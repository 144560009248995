import React from "react";
import AuthUserContext from "./Context";

const withAuth = Component => props => (
  <AuthUserContext.Consumer>
    {auth => <Component {...props} auth={auth} />}
  </AuthUserContext.Consumer>
);

export default withAuth;
