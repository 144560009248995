import React from "react";
import styled from "styled-components";
import { Avatar, Badge, Box, Button, Grid, Tab, Tabs } from "@material-ui/core";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import {
  ComputerSharp,
  Wifi,
  MessageSharp,
  OpenInNewSharp
} from "@material-ui/icons";

import { DeviceStrings } from "../strings";
import {
  CenteredOuterDiv,
  showMetaTags,
  showSuccessToast,
  showErrorToast
} from "../UIUtils";
import { withLogin } from "../loggedInPage.js";
import {
  isDeviceOnlineSince,
  subscribeToDevice,
  subscribeToDeviceStatus,
  updateDeviceInfo
} from "../FirebaseFunctions";
import themeMyPlayer from "../theme";
import ProtectedEditForm from "../components/ProtectedEditForm";

import Content from "./DeviceContent";
import Message from "./DeviceMessage";
import Online from "./DeviceOnline";

const URL = {
  cloudControl: "https://cc.myplayer.io/device/imei/{deviceId}"
};

const BackButton = styled.div`
  padding: 20px 10px;
  color: black;
  border-radius: 5px;
  display: block;
  cursor: pointer;
  margin-left: -10px;
`;

const StoreDocIdDiv = styled.div`
  padding: 8px;
  font-size: small;
`;

const StyledAvatar = withStyles({
  root: {
    padding: "20px",
    width: themeMyPlayer.spacing(12),
    height: themeMyPlayer.spacing(12)
  }
})(Avatar);

const targetInfoLabels = {
  storeId: DeviceStrings.DEVICE_STORE_ID,
  postcode: DeviceStrings.DEVICE_POSTCODE,
  retailerName: DeviceStrings.DEVICE_RETAILER_NAME
};

const OnlineBadge = props => {
  if (props.lastOnline === null) return props.children;
  const isOnline = isDeviceOnlineSince(props.lastOnline);
  return isOnline ? (
    <Badge badgeContent={DeviceStrings.DEVICE_ONLINE} color="primary">
      {props.children}
    </Badge>
  ) : (
    <Badge badgeContent={DeviceStrings.DEVICE_OFFLINE} color="error">
      {props.children}
    </Badge>
  );
};

class Device extends React.Component {
  constructor(props) {
    super(props);

    this.unsubList = [];

    const projectId = props.match.params.projectId;
    const deviceId = props.match.params.deviceId;
    const data = null;
    const storeDocId = null;
    const lastOnline = null;
    const tab = 0;
    this.state = {
      projectId,
      deviceId,
      data,
      storeDocId,
      lastOnline,
      tab
    };
  }

  back = () => {
    this.props.history.goBack();
  };

  save = apiData => {
    const { projectId, deviceId } = this.state;

    // not using await here to allow chained operations and error display in child components
    return updateDeviceInfo(projectId, deviceId, apiData)
      .then(result => {
        if (result.success) {
          // successful
          showSuccessToast(DeviceStrings.DEVICE_UPDATE_OK_TEXT);
        } else {
          // failed
          throw new Error(result.errors);
        }
      })
      .catch(error => {
        // reset data state
        this.setState({ data: this.originalData });

        showErrorToast(DeviceStrings.DEVICE_UPDATE_FAIL_TEXT);

        // throw the error again so the form can handle reset
        throw error;
      });
  };

  openCloudControl = () => {
    const { deviceId } = this.state;
    window.open(URL.cloudControl.replace("{deviceId}", deviceId), "_blank");
  };

  // info returned by FirebaseFunctions.getDevice()
  getFormData = apiInfo => {
    const data = [];
    Object.keys(targetInfoLabels).forEach(key => {
      const label = targetInfoLabels[key];
      const value = apiInfo[key];
      data.push({ key, label, value });
    });
    return data;
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onTabChange = (event, newTab) => {
    this.setState({ tab: newTab });
  };

  onInfoUpdate = apiInfo => {
    const data = this.getFormData(apiInfo);
    console.debug(`onInfoUpdate`, data);
    this.setState({ data });

    // storeDocId
    const { storeDocId } = apiInfo;
    this.setState({ storeDocId });

    // store original data
    this.originalData = data;
  };

  onStatusUpdate = apiInfo => {
    // const isOnline = isDeviceOnlineSince(apiInfo.updatedAt);
    const lastOnline = apiInfo.updatedAt;
    console.debug(`onStatusUpdate: lastOnline=${lastOnline}`);
    this.setState({ lastOnline });
  };

  componentDidMount = async () => {
    const { projectId, deviceId } = this.state;

    // subscribe to device info changes
    this.unsubList.push(await subscribeToDevice(deviceId, this.onInfoUpdate));

    // subscribe to device status changes
    this.unsubList.push(
      await subscribeToDeviceStatus(deviceId, this.onStatusUpdate)
    );
  };

  componentWillUnmount = () => {
    this.unsubList.forEach(unsub => {
      unsub();
    });
  };

  render = () => {
    const {
      projectId,
      deviceId,
      data,
      storeDocId,
      lastOnline,
      tab
    } = this.state;
    return (
      <MuiThemeProvider theme={themeMyPlayer}>
        <CenteredOuterDiv>
          {showMetaTags(
            DeviceStrings.META_TITLE,
            DeviceStrings.META_DESCRIPTION
          )}
          <BackButton onClick={this.back}>
            {DeviceStrings.BUTTON_BACK}
          </BackButton>
          {data && (
            <Grid container spacing={3}>
              {/* Left panel */}
              <Grid
                container
                justify="center"
                alignContent="flex-start"
                item
                sm={12}
                md={4}
              >
                <Grid container justify="center" item xs={12}>
                  <OnlineBadge lastOnline={lastOnline}>
                    <StyledAvatar
                      src={require("../images/icon_my_player_v3.png")}
                    />
                  </OnlineBadge>
                </Grid>
                {storeDocId && <StoreDocIdDiv>{storeDocId}</StoreDocIdDiv>}
                <Box mt={3} />
                <ProtectedEditForm data={data} onSave={this.save} />
              </Grid>
              {/* Right panel */}
              <Grid item sm={12} md={8}>
                <Box>{DeviceStrings.DUMMY_TEXT}</Box>
                <Box mt={2} mb={2}>
                  <Button
                    variant="contained"
                    color="default"
                    endIcon={<OpenInNewSharp />}
                    onClick={this.openCloudControl}
                  >
                    {DeviceStrings.DEVICE_BUTTON_CLOUD_CONTROL}
                  </Button>
                </Box>
                <Tabs
                  name="tab"
                  value={tab}
                  onChange={this.onTabChange}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    icon={<ComputerSharp />}
                    label={DeviceStrings.DEVICE_TAB_CONTENT}
                  />
                  <Tab
                    icon={<Wifi />}
                    label={DeviceStrings.DEVICE_TAB_ONLINE_STATUS}
                  />
                  <Tab
                    icon={<MessageSharp />}
                    label={DeviceStrings.DEVICE_TAB_MESSAGE_LOG}
                  />
                </Tabs>
                {tab === 0 ? (
                  <Content projectId={projectId} deviceId={deviceId} />
                ) : (
                  <></>
                )}
                {tab === 1 ? (
                  <Online projectId={projectId} deviceId={deviceId} />
                ) : (
                  <></>
                )}
                {tab === 2 ? <Message /> : <></>}
              </Grid>
            </Grid>
          )}
        </CenteredOuterDiv>
      </MuiThemeProvider>
    );
  };
}

export default withLogin(Device);
