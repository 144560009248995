import React from "react";
import { Redirect } from "react-router";
import { StyledCard, showMessage, showConfirmation } from "../UIUtils";
import {
  getProjectDefaultApps,
  getProjectOtherApps,
  getDeviceProject,
  updateDeviceProject,
  projectDocHasChanged,
} from "../FirebaseFunctions";
import styled from "styled-components/macro";

const AppCard = styled(StyledCard)`
  min-height: 74px;
  @media (min-width: 0px) {
    width: calc(100% - 20px);
  }
`;

const DefaultAppCard = styled(AppCard)``;

const AppIcon = styled.img`
  width: 30px;
  height: 30px;
  margin: 20px;
  display: inline-block;
  vertical-align: middle;
`;

const AppNameSpan = styled.span`
  display: inline-block;
  vertical-align: middle;
  color: black;
  font-weight: bold;
`;

const CheckBoxSection = styled.div`
  float: right;
  display: inline-block;
  vertical-align: middle;
  line-height: 74px;
`;
const DefaultAppSpan = styled.span`
  float: right;
  display: inline-block;
  vertical-align: middle;
`;

const DefaultSpan = styled.span`
    color:grey;
    display:inline-block;
    vertical-align:middle;
    font-size:14px;
    margin-left:10px;
`;

const Outer = styled.div`
  margin-bottom:55px;
`

const CheckBox = styled.div`
  float: right;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid #a0a0a0;
`;

const SaveChangeButton = styled.div`
  font-size: 18px;
  color: white;
  background-color: black;
  padding: 10px 30px;
  border-radius: 10px;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
`;

const CheckedBox = styled(CheckBox)`
  background: orange;
`;

class AppCardItem extends React.Component {
  render() {
    const app = this.props.app;
    let appName = app.appName + " (" + app.ver + ")";
    return (
      <AppCard onClick={() => this.props.appSelected(app)} key={app.appName}>
        <AppIcon src={app.img} />
        <AppNameSpan>{appName}</AppNameSpan>
        {this.props.app.default && (
          <DefaultSpan> Default app </DefaultSpan>
        )}
        <CheckBoxSection>
          {this.props.selectedApps.findIndex(
            element => app.appName == element.appName
          ) == -1 && (
            <>
              <CheckBox></CheckBox>
              <DefaultAppSpan>Not Selected</DefaultAppSpan>
            </>
          )}
          {this.props.selectedApps.findIndex(
            element => app.appName == element.appName
          ) > -1 && (
            <>
              <CheckedBox></CheckedBox>
              <DefaultAppSpan>Selected</DefaultAppSpan>
            </>
          )}
        </CheckBoxSection>
      </AppCard>
    );
  }
}

class ProjectAppsHolder extends React.Component {
  state = {
    defaultApps: [],
    otherApps: [],
    selectedApps: []
  };

  originalApps = [];

  grabDefaultApps = async () => {
    const apps = await getProjectDefaultApps();
    this.setState({ defaultApps: apps });
  };

  grabOtherApps = async () => {
    const apps = await getProjectOtherApps();
    this.setState({ otherApps: apps });
  };

  appSelected = app => {
    if (app.default) return;
    let selectedApps = this.state.selectedApps;
    let foundIndex = this.state.selectedApps.findIndex(
      element => app.appName == element.appName
    );
    let useApps = selectedApps;
    if (foundIndex > -1) {
      useApps.splice(foundIndex, 1);
      this.setState({ selectedApps: useApps });
    } else {
      useApps = [...selectedApps, app];
      this.setState({ selectedApps: useApps });
    }
  };

  finaliseSave = async() =>{
    let project = {
      projectId: this.props.project.projectId,
      pkgs: this.state.selectedApps
    };
    updateDeviceProject(project);

    showMessage("Project Apps Updated");
    const rsp = await projectDocHasChanged(this.props.project.projectId);
    console.log("projectDocHasChanged : ", rsp);
  }

  saveChanges = async() => {
    if(this.props.project.hasMedia){
      const rsp = await showConfirmation("Make App Changes?", "Making Changes to your project will make all your devices become non compliant")
      if (rsp.value) {
        this.finaliseSave();
      }
    }
    else{
      this.finaliseSave();
    }
  }

  undoChanges = async() => {
    this.setState({selectedApps:this.originalApps})
  }

  grabSelectedProjectApps = async () => {
    const projectId = this.props.project.projectId;
    const response = await getDeviceProject(projectId);
    if(response){
      const apps = response.pkgs;
      this.originalApps = apps;
      this.setState({ selectedApps: apps });
    }
  };

  componentDidMount() {
    this.grabDefaultApps();
    this.grabOtherApps();
    this.grabSelectedProjectApps();
  }

  isAppMatching(element, name) {
    return element.appName > name;
  }

  render() {
    return (
      <Outer>
        {this.state.defaultApps.map(app => (
          <AppCardItem
            appSelected={this.appSelected}
            app={app}
            key={app.appName}
            selectedApps={this.state.selectedApps}
          />
        ))}
        {this.state.otherApps.map(app => (
          <AppCardItem
            appSelected={this.appSelected}
            app={app}
            key={app.appName}
            selectedApps={this.state.selectedApps}
          />
        ))}
        <SaveChangeButton onClick={this.saveChanges}>Save App Changes </SaveChangeButton>
        <SaveChangeButton onClick={this.undoChanges}>Undo App Changes </SaveChangeButton>
      </Outer>
    );
  }
}

export default ProjectAppsHolder;
