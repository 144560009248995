import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import Swal from "sweetalert2";
import FirebaseStyledLogin from "./components/FirebaseStyledLogin";

class Auth extends React.Component {
  state = {};
  unsubscribeList = [];

  handleVerifyEmail = async (actionCode, continueUrl, lang) => {
    try {
      const check = await firebase.auth().applyActionCode(actionCode);
      const r = await Swal.fire("Your Email Address has been verified");
      window.location = "/";
    } catch (e) {
      const r = await Swal.fire(
        "There was a problem verifiying your email address, please try again later"
      );
      window.location = "/";
    }
  };

  handleResetPassword = async (actionCode, continueUrl, lang) => {
    var accountEmail;
    try {
      let email = await firebase.auth().verifyPasswordResetCode(actionCode);

      const value = await Swal.fire({
        title: email + " Please Enter your new password",
        input: "password",
        inputPlaceholder: "Enter your new password",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off"
        }
      });

      try {
        const resp = await firebase
          .auth()
          .confirmPasswordReset(actionCode, value.value);
        console.log("resp : ", resp);
        const r = await Swal.fire("Your Password has now been reset");
        window.location = "/";
      } catch (e) {
        const r = await Swal.fire(
          "An error occured and your password could not be reset, please try again"
        );
        window.location = "/";
      }
    } catch (e) {
      const r = await Swal.fire(
        "An error occured and your password could not be reset, please try again"
      );
      window.location = "/";
    }
  };

  getParameterByName = name => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return "";
    else return decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  start = () => {
    var mode = this.getParameterByName("mode");
    var actionCode = this.getParameterByName("oobCode");
    var continueUrl = this.getParameterByName("continueUrl");
    var lang = this.getParameterByName("lang") || "en";

    switch (mode) {
      case "resetPassword":
        this.handleResetPassword(actionCode, continueUrl, lang);
        break;
      case "recoverEmail":
        // this.handleRecoverEmail(auth, actionCode, lang);
        break;
      case "verifyEmail":
        this.handleVerifyEmail(actionCode, continueUrl, lang);
        break;
      default:
    }
  };

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    return <></>;
  }
}

export default Auth;
