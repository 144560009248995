import React from "react";
import styled from "styled-components/macro";
import {convertInitialToColor} from '../InitialToColor'

const BigPersonCircle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  border: 2px solid white;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  text-align: center;
  color: white;
  line-height: ${props => props.height};
  font-size: ${props => props.fontSize};

  @media (max-width:600px) {
    width: ${props => props.width.replace('px','')/2 + 'px'};
    height: ${props => props.height.replace('px','')/2 + 'px'};
  }

  min-width: 30px;
  min-height: 30px;
`;

const BigPersonCircleBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: ${props => props.bgColor};
`;

const BigPersonCircleInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-size: cover;
  background-image: url(${props => props.bgImage});
`;

class PersonCircle extends React.Component {
  render() {
    if (!this.props.user || !this.props.user.usersId || !this.props.user.name) {
      return <BigPersonCircle width={this.props.width}
      height={this.props.height}
      margin={this.props.margin}
      fontSize={this.props.fontSize}/>;
    }
    const matches = this.props.user.name.match(/\b(\w)/g);
    const userInitial = matches.join("");
    const bgColor = convertInitialToColor(userInitial);
    return (
      <BigPersonCircle
        width={this.props.width}
        height={this.props.height}
        margin={this.props.margin}
        fontSize={this.props.fontSize}
      >
        {!this.props.user.img && (
          <BigPersonCircleBg bgColor={bgColor}>{userInitial}</BigPersonCircleBg>
        )}
        <BigPersonCircleInner
          key={this.props.user.usersId}
          bgImage={this.props.user.img}
          alt={this.props.user.name}
        />
      </BigPersonCircle>
    );
  }
}

export default PersonCircle;
