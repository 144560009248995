import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import {
  getProjectIdsForUser,
  subscribeToUseProjectsListChanges,
  getProject
} from "../FirebaseFunctions";
import styled from "styled-components";
import {
  CenteredOuterDiv,
  HeaderSpan,
  StyledCard,
  showMetaTags,
  AnimatingDiv
} from "../UIUtils";

const OuterDiv = styled(CenteredOuterDiv)`
  z-index: -1;
`;

const ProjectRowImage = styled.div`
  background-image: url(${props => props.bgImage});
  width: 50px;
  height: 50px;
  margin: 10px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
`;

const ProjectRowSpan = styled.span`
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 100px);
`;

export const ProjectRow = styled(StyledCard)``;

class ProjectList extends React.Component {
  state = {
    projects: [],
    projectIds: []
  };
  mounted = false;
  unsubscribeList = [];
  usersId = firebase.auth().currentUser.uid;

  grabProjectAction = async (projectId, remote) => {
    const rsp = await getProject(projectId, true);
    const projectIds = this.state.projectIds;
    const projects = this.state.projects;
    const foundProject = projectIds.find(pID => pID == projectId);
    const foundIndex = projectIds.indexOf(foundProject);
    let mergedProject = { ...projects[foundIndex], ...rsp };
    if (!mergedProject.deleted) {
      projects.splice(foundIndex, 1, mergedProject);
      projectIds.splice(foundIndex, 1, projectId);
    } else {
      console.warn("Project deleted : ", mergedProject);
      projects.splice(foundIndex, 1);
      projectIds.splice(foundIndex, 1);
    }
    this.setState({
      projects: projects,
      projectIds: projectIds
    });
    return projects;
  };

  grabProject = async projectId => {
    const rsp = await this.grabProjectAction(projectId, false);
    this.grabProjectAction(projectId, true);
  };

  getInfoForProjects = async projects => {
    const that = this;
    const projectIds = [];
    projects.forEach(function(element) {
      projectIds.push(element.projectId);
      that.grabProject(element.projectId);
    });
    this.setState({ projectIds: projectIds });
  };

  grabProjectsFromFB = async remote => {
    const that = this;
    const projects = await getProjectIdsForUser(this.usersId, remote);
    if (!this.mounted) return;
    this.setState({ projects: projects });
    this.getInfoForProjects(projects);
  };

  init = async () => {
    const that = this;
    this.setState({ user: this.props.user });
    let propProjects = this.props.projects;
    if (propProjects) {
      this.setState({ projects: propProjects });
      this.getInfoForProjects(propProjects);
    } else {
      that.grabProjectsFromFB(true);
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    let clickFunc = this.props.projectSelected;
    console.log("projects : ", this.state.projects);
    if (!this.state.projects || this.state.projects.length == 0) {
      return <></>;
    }
    return (
      <>
        {this.state.projects.map(project => (
          <>
            {this.props.project.projectId != project.projectId && (
              <ProjectRow
                key={project.projectId}
                onClick={() => clickFunc(project)}
              >
                {project.name && (
                  <>
                    <ProjectRowImage
                      bgImage={project.imageURL ? project.imageURL : ""}
                    ></ProjectRowImage>
                    <ProjectRowSpan>
                      {project.name ? project.name : project.projectId}
                    </ProjectRowSpan>
                  </>
                )}
                {!project.name && (
                  <>
                    <ProjectRowImage></ProjectRowImage>
                    <AnimatingDiv></AnimatingDiv>
                  </>
                )}
              </ProjectRow>
            )}
          </>
        ))}
      </>
    );
  }
}

export default ProjectList;
