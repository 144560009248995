import { Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";

/**
 * styled-components
 */

export const SignInLinkSpan = styled.span`
  text-align: center;
  width: 100%;
  font-weight: normal;
  color: rgb(247, 129, 48);
  cursor: pointer;
  font-size: 14px;
  display: block;
  margin-top: 20px;

  &:hover {
    opacity: 0.5;
  }
`;

export const CenteredFormContainer = styled.form`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const FormRow = styled.div`
  padding: 10px;
`;

export const FormTextField = styled(TextField)`
  width: 300px;
`;

export const RoundedButton = withStyles({
  root: {
    color: "white",
    borderRadius: 20
  }
})(Button);
