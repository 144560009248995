import React from "react";
import firebase from "firebase";
import Swal from "sweetalert2";
import styled from "styled-components/macro";
import {
  getUser,
  getUsersIdsInProject,
  subscribeToUsersIdsInProject
} from "../FirebaseFunctions";
import PersonCircle from "./PersonCircle";

const PersonRowCircle = styled(PersonCircle)`
  margin-right: -10px;
`;

class ProjectPeopleRow extends React.Component {
  unsubscribeList = [];
  mounted = false;
  state = {
    users: []
  };

  getUsersFromRemote = async(remote) =>{
    const that = this;
    const rsp = await getUsersIdsInProject(this.props.project.projectId, remote);

    for(let i in rsp){
      let user = rsp[i];
      that.addBasicUserWithId(user);
    }
    for(let i in rsp){
      let user = rsp[i];
      that.getAddUserWithId(user.usersId);
    }
  }

  getUsers = async() =>{
    let rsp;
    try{
      rsp = await this.getUsersFromRemote(false);
    }
    catch(e){}
    rsp = await this.getUsersFromRemote(true);
    return rsp;
  }

  addBasicUserWithId = async user => {
    const usersId = user.usersId;
    const currentUsers = this.state.users;
    const foundUser = currentUsers.find(
      dev => dev.usersId == usersId
    );
    const foundIndex = currentUsers.indexOf(foundUser);
    if(foundIndex == -1){
      this.setState({ users: this.state.users.concat(user) });
    }
    else{
      let mergedUser = { ...currentUsers[foundIndex], ...user };
      currentUsers.splice(foundIndex, 1, mergedUser);
      this.setState({ users: currentUsers});
    }
    console.log("users : ", this.state.users)
  }

  getAddUserWithId = async usersId => {
    const user = await getUser(usersId);
    if(!this.mounted)return;
    this.addBasicUserWithId(user);
  };

  subscribeToUsers = async () => {
    const that = this;

    let unsub = await subscribeToUsersIdsInProject(
      this.props.project.projectId,
      function(user, type) {
        if(!that.mounted)return;
        let found = that.state.users.findIndex(
          element => user.usersId == element.usersId
        );
        if (type == "added" && found == -1) {
          that.getAddUserWithId(user.usersId);
        } else if (type == "removed" && found > -1) {
          let users = that.state.users;
          users.splice(found, 1);
          that.setState({ users: users });
        }
      }
    );
    this.unsubscribeList.push(unsub);
  };

  init = async() => {
    // this.addBasicUserWithId(this.props.project.creator);
    const rsp = await this.getUsers();
    this.subscribeToUsers();
  }

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    return (
      <>
        {this.state.users.map(user => (
          <PersonRowCircle
            user={user}
            key={user.usersId}
            width="30px"
            height="30px"
            margin="0px -10px 0px 0px"
            fontSize="12px"
          />
        ))}
      </>
    );
  }
}

export default ProjectPeopleRow;
