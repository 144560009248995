import React from "react";
import styled from "styled-components/macro";
import {
  StyledCard,
  showConfirmation,
  showMessage,
  AnimatingDiv
} from "../UIUtils";
import moreIcon from "../images/moreIcon.svg";
import DropDown from "./DropDown";
import {
  subscribeToDeviceHeartbeats,
  subscribeToDeviceInfo,
  subscribeToDeviceStatus,
  subscribeToDevice,
  isDeviceOnlineSince,
  getDevice,
  getDeviceStatus,
  checkForAdmin,
  // getDeviceInfo,
  subscribeToDeviceCompliance,
  getDeviceHeartbeats,
  requestHeartbeat,
  deployDeviceInProject,
  checkComplianceForDevice,
  subscribeToDeviceComplianceCheck
} from "../FirebaseFunctions";

const DeviceCard = styled(StyledCard)`
  /* overflow: hidden; */
`;

const MoreIcon = styled.img`
  display: inline-block;
  margin: 0px 15px 0px 0px;
  width: 20px;
  vertical-align: middle;
  float: right;
  position: absolute;
  right: 0px;
  top: calc(50% - 10px);
  &:hover {
    opacity: 0.5;
  }
`;

const DeviceIcon = styled.img`
  padding: 10px;
  border: 3px solid white;
  background: black;
  border-radius: 50%;
  margin: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  z-index: 1;
  position: relative;
`;

const DeviceInfoBox = styled.div`
  /* display: inline-block;
  color: black;
  width: calc(100% - 100px);
  vertical-align: middle; */

  display: inline-block;
  color: black;
  width: calc(100% - 115px);
  max-height: 75px;
  vertical-align: middle;
  overflow-y: auto;
  font: 12px myplayer;
  font-weight: bold;
  position: relative;
`;

const ClippedSpan = styled.span`
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
`;

class DeviceCell extends React.Component {
  unsubscribeList = [];
  state = {
    device: {},
    lastHeartbeat: [],
    isOnline: null,
    complianceState: null,
    checkingComplianceState: null,
    transferringDevice: false
  };
  mounted = false;

  mergeInfoToDevice = device => {
    if (!this.mounted) return;
    let useDevice = { ...this.state.device, ...device };
    this.setState({ device: useDevice });
    if (this.props.updateDevice) {
      this.props.updateDevice(useDevice);
    }
  };

  getDeviceBasic = async () => {
    if (!this.mounted) return;
    let that = this;
    let unsub = await subscribeToDevice(this.props.device.deviceId, function(
      device
    ) {
      if (!that.mounted) return;
      that.mergeInfoToDevice(device);
    });
    this.unsubscribeList.push(unsub);
  };

  getDeviceStatus = async () => {
    if (!this.mounted) return;
    let that = this;
    let unsub = await subscribeToDeviceStatus(
      this.props.device.deviceId,
      function(device) {
        if (!that.mounted) return;
        that.mergeInfoToDevice(device);
        if (device && device.updatedAt) {
          const isOnline = isDeviceOnlineSince(device.updatedAt);
          that.setState({ isOnline });
        }
      }
    );
    this.unsubscribeList.push(unsub);
  };

  // getDeviceInfo = async () => {
  //   let that = this;
  //   let unsub = await subscribeToDeviceInfo(
  //     this.props.device.deviceId,
  //     function(device) {
  //       that.mergeInfoToDevice(device);
  //     }
  //   );
  //   this.unsubscribeList.push(unsub);
  // };

  getDeviceCompliance = async () => {
    if (!this.mounted) return;
    let that = this;
    let unsub = await subscribeToDeviceCompliance(
      this.props.device.deviceId,
      this.props.project.projectId,
      function(data) {
        if (!that.mounted) return;
        if (data) {
          if (data.state == "compliant") {
            that.setState({
              complianceState: "Compliant",
              checkingComplianceState: null
            });
          } else if (data.state == "running") {
            that.setState({
              complianceState: "Deploying",
              checkingComplianceState: null
            });
          } else if (data.state == "error") {
            that.setState({
              complianceState: "Error Deploying",
              checkingComplianceState: null
            });
          } else {
            that.setState({
              complianceState: "Noncompliant",
              checkingComplianceState: null
            });
          }
        } else {
          that.setState({
            complianceState: "Noncompliant",
            checkingComplianceState: null
          });
        }
      }
    );
    this.unsubscribeList.push(unsub);
  };

  // getDeviceComplianceChecking = async () => {
  //   let that = this;
  //   let unsub = await subscribeToDeviceComplianceCheck(
  //     this.props.device.deviceId,
  //     this.props.project.projectId,
  //     function(data) {
  //       if (data) {
  //         if (data.state == "checking") {
  //           that.setState({ checkingComplianceState: "Checking" });
  //         }
  //       } else {
  //         that.setState({ complianceState: null });
  //       }
  //     }
  //   );
  //   this.unsubscribeList.push(unsub);
  // };

  getDeviceFromCacheRemote = async () => {
    if (!this.mounted) return;
    try {
      const info = await getDevice(this.props.device.deviceId, false);
      this.mergeInfoToDevice(info);
    } catch (e) {
      console.warn("no cache for device");
    }
    const moreInfo = await getDevice(this.props.device.deviceId, true);
    this.mergeInfoToDevice(moreInfo);
    return moreInfo;
  };

  init = async () => {
    if (!this.mounted) return;
    this.mergeInfoToDevice(this.props.device);
    let info = this.getDeviceFromCacheRemote();
    // this.getDeviceBasic();
    this.getDeviceStatus();
    this.getDeviceCompliance();
    // this.getDeviceComplianceChecking();
    // this.getDeviceInfo();
    // this.getDeviceHeartbeats();
  };

  goToCC = () => {
    let link =
      "https://cc.myplayer.io/device/imei/" + this.props.device.deviceId;
    window.open(link, "_blank");
  };

  requestOnline = async () => {
    console.log("this.state.device : ", this.state.device);
    requestHeartbeat(this.props.device.deviceId, this.props.project.projectId);
    showMessage("Online Request Sent");
    this.toggleShown();
  };

  checkCompliance = async () => {
    const rsp = await showConfirmation(
      "Check Device Compliance?",
      "Are you sure you want to check the compliance to this device?"
    );
    if (rsp.value) {
      this.setState({ checkingComplianceState: "Contacting Device" });
      const rsp = await checkComplianceForDevice(
        this.props.project.projectId,
        this.props.device.deviceId
      );
    }
  };

  deployDevice = async () => {
    const rsp = await showConfirmation(
      "Deploy Device?",
      "Are you sure you want to Deploy to this device?"
    );
    if (rsp.value) {
      this.setState({
        complianceState: "Contacting Device..."
      });
      const rsp = await deployDeviceInProject(
        this.props.project.projectId,
        this.props.device.deviceId
      );
    }
  };

  transferDevice = () => {
    this.props.transferDevice(this.state.device);
  };

  calcDropItems = async () => {
    if (!this.mounted) return;
    const cc = {
      name: "CC",
      style: "Positive",
      action: this.goToCC
    };
    const requestOnline = {
      name: "Request Online",
      style: "Positive",
      action: this.requestOnline
    };
    const checkCompliance = {
      name: "Check Device Compliance",
      style: "Positive",
      action: this.checkCompliance
    };
    const deployDevice = {
      name: "Deploy Device",
      style: "Positive",
      action: this.deployDevice
    };
    this.setState({
      dropDownItems: [cc, requestOnline, checkCompliance, deployDevice]
    });
    console.log("this.props.transferSelected : ", this.props.transferSelected);
    if (this.props.transferDevice) {
      const rsp = await checkForAdmin();
      if (rsp && rsp.isAdmin) {
        const transferDevice = {
          name: "Transfer Device",
          style: "Positive",
          action: this.transferDevice
        };
        this.setState({
          dropDownItems: [
            cc,
            requestOnline,
            checkCompliance,
            deployDevice,
            transferDevice
          ]
        });
      }
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
    this.calcDropItems();
  }

  toggleShown = () => {
    let isShown = this.state.isShown;
    this.setState({ isShown: !isShown });
  };

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  defaultIcon =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA2klEQVRIie2USwrCMBRF3xM7Fke6GaHr0V04dlX+OvIz0jV0VsGJDjxOktIWE9MoFMELIeS2756XNkSkSwFHIPU8T4HDJ4AUyIE5kAEXMzLj5b4GQgBj4IxbJ2AUG54Ae0+41Q5IYgAzm2DWNTW8aQwgC+jeauvKUQ+gEJFBYD+Fqg7bbuIrqu0A2IjIQ1Un9jsHB6lqtd4FoPJyDKCst36vTUiM/oDuAf3Gei0irU5PdD2wanFVLF05zquiAio7suf7lefS7//kEMDdzLc3XjRgISJXM/u8bvQEpiw9/3YydeAAAAAASUVORK5CYII=";

  render() {
    if (!this.state.device.storeId && this.state.device.deviceId) {
      // if (true == true) {
      return (
        <DeviceCard>
          <AnimatingDiv></AnimatingDiv>
          <DeviceIcon src={this.defaultIcon} onClick={this.props.onClick} />
          <DeviceInfoBox onClick={this.props.onClick}>
            <ClippedSpan>{this.state.device.deviceId}</ClippedSpan>
          </DeviceInfoBox>
        </DeviceCard>
      );
    } else if (this.props.device) {
      return (
        <>
          <DeviceCard>
            <DeviceIcon src={this.defaultIcon} onClick={this.props.onClick} />
            <DeviceInfoBox>
              <ClippedSpan>{this.state.device.deviceId}</ClippedSpan>
              <ClippedSpan>{this.state.device.storeId}</ClippedSpan>
              {this.state.isOnline == null && <ClippedSpan></ClippedSpan>}
              {this.state.isOnline && <span>Online</span>}
              {this.state.isOnline != null && !this.state.isOnline && (
                <ClippedSpan>Offline</ClippedSpan>
              )}
              {this.state.isOnline == null && <ClippedSpan></ClippedSpan>}
              {this.state.complianceState && (
                <ClippedSpan>{this.state.complianceState}</ClippedSpan>
              )}
              {this.state.checkingComplianceState && (
                <ClippedSpan>{this.state.checkingComplianceState}</ClippedSpan>
              )}
              {this.state.device.stage && (
                <ClippedSpan>{this.state.device.stage}</ClippedSpan>
              )}
              {!this.state.device.stage && <ClippedSpan></ClippedSpan>}
            </DeviceInfoBox>
            {this.props.project &&
              this.props.project.permissions &&
              this.props.project.permissions.devices.write &&
              this.state.isShown && (
                <>
                  <MoreIcon src={moreIcon} onClick={this.toggleShown} />
                  <DropDown
                    top="50px"
                    right="10px"
                    dropItems={this.state.dropDownItems}
                    toggleShown={this.toggleShown}
                  ></DropDown>
                </>
              )}
            {this.props.project &&
              this.props.project.permissions &&
              this.props.project.permissions.devices.write &&
              !this.state.isShown && (
                <MoreIcon src={moreIcon} onClick={this.toggleShown} />
              )}
          </DeviceCard>
        </>
      );
    } else {
      return null;
    }
  }
}

export default DeviceCell;
