import React from "react";
import firebase from "firebase";
import styled from "styled-components/macro";
import shareImage from "../images/ShareIcon.svg";
import {
  getProjectCodeForId,
  removeProjectForUser,
  getPermissionsCategories,
  setPermissionsForUser
} from "../FirebaseFunctions";
import moreIcon from "../images/moreIcon.svg"
import { showMessage, showConfirmation } from "../UIUtils";
import DropDown from "./DropDown";

const MoreIcon = styled.img`
  display: inline-block;
  margin: 0px 15px 0px 0px;
  width: 20px;
  vertical-align: middle;
  &:hover {
    opacity: 0.5;
  }
`;

const EmptyItem = styled.div`
  display: inline-block;
  margin: 0px 15px 0px 0px;
  width: 15px;
  height: 15px;
  vertical-align: middle;
`;

class UserPermissionsDropDown extends React.Component {
  state = {
    isShown: false,
    dropDownItems: []
  };

  currentPermissionKey = ''

  changePermission = permissionsKey => {
    let project = this.props.project;
    let user = this.props.user;
    setPermissionsForUser(project.projectId, user.usersId, permissionsKey);
    this.currentPermissionKey = permissionsKey;
    this.toggleShown();
    this.calcDropItems();
  };

  toggleShown = () => {
    let isShown = this.state.isShown;
    this.setState({ isShown: !isShown });
  };

  sortByKey = (array, key) => {
    return array.sort(function(a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  removeUser = async () => {
    console.log("Remove User");
    const rsp = await showConfirmation("Remove User?", "Are you sure you want to remove this User?")
    if (rsp.value) {
      removeProjectForUser(this.props.project.projectId, this.props.user.usersId)
      console.log("Remove project for user : ", this.props.user);
    }
    this.toggleShown();
  }

  calcDropItems = async () => {
    const that = this;
    const permissionsCat = await getPermissionsCategories();
    that.setState({ dropDownItems: [] });
    let items = [];
    permissionsCat.forEach(permission => {
      const action = () => {
        that.changePermission(permission.permissionsKey);
      };
      const style =
        this.currentPermissionKey == permission.permissionsKey
          ? "Positive"
          : "Neutral";
      const item = {
        name: permission.name,
        style: style,
        action: action,
        order: permission.order
      };
      items.push(item);
    });
    this.sortByKey(items, 'order')
    if(this.props.viewer.permissions.users.delete){
      const item = {
        name: "Remove User",
        style: "Negative",
        action: this.removeUser,
      };
      items.push(item);
    }
    that.setState({ dropDownItems: items });
  };

  componentDidMount() {
    this.currentPermissionKey = this.props.user.permissions.permissionsKey;
    this.calcDropItems();
  }

  toggleShown = () => {
    let isShown = this.state.isShown;
    this.setState({ isShown: !isShown });
  };

  render() {
    if (this.state.dropDownItems.length) {
      return (
        <>
          <MoreIcon
            src={moreIcon}
            onClick={this.toggleShown}
          />
          {this.state.isShown && (
            <DropDown
              top="50px"
              right="10px"
              dropItems={this.state.dropDownItems}
              toggleShown={this.toggleShown}
            ></DropDown>
          )}
        </>
      );
    } else {
      return <EmptyItem />;
    }
  }
}

export default UserPermissionsDropDown;
