import React from "react";
import styled from "styled-components/macro";
import UserPermissionsDropDown from "./UserPermissionsDropDown";
import { StyledCard, showConfirmation } from "../UIUtils";
import PersonCircle from "./PersonCircle";
import DropDown from "./DropDown";
import {removeProjectCode} from '../FirebaseFunctions'
import moreIcon from "../images/moreIcon.svg";
import {
  subscribeToUser,
  subscribeToUserProjectRemoval,
  getLoggedInUsersId,
  getPermissionsForKey
} from "../FirebaseFunctions";

const MoreIcon = styled.img`
  display: inline-block;
  margin: 0px 15px 0px 0px;
  width: 20px;
  vertical-align: middle;
  float: right;
  position: absolute;
  right: 0px;
  top: calc(50% - 10px);
  &:hover {
    opacity: 0.5;
  }
`;

const CodeCard = styled(StyledCard)`
  min-height: 74px;
`;

const CodeSpan = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 73px;
  margin-right: 15px;
  margin-left: 15px;
`;

class CodeCell extends React.Component {
  unsubscribeList = [];
  state = {
    code: {},
    removed: false
  };

  deleteCode = async () => {
    const rsp = await showConfirmation(
      "Delete Code?",
      "Are you sure you want to remove this share code?"
    );
    if (rsp.value) {
      const rsp = await removeProjectCode(this.props.codeDict);
    }
  };

  calcDropItems = async () => {
    const remove = {
      name: "Delete",
      style: "Negative",
      action: this.deleteCode
    };
    this.setState({ dropDownItems: [remove] });
  };

  componentDidMount() {
    this.calcDropItems();
  }

  toggleShown = () => {
    let isShown = this.state.isShown;
    this.setState({ isShown: !isShown });
  };

  componentWillUnmount() {
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    if (!this.state.removed && !this.props.codeDict.immutable) {
      return (
        <CodeCard>
          <CodeSpan>{this.props.codeDict.code}</CodeSpan>
            <MoreIcon src={moreIcon} onClick={this.toggleShown} />
            {this.state.isShown && (
              <DropDown
                top="50px"
                right="10px"
                dropItems={this.state.dropDownItems}
                toggleShown={this.toggleShown}
              ></DropDown>
            )}
        </CodeCard>
      );
    } else if (this.props.codeDict.immutable){
      return (
        <CodeCard>
          <CodeSpan>{this.props.codeDict.code}</CodeSpan>
        </CodeCard>
      )
    } else {
      return <CodeCard />;
    }
  }
}

export default CodeCell;
