import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import Swal from "sweetalert2";
import { withLogin } from "./loggedInPage.js";
import { subscribeToRecovery, checkForAdmin } from "./FirebaseFunctions";
import styled from "styled-components";
import {
  CenteredOuterDiv,
  HeaderSpan,
  showMetaTags,
  StyledCard
} from "./UIUtils";

const RecoverCard = styled(StyledCard)`
  /* overflow: hidden; */
`;

const RecoverTitle = styled.span`
  display: block;
  margin: 20px;
  font-weight: bold;
`;

const RecoverSpan = styled.span`
  display: block;
  margin: 20px;
  font-weight: normal;
  margin-top: -10px;
`;

const StyledSpan = styled.span`
  display: inline-block;
  text-align: center;
  margin-top: 25px;
  width: 100%;
`;

class Recovery extends React.Component {
  state = {
    recovery: [],
    recoveryDeviceIds: [],
    redirect: false
  };
  mounted = false;
  unsubscribeList = [];

  recoveryAdded = recover => {
    let recovery = this.state.recovery;
    let recoveryDeviceIds = this.state.recoveryDeviceIds;

    recovery.unshift(recover);
    recoveryDeviceIds.unshift(recover.deviceId);

    this.setState({ recovery: recovery, recoveryDeviceIds: recoveryDeviceIds });
  };

  recoveryRemoved = recover => {
    const recoveryDeviceIds = this.state.recoveryDeviceIds;
    const index = recoveryDeviceIds.indexOf(recover.deviceId);
    recoveryDeviceIds.splice(index, 1);

    const recovery = this.state.recovery;
    recovery.splice(index, 1);

    this.setState({ recovery: recovery, recoveryDeviceIds: recoveryDeviceIds });
  };

  subToRecover = async () => {
    const that = this;
    try {
      let unsub = await subscribeToRecovery(function(recoverDict, type) {
        console.log("recoverDict : ", recoverDict);
        const index = that.state.recoveryDeviceIds.indexOf(
          recoverDict.deviceId
        );
        if (type == "added" && index == -1) {
          that.recoveryAdded(recoverDict);
        } else if (type == "removed" && index != -1) {
          that.recoveryRemoved(recoverDict);
        }
      });
      this.unsubscribeList.push(unsub);
    } catch (e) {
      console.log("error : ", e);
    }
  };

  init = async () => {
    const rsp = await checkForAdmin();
    if (rsp && rsp.isAdmin) {
      this.subToRecover();
      return;
    }
    this.setState({ redirect: true });
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    if (this.state.recovery.length) {
      return (
        <CenteredOuterDiv>
          {this.state.recovery.map(recover => (
            <RecoverCard key={recover.deviceId}>
              {Object.keys(recover).map(key => (
                <div key={key}>
                  {key == "deviceId" && (
                    <RecoverTitle>{recover[key]}</RecoverTitle>
                  )}
                  {key != "deviceId" && (
                    <>
                      <RecoverTitle>{key}</RecoverTitle>
                      <RecoverSpan>{recover[key]}</RecoverSpan>
                    </>
                  )}
                </div>
              ))}
            </RecoverCard>
          ))}
        </CenteredOuterDiv>
      );
    } else {
      return (
        <CenteredOuterDiv>
          <StyledSpan>No Recovery Codes</StyledSpan>
        </CenteredOuterDiv>
      );
    }
  }
}

export default withLogin(Recovery);
