import { ComplianceStrings } from "../strings";

export const COMPLIANCE_STATE = {
  STATE_COMPLIANT: ComplianceStrings.STATE_COMPLIANT,
  STATE_CHECKING: ComplianceStrings.STATE_CHECKING,
  STATE_DEPLOYING: ComplianceStrings.STATE_DEPLOYING,
  STATE_ERROR_DEPLOYING: ComplianceStrings.STATE_ERROR_DEPLOYING,
  STATE_NON_COMPLIANT: ComplianceStrings.STATE_NON_COMPLIANT
};

const getComplianceCheckingValue = () => "checking";

export const getComplianceStatus = (checking, state) => {
  if (checking === null || state === null) return null;

  if (checking === getComplianceCheckingValue())
    return COMPLIANCE_STATE.STATE_CHECKING;

  if (state === "compliant") return COMPLIANCE_STATE.STATE_COMPLIANT;
  else if (state === "running") return COMPLIANCE_STATE.STATE_DEPLOYING;
  else if (state === "error") return COMPLIANCE_STATE.STATE_ERROR_DEPLOYING;
  else return COMPLIANCE_STATE.STATE_NON_COMPLIANT;
};
