import { createMuiTheme } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";

export const primaryColor = "#F78130";

const themeMyPlayer = createMuiTheme({
  palette: {
    primary: { 500: "#F78130" },
    error: orange
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:hover:not($disabled):not($error):before": {
          borderBottom: "none"
        }
      }
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: "#00FF00"
      },
      colorError: {
        backgroundColor: "#7F7F7F"
      }
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: "#00FF00"
      }
    },
    MuiButton: {
      containedPrimary: {
        color: "white"
      },
      root: {
        borderRadius: 20
      }
    },
    MuiDivider: {
      root: {
        border: `2px solid ${primaryColor}`
      }
    }
  }
});

export default themeMyPlayer;
