import React from 'react';
import logo from '../images/long_icon.png';

class Footer extends React.Component {

  componentDidMount() {
  }

  render() {
      return(
        <div className="footer">
          <a href="https://www.myplayer.io" id="myplayer_logo">
              <img src={logo}/>
          </a>
          <a href="https://www.youtube.com/channel/UC2R2KlCz25C0taPB-LQE2xA">
              <img src="https://static.wixstatic.com/media/e7728d_d8d72969e9ea4fd5991f2da5eaeea89b~mv2.png/v1/fill/w_45,h_45,al_c,q_80,usm_0.66_1.00_0.01/e7728d_d8d72969e9ea4fd5991f2da5eaeea89b~mv2.png"/>
          </a>
          <a href="https://www.linkedin.com/company/myplayer/">
              <img src="https://static.wixstatic.com/media/e7728d_2d38085d762241919fb35f092d0fd1e7~mv2.png/v1/fill/w_45,h_45,al_c,q_80,usm_0.66_1.00_0.01/e7728d_2d38085d762241919fb35f092d0fd1e7~mv2.png"/>
          </a>
          <a href="https://www.myplayer.io">
              <span>© MyPlayer Limited 2018</span>
          </a>
      </div>
      )
  }
}

export default Footer;
