import React from "react";
import { Redirect } from "react-router";
import styled from "styled-components/macro";
import addButton from "../images/addButton.svg";
import {
  showMultiOption,
  showInputMessage,
  StyledCard,
  showMessage
} from "../UIUtils";
import {
  getProject,
  getProjectIdFromCode,
  addProjectForUser,
  getLoggedInUsersId,
  checkProjectIdExistsForUser
} from "../FirebaseFunctions";

const ProjectCard = styled(StyledCard)``;

const ProjectCardSizer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 80%;
  margin-bottom: 20%;
  border-radius: 10px 10px 0px 0px;
`;

const AddIcon = styled.img`
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  &:hover {
    opacity: 0.5;
  }
`;

const AddText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  color: black;
  transform: translateX(-50%);
  margin-top: 50px;
`;

const AddIconB = styled.img`
  width: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  &:hover {
    opacity: 0.5;
  }
`;

const AddTextB = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

class AddProjectCell extends React.Component {
  state = {
    redirectToCreate: false
  };

  addProject = async () => {
    const rsp = await showMultiOption(
      "Add a Project",
      "Create a new project or Join an Existing One with a project code",
      "Join",
      "Create"
    );
    console.log("rsp : ", rsp);
    if (rsp.dismiss == "cancel") {
      this.setState({ redirectToCreate: true });
    } else if (rsp.value) {
      const codes = await showInputMessage(
        "Please enter your MyPlayer project code",
        "MyPlayer project code"
      );
      if (codes.value) {
        var code = codes.value;
        const project = await getProjectIdFromCode(code);
        if (!project) {
          showMessage("Project Not Found");
          return;
        }
        const existingProjects = await checkProjectIdExistsForUser(
          project.projectId,
          getLoggedInUsersId()
        );
        if (existingProjects) {
          let proj = await getProject(existingProjects.projectId);
          console.log("existing project : ", proj);
          showMessage(proj.name + " Project Already Joined");
          return;
        }
        const rsp = await addProjectForUser(
          project.projectId,
          getLoggedInUsersId(),
          undefined,
          code
        );
        showMessage("Project Joined");
      }
    }
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    if (this.state.redirectToCreate) {
      return <Redirect to="/create" />;
    }
    if (this.props.asRow) {
      return (
        <ProjectCard onClick={this.addProject}>
          <AddIconB src={addButton} />
          <AddTextB>Add Project</AddTextB>
        </ProjectCard>
      );
    } else {
      return (
        <ProjectCard onClick={this.addProject}>
          <ProjectCardSizer>
            <AddIcon src={addButton} />
            <AddText>Add Project</AddText>
          </ProjectCardSizer>
        </ProjectCard>
      );
    }
  }
}

export default AddProjectCell;
