import React from "react";
import { Redirect, Route } from "react-router-dom";

import { withAuth } from "../authentication";
import { Spinner } from "../UIUtils"

/**
 * props (from withAuth): auth: { user, initialized }
 * props: authCheck, authFailPath
 */
const AuthRoute = props => {
  // check if auth is initialized
  const authInitialized = props.auth.initialized;
  // check if redirected from another Route
  const from =
    props.location.state && props.location.state.from
      ? props.location.state.from
      : null;
  // override the redirect path upon authCheck fail
  const redirectPath = from ? from : props.authFailPath;
  return (
    <>
      {authInitialized &&
        (props.authCheck(props.auth.user) ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location.pathname }
            }}
          />
        ))}
      {!authInitialized && <Spinner />}
    </>
  );
};

export default withAuth(AuthRoute);
