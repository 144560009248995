import React from "react";
import styled from "styled-components/macro";
import ProjectBaseUI from "./ProjectBaseUI";
import { EmptyTablePlaceholder } from "../UIUtils";
import { showInputMessage } from "../UIUtils";
import { getProjectAnalytics, setProjectAnalytics } from "../FirebaseFunctions";

const OuterDiv = styled.div`
  /* margin-top: 30px; */
`;

const AnalayticsIframe = styled.iframe`
  position: relative;
  width: 100%;
  height: 2200px;
  left: 50%;
  border: none;
  min-width: 1250px;
  transform: translateX(-50%);
`;

const ClickHereSpan = styled(EmptyTablePlaceholder)`
  color: #f7822f;
  font-weight: bold;
  cursor: pointer;
`;

class ProjectAnalytics extends ProjectBaseUI {
  askForAnalyticsCode = async () => {
    const that = this;
    const rsp = await showInputMessage("Please Enter Your Analytics Code", "");
    if (rsp.value) {
      // 1UG_QsUMhoAmf1wV6W24sW3W83MEr7PNJ
      let url =
        "https://datastudio.google.com/embed/reporting/" +
        rsp.value +
        "/page/1M";
      console.log("add analytics to : ", that.state.project.projectId, url);
      const resp = await setProjectAnalytics({
        projectId: that.state.project.projectId,
        analytics: url
      });
      console.log("resp : ", resp);
    }
  };

  grabAnalytics = async () => {
    const dat = await getProjectAnalytics(this.state.project.projectId);
    if(!dat)return;
    let project = this.state.project;
    project.analytics = dat.analytics;
    this.setState({ project: project });
  };

  componentWillUpdate() {
    if (
      this.state.project &&
      this.state.project.permissions &&
      this.state.project.permissions.analytics.read &&
      !this.state.project.analytics
    ) {
      this.grabAnalytics();
    }
  }

  render() {
    if (
      this.state.project &&
      !this.state.project.deleted &&
      !this.state.removed &&
      this.state.project.analytics &&
      this.state.project.creatorId
    ) {
      return (
        <OuterDiv>
          {this.state.project.analytics &&
            this.state.project.permissions &&
            this.state.project.permissions.analytics.read && (
              <AnalayticsIframe
                src={this.state.project.analytics}
              ></AnalayticsIframe>
            )}
        </OuterDiv>
      );
    } else {
      return (
        <>
          <EmptyTablePlaceholder>
            This project doesn't have an analytics account set up yet.
          </EmptyTablePlaceholder>
          {this.state.project &&
            this.state.project.projectId &&
            this.state.project.permissions &&
            this.state.project.permissions.analytics.write && (
              <ClickHereSpan onClick={this.askForAnalyticsCode}>
                Click here to add one
              </ClickHereSpan>
            )}
        </>
      );
    }
  }
}

export default ProjectAnalytics;
