import React from "react";
import { Button, Grid } from "@material-ui/core";

import {
  deployDeviceInProject,
  subscribeToDeviceStatus
} from "../FirebaseFunctions";
import { showConfirmation, showToast, showErrorToast } from "../UIUtils";
import { DeviceStrings } from "../strings";
import { getEndMoment, isDeploying } from "../utils/projectProgressUtil";

import DeployDetails from "./DeployDetails";
import { BorderedBox } from "./styled";

/**
 * props: deviceId, projectId
 */
class DeviceContentDeployPanel extends React.Component {
  state = {
    deploying: null,
    deployPending: false,
    lastDeployedMoment: null
  };

  unsubList = [];

  deployContent = async () => {
    const { deviceId, projectId } = this.props;
    const confirm = await showConfirmation(
      DeviceStrings.DEVICE_CONTENT_DEPLOY_CONTENT_TITLE,
      DeviceStrings.DEVICE_CONTENT_DEPLOY_CONTENT_TEXT
    );
    if (confirm.value) {
      const result = await deployDeviceInProject(projectId, deviceId);
      console.debug("deployDeviceInProject", result);
      if (result.success) {
        // set pending until deploying status update
        const deployPending = true;
        this.setState({ deployPending });

        showToast(DeviceStrings.DEVICE_REQUEST_RECEIVED, 2000);
      } else {
        const msg =
          result.errors.length > 0
            ? result.errors[0]
            : DeviceStrings.DEVICE_ERROR_REQUEST_FAILED;
        showErrorToast(msg);
      }
    }
  };

  shouldDisableButton = () => {
    const { deploying, deployPending } = this.state;
    return deploying || deployPending;
  };

  onStatusUpdate = status => {
    const progress = status.project_progress;

    const deploying = isDeploying(progress);
    const lastDeployedMoment = getEndMoment(progress);
    // cancel pending if already deploying
    const deployPending = deploying ? false : this.state.deployPending;
    this.setState({ deploying, deployPending, lastDeployedMoment });
  };

  componentDidMount = async () => {
    const { deviceId } = this.props;
    this.unsubList.push(
      await subscribeToDeviceStatus(deviceId, this.onStatusUpdate)
    );
  };

  componentWillUnmount = () => {
    this.unsubList.forEach(unsub => unsub());
  };

  render = () => {
    const { deviceId, projectId } = this.props;
    if (!deviceId || !projectId) return <></>;

    const { deployPending, lastDeployedMoment } = this.state;
    return (
      <BorderedBox p={2}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={8} container spacing={1}>
            <Grid item xs={12}>
              {DeviceStrings.DEVICE_CONTENT_COMPLIANCE_LAST_DEPLOYED}
              {lastDeployedMoment &&
                `: ${new Date(lastDeployedMoment).toUTCString()}`}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.deployContent}
              disabled={this.shouldDisableButton()}
            >
              {DeviceStrings.DEVICE_BUTTON_DEPLOY_CONTENT}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DeployDetails deviceId={deviceId} deployPending={deployPending} />
        </Grid>
      </BorderedBox>
    );
  };
}

export default DeviceContentDeployPanel;
