import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import "./css/Dashboard.css";
import ProjectHeader from "./components/ProjectHeader";
import ProjectPageSections from "./components/ProjectPageSections"
import styled from "styled-components";
import {withLogin} from "./loggedInPage.js"
import { withRouter } from "react-router";
import { CenteredOuterDiv, showMetaTags } from "./UIUtils";

const Header = styled.div`
  width: 100%;
  display: block;
`;

const BackButton = styled.div`
  padding: 20px 10px;
  color: black;
  border-radius: 5px;
  display: block;
  cursor: pointer;
  margin-left: -10px;
`;

class ProjectPage extends React.Component {
  state = {
    project: null
  };

  unsubscribeList = [];

  componentDidMount() {
    var self = this;
    let project = { projectId: this.props.match.params.projectId };
    this.setState({ ...project, project });
  }

  componentWillUnmount() {
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  back = () => {
    this.props.history.push("/dashboard")
  }

  render() {
    if (this.state.project) {
      return (
        <CenteredOuterDiv>
        {showMetaTags("MyPlayer Project", 'Your MyPlayer Project')}
          <BackButton onClick={this.back}>&#8592; BACK</BackButton>
          <Header>
            <ProjectHeader
              project={this.state.project}
              usersId={this.props.user.uid}
            ></ProjectHeader>
          </Header>
          <ProjectPageSections project={this.state.project}
          usersId={this.props.user.uid} startingPage={this.props.startingPage} history = {this.props.history}>
          </ProjectPageSections>
        </CenteredOuterDiv>
      );
    } else {
      return <></>;
    }
  }
}

export default withLogin(ProjectPage);
