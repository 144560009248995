import React from "react";
import { Redirect } from "react-router";
import { withLogin } from "./loggedInPage.js";
import firebase from "firebase";
import styled from "styled-components/macro";
import {
  makeRandomProjectId,
  getLoggedInUsersId,
  uploadFile,
  cancelUpload,
  createProject,
  getProject,
  updateProject
} from "./FirebaseFunctions";
import {
  CenteredOuterDiv,
  HeaderSpan,
  StyledCard,
  DescSpan,
  showMessage,
  showPercentageIndicator,
  showMetaTags
} from "./UIUtils";
import addImage from "./images/addImage.svg";
import "./css/MaterialForm.css";

const OuterDiv = styled(CenteredOuterDiv)`
  margin-top: 75px;
`;

const ProjectCard = styled(StyledCard)`
  vertical-align: middle;
`;

const ProjectImage = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 80%;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
`;

const ProjectInfo = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 35px;
`;

const CreateButton = styled.div`
  display: inline-block;
  background-color: #ff9300;
  padding: 10px 30px;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
`;

const HiddenInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  opacity: 0;
  cursor: pointer;
  top: 0px;
  left: 0px;
`;

const InputGroup = styled.div`
  position: relative;
  margin: 45px 0;
`;

const InputText = styled.div`
  background: none;
  color: #c6c6c6;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 320px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
`;

const AddPhotoImageOuter = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0.3;
  transition: opacity 0.3s ease;
  border-radius: 10px;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }
`;

const TextInput = styled.textarea`
  font-family:myplayer;
`

const AddPhotoImage = styled.img`
  height: 35px;
  margin-top: calc(50% - 50px);
`;

class CreateProjectPage extends React.Component {
  defaultImage =
    "https://firebasestorage.googleapis.com/v0/b/myplayerexperience2.appspot.com/o/images%2Fpowered.png?alt=media&token=1f3eab4b-57c7-473c-a63b-125363c73adc";

  uploadManager = {
    currentUploadTask: null
  };

  uploadImage = null;
  isEditing = false;
  preEditedProject = null;

  state = {
    pageHeader: "Create Project",
    saveButton: "Create",
    uploadImage: null,
    titleValue: "",
    descValue: "",
    redirectURL: null
  };

  updateTitleValue = e => {
    this.setState({
      titleValue: e.target.value
    });
  };

  updateDescValue = e => {
    this.setState({
      descValue: e.target.value
    });
  };

  finaliseProjectCreation = async projectObject => {
    if (this.isEditing) {
      delete projectObject.creation_date;
      projectObject.modified_date = Date.now();
      console.log("Edit Project : ", projectObject);
      const rsp = await updateProject(projectObject);
      const rspTwo = showMessage("Project Updated");
      this.setState({ redirectURL: true });
    } else {
      projectObject.deleted = false;
      console.log("create Project : ", projectObject);
      const rsp = await createProject(projectObject);
      const rspTwo = showMessage("Project Created");
      this.setState({ redirectURL: true });
    }
  };

  createProject = async () => {
    console.log("create project");
    const that = this;
    if (!this.state.titleValue.length) {
      const rspTwo = showMessage("Title cannot be Empty");
      return;
    }
    if (!this.state.descValue.length) {
      const rspTwo = showMessage("Description cannot be Empty");
      return;
    }

    let projectId = makeRandomProjectId();
    let creatorId = getLoggedInUsersId();
    if (this.isEditing) {
      projectId = this.props.match.params.projectId;
      creatorId = this.preEditedProject.creatorId;
    }
    let uploadImage = this.state.uploadImage;
    const projectObject = {
      name: this.state.titleValue,
      desc: this.state.descValue,
      creatorId: creatorId,
      projectId: projectId,
      creator: creatorId,
      creation_date: Date.now()
    };
    console.log("uploadImage : ", uploadImage);
    if (
      !uploadImage ||
      uploadImage == this.defaultImage ||
      (this.isEditing && uploadImage == this.preEditedProject.imageURL)
    ) {
      if(uploadImage == null){
        projectObject.imageURL = this.defaultImage;
      }
      that.finaliseProjectCreation(projectObject);
    } else {
      var percentId = "percentageIndicator";
      showPercentageIndicator("Uploading Project", percentId).then(
        function() {
          cancelUpload(that.uploadManager);
        }
      );
      var percentSpan = document.getElementById(percentId);
      uploadFile(
        "/projectImages/" + projectObject.projectId,
        this.uploadImage,
        that.uploadManager,
        function(progress) {
          percentSpan.textContent = progress.toFixed(0) + "%";
        }
      ).then(function(rsp) {
        projectObject.imageURL = rsp;
        that.finaliseProjectCreation(projectObject);
      });
    }
  };

  handleImageChange = event => {
    const that = this;
    const fr = new FileReader();
    fr.onload = function() {
      that.setState({ uploadImage: fr.result });
    };
    this.uploadImage = event.target.files[0];
    fr.readAsDataURL(this.uploadImage);
  };

  handleClick = e => {
    this.inputElement.click();
  };

  checkForEdit = async () => {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.projectId
    ) {
      let projectId = this.props.match.params.projectId;
      this.setState({ pageHeader: "Save Changes" });
      const project = await getProject(projectId);
      this.preEditedProject = project;
      this.setState({
        uploadImage: project.imageURL,
        titleValue: project.name,
        descValue: project.desc
      });
      this.isEditing = true;
    }
  };

  componentDidMount() {
    this.checkForEdit();
  }

  render() {
    if (this.state.redirectURL) {
      return <Redirect to="/" />;
    }
    return (
      <OuterDiv>
      {showMetaTags("Edit Project", 'Edit Your MyPlayer Project')}
        <HeaderSpan> {this.state.pageHeader}</HeaderSpan>
        <DescSpan>
          {" "}
          Choose a Project Title, Description and image which best represent
          your project{" "}
        </DescSpan>
        <ProjectCard>
          <ProjectImage
            onClick={this.addImage}
            bgImage={this.state.uploadImage}
          />
          <HiddenInput
            ref={input => (this.inputElement = input)}
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={this.handleImageChange.bind(this)}
          />
          <AddPhotoImageOuter onClick={this.handleClick}>
            <AddPhotoImage src={addImage} />
          </AddPhotoImageOuter>
        </ProjectCard>
        <ProjectInfo>
          <InputGroup className="group">
            <input
              type="text"
              required="required"
              value={this.state.titleValue}
              onChange={this.updateTitleValue}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>Title</label>
          </InputGroup>
          <InputGroup className="group">
            <TextInput
              type="textarea"
              rows="5"
              required="required"
              value={this.state.descValue}
              onChange={this.updateDescValue}
            ></TextInput>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>Description</label>
          </InputGroup>
        </ProjectInfo>
        <br />
        <CreateButton onClick={this.createProject}>
          {this.state.pageHeader}
        </CreateButton>
      </OuterDiv>
    );
  }
}

export default withLogin(CreateProjectPage);
