const STRINGS = {
  FORM_EMAIL: "Email",
  FORM_PASSWORD_REGISTER: "Choose password",
  FORM_PASSWORD_SIGNIN: "Password",
  FORM_NAME: "First & last name",
  FORM_REGISTER: "Register",
  FORM_SIGNIN: "Sign In",

  ERROR_SIGN_IN: { "auth/wrong-password": "Invalid Details, please try again" },

  ERROR_REGISTER_TRANSATION: {
    "auth/email-already-in-use": "User already registered, please sign in"
  },

  LINK_REGISTER: "Create an account here",
  LINK_SIGNIN: "Already Have an account? Sign in here",

  META_TITLE: "Login",
  META_DESCRIPTION: "Login to MyPlayer",

  TITLE_SIGNIN: "MyPlayer Sign In",
  TITLE_REGISTER: "MyPlayer Register",

  FORGOT_PASSWORD: "Forgot Password?",
  FORGOT_PASSWORD_TITLE: "Enter your email address used to register",
  FORGOT_PASSWORD_PLACEHOLDER: "Enter your email address",
  FORGOT_PASSWORD_DONE_TITLE: "Password Reset Email Sent",
  FORGOT_PASSWORD_DONE_TEXT: "Please check your {email} inbox",

  TOP_BANNER_MESSAGE: "You must be signed in to access this page"
};

export default STRINGS;
