import React from "react";

import { withFirebase } from "../Firebase";
import LogoHeader from "../components/LogoHeader";
import { HomeStrings } from "../strings";

import { SignInLinkSpan } from "./styled"
import RegisterForm from "./RegisterForm";

class RegisterContent extends React.Component {
  onRegister = async (email, password, name) => {
    // register with firebase
    const credential = await this.props.firebase.registerUser(email, password)
    // register successful

    // convert characters
    const emailConverted = email.replace(/\./g, "~");

    // create user profile
    this.props.firebase
      .createUserProfile(emailConverted, name, credential.user.uid)
      .catch(error => {
        throw error;
    });
  }

  render() {
    return (
      <div>
        <LogoHeader title={HomeStrings.TITLE_REGISTER} />
        <RegisterForm onRegister={this.onRegister} setProcessing={this.props.setProcessing} />
        <SignInLinkSpan onClick={this.props.toggleSignIn}>
          {HomeStrings.LINK_SIGNIN}
        </SignInLinkSpan>
      </div>
    );
  }
}

export default withFirebase(RegisterContent);
