import React from 'react';
import styled from 'styled-components/macro';
import { subscribeToUsersIdsInProject, subscribeToProjectChanges} from '../FirebaseFunctions'
import UserRow from './UserRow'

class UserList extends React.Component {

  unsubscribeList = [];
  state = {
      users: [],
      project: null,
      creator: null
  };

  grabUsers = async () => {
    const that = this;

    let unsub = await subscribeToUsersIdsInProject(this.props.project.projectId, function(user, type){
      let found = that.state.users.findIndex( element => user.usersId  == element.usersId);
      if(type == 'added' && found == -1){
        if(user.usersId != that.state.creator.usersId){
          that.setState({users:[...that.state.users, user]})
        }
      }
      else if(type == 'removed' && found > -1){
        let users = that.state.users;
        users.splice(found, 1);
        that.setState({users : users})
      }
    });
    this.unsubscribeList.push(unsub)
  }

  getProjectChanges = async () => {
    const that = this;
    /* subscribe to project info changes */
    let unsub = await subscribeToProjectChanges(this.props.project.projectId, function(rsp){
      let useProject = {...that.state.project, ...rsp};
      that.setState({project:useProject})
      that.setState({creator:{usersId : useProject.creator}})
    });
    this.unsubscribeList.push(unsub)
  }

  componentDidMount() {
    const creator = {usersId : this.props.project.creator};
    this.setState({creator:creator});
    this.getProjectChanges();
    this.grabUsers();
  }

  componentWillUnmount () {
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    // console.log("this.props.project : ", this.props.project, this.state.users,this.state.creator);
    if(this.state.project && this.state.users && this.state.creator){
      return(
        <>
        <UserRow user={this.state.creator} project={this.state.project} isCreator={true} key={this.state.project.creator}></UserRow>
        {this.state.users.map(user =>
          <UserRow user={user} project={this.state.project}
          key={user.usersId} isCreator={false}></UserRow>
        )}
        </>
      )
    }
    else{
      return null
    }
  }
}

export default UserList;
