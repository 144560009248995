import React from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import styled from 'styled-components/macro';
import shareImage from '../images/ShareIcon.svg'
import {getProjectCodeForId} from '../FirebaseFunctions'
import {showMessage, showMultiOption} from '../UIUtils'

const ShareIcon = styled.img`
  width: 30px;
  margin-top: -5px;
  &:hover {
    opacity: 0.5;
  }
`;

class ShareButton extends React.Component {

  getShareCode = async (forceRefresh) => {
    const rsp = await getProjectCodeForId(this.props.project.projectId, forceRefresh)
    const url = window.location.origin + '/join/project/' + rsp.code;
    const resp = await showMultiOption(
      "Here is your project share link",
      url,
      "reload",
      "ok"
    );
    console.log("rsp : ", rsp)
    if (resp.value) {
      this.getShareCode(true);
    }
  }

  render() {
      if(this.props.project && this.props.project.permissions && this.props.project.permissions.code.write){
        return(
            <ShareIcon src={shareImage} onClick={this.getShareCode}></ShareIcon>
        )
      }
      else{
        return null;
      }
  }
}

export default ShareButton;
