import React from "react";
import firebase from "firebase";
import styled from "styled-components/macro";
import {
  getLoggedInUsersId,
  getUser,
  subscribeToUser
} from "../FirebaseFunctions";
import { CenteredOuterDiv } from "../UIUtils";
import PersonCircle from "./PersonCircle";
import UserHeaderDropDown from "./UserHeaderDropDown";

const UserHeaderCard = styled(CenteredOuterDiv)`
  font-weight: bold;
  z-index:1;
  min-height:100px
`;

const accountNameStyle = {
  textAlign: "center",
  display: "inline-block",
  fontWeight: "600",
  padding: "20px 0px 0px 0px",
  fontSize: "24px",
  marginTop: "-15px",
  verticalAlign: "middle",
  marginRight: "10px",
  marginLeft: "10px"
};

const photoOuterStyle = {
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  verticalAlign: "middle"
};

class UserHeader extends React.Component {
  unsubscribeList = [];
  state = {
    user: {}
  };

  subscribeToUser = async () => {
    const that = this;
    let unsub = await subscribeToUser(getLoggedInUsersId(), function(
      user,
      type
    ) {
      let useUser = { ...that.state.user, ...user };
      that.setState({ user: useUser });
    });
    this.unsubscribeList.push(unsub);
  };

  componentDidMount() {
    this.subscribeToUser();
  }

  componentWillUnmount() {
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    return (
      <UserHeaderCard>
        <div className="photo_outer" style={photoOuterStyle}>
          <PersonCircle
            user={this.state.user}
            width="100px"
            height="100px"
            margin="30px 10px 20px 0px"
            fontSize="40px"
          ></PersonCircle>
        </div>
        <div className="name_outer" style={photoOuterStyle}>
          <span className="account_name" style={accountNameStyle}>
            {this.state.user.name}
          </span>
          <UserHeaderDropDown user={this.state.user} />
        </div>
      </UserHeaderCard>
    );
  }
}

export default UserHeader;
