import React from "react";
import { Box, Divider, Grid, LinearProgress } from "@material-ui/core";
import { CheckCircleOutlineSharp, DevicesSharp } from "@material-ui/icons";

import { getDeviceStatus, subscribeToDeviceStatus } from "../FirebaseFunctions";
import { DeviceStrings } from "../strings";
import {
  isDeploying,
  getLastInstalledApps,
  getLastTotalFiles,
  getLastUpdatedFiles,
  getLastInstallStage,
  isInstallStage,
  isAppStage,
  isContentStage,
  getFormattedLabel
} from "../utils/projectProgressUtil";

const INSTALL_INDEX = 0;
const APP_INDEX = 1;
const CONTENT_INDEX = 2;

const ProgressRow = props => {
  const { label, info, inProgress } = props;
  const formattedLabel = getFormattedLabel(label, info);
  return (
    <Grid container direction="column" spacing={1}>
      <Grid
        item
        container
        justify="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <DevicesSharp />
            </Grid>
            <Grid item>{formattedLabel}</Grid>
          </Grid>
        </Grid>
        {!inProgress && (
          <Grid item>
            <CheckCircleOutlineSharp />
          </Grid>
        )}
      </Grid>
      <Grid item>
        {inProgress && <LinearProgress />}
        {!inProgress && <Divider />}
      </Grid>
    </Grid>
  );
};

/**
 * props: history (project_progress), packages (project.pkgs)
 */
const DeployHistory = props => {
  const { history, packages } = props;
  const lastStage = getLastInstallStage(history);
  const installedApps = getLastInstalledApps(history, packages);
  const updatedFiles = getLastUpdatedFiles(history);
  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProgressRow
            label={lastStage}
            info={history[lastStage]}
            inProgress={false}
          />
        </Grid>
        {installedApps.length > 0 && (
          <Grid item xs={12}>
            <ProgressRow
              label={`${
                DeviceStrings.DEVICE_CONTENT_DEPLOY_INSTALLED_APPS
              }: ${installedApps.join(", ")}`}
              inProgress={false}
            />
          </Grid>
        )}
        {updatedFiles > 0 && (
          <Grid item xs={12}>
            <ProgressRow
              label={`${DeviceStrings.DEVICE_CONTENT_DEPLOY_UPDATED_FILES}: ${updatedFiles}`}
              inProgress={false}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

/**
 * props: deviceId, deployPending
 */
class DeployDetails extends React.Component {
  state = {
    latestStage: [{}, {}, {}],
    deploying: null,
    history: null,
    packages: null
  };

  unsubList = [];

  updateStage = (stage, info) => {
    if (!stage || !info) return;
    const { latestStage } = this.state;
    const index = isInstallStage(stage)
      ? INSTALL_INDEX
      : isAppStage(stage)
      ? APP_INDEX
      : isContentStage(stage)
      ? CONTENT_INDEX
      : -1;
    if (index >= 0) {
      const newStage = [...latestStage];
      newStage[index] = {
        stage,
        info
      };
      this.setState({ latestStage: newStage });
    }
  };

  onStatusUpdate = status => {
    const progress = status.project_progress;
    const deploying = isDeploying(progress);
    const history = deploying ? null : progress;
    const packages = deploying || !status.project ? null : status.project.pkgs;
    console.debug(`onStatusUpdate: deploying=${deploying}`, progress, packages);
    this.setState({ deploying, history, packages });

    this.updateStage(status.stage, status.project_progress[status.stage]);
  };

  componentDidMount = async () => {
    const { deviceId } = this.props;

    // check last time of deployment
    if (deviceId) {
      const status = await getDeviceStatus(deviceId);
      const history = status.project_progress;
      const packages = status.project ? status.project.pkgs : null;
      const deploying = isDeploying(history);
      if (!deploying) this.setState({ history, packages });

      this.unsubList.push(
        await subscribeToDeviceStatus(deviceId, this.onStatusUpdate)
      );
    }
  };

  componentWillUnmount = () => {
    this.unsubList.forEach(unsub => unsub());
  };

  render = () => {
    const { history, packages, latestStage, deploying } = this.state;
    if (deploying === null) return <></>;

    const { deployPending } = this.props;
    if (!deploying && !deployPending)
      return <DeployHistory history={history} packages={packages} />;

    return (
      <Box mt={2}>
        <Grid container spacing={2}>
          {deployPending && (
            <Grid item xs={12}>
              <ProgressRow
                label={DeviceStrings.DEVICE_CONTENT_PROGRESS_CONTACTING_DEVICE}
                inProgress={true}
              />
            </Grid>
          )}
          {!deployPending &&
            latestStage
              .filter(stage => stage.stage)
              .map(stage => (
                <Grid item xs={12} key={`deploy-stage-${stage.stage}`}>
                  <ProgressRow
                    label={stage.stage}
                    info={stage.info}
                    inProgress={deploying}
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
    );
  };
}

export default DeployDetails;
