import React from "react";
import { TextField } from "@material-ui/core";

import { DeviceStrings } from "../strings";

import { BorderedBox } from "./styled";

const dummyMessageLog = () => {
  let messages = "";
  Array(10)
    .fill()
    .forEach(() => {
      messages +=
        new Date().toUTCString() +
        " : " +
        DeviceStrings.DUMMY_TEXT.substring(0, 160) +
        "\r\n";
    });
  return messages;
};

const Message = props => {
  return (
    <BorderedBox mt={3} mb={3} p={1}>
      <TextField
        fullWidth
        multiline
        rows="20"
        value={dummyMessageLog()}
        InputLabelProps={{
          readOnly: true
        }}
        InputProps={{
          style: { fontSize: "small" },
          disableUnderline: true,
          spellCheck: false
        }}
      />
    </BorderedBox>
  );
};

export default Message;
