// import React from "react";
// import styled from "styled-components/macro";
// import { StyledCard } from "../UIUtils";
// import {
//   getDevicesInProject,
//   subscribeToDevicesInProject,
//   subscribeToDeviceHeartbeats,
//   subscribeToDeviceStatus,
//   isDeviceOnline
// } from "../FirebaseFunctions";
//
// const DeviceCard = styled.div`
//   position: relative;
//   display: inline-block;
//   vertical-align: middle;
//   font-size:24px;
// `;
//
// const WidgetSpan = styled.span`
//   margin: 32px;
//   display: block;
// `;
//
// class OnlineDevicesWidget extends React.Component {
//   unsubscribeList = [];
//   state = {
//     devices: [],
//     onlineDevices: []
//   };
//
//   getDeviceStatus = async deviceId => {
//     let that = this;
//     let unsub = await subscribeToDeviceStatus(deviceId, function(device) {
//       let myDate = new Date(device.updatedAt);
//       let result = myDate.getTime();
//       let heartbeat = { timestamp: result / 1000 };
//       let isOnline = isDeviceOnline(heartbeat);
//       let found = that.state.onlineDevices.find(element => deviceId == element);
//       if (isOnline && !found) {
//         that.setState({
//           onlineDevices: [...that.state.onlineDevices, deviceId]
//         });
//       }
//       // that.setState({ isOnline: isOnline, lastOnlineAt: result });
//     });
//     this.unsubscribeList.push(unsub);
//   };
//
//   getDeviceHeartbeats = async deviceId => {
//     let that = this;
//     let unsub = await subscribeToDeviceHeartbeats(deviceId, function(
//       heartbeat,
//       type
//     ) {
//       if (type == "added" && heartbeat) {
//         // that.setState({ lastHeartbeat: heartbeat });
//         let isOnline = isDeviceOnline(heartbeat);
//         let found = that.state.onlineDevices.find(
//           element => deviceId == element
//         );
//         if (isOnline && !found) {
//           // that.setState({
//           //   onlineDevices: [...that.state.onlineDevices, deviceId]
//           // });
//         } else if (!isOnline) {
//           console.warn("Device is offline : ", deviceId, heartbeat);
//         }
//       }
//     });
//     this.unsubscribeList.push(unsub);
//   };
//
//   grabDevices = async () => {
//     var that = this;
//     const devices = await getDevicesInProject(this.props.project.projectId);
//     devices.forEach(device => that.getDeviceStatus(device.deviceId));
//     // devices.forEach(device => that.getDeviceHeartbeats(device.deviceId));
//     that.setState({ devices: devices });
//
//     let unsub = await subscribeToDevicesInProject(
//       this.props.project.projectId,
//       function(device, type) {
//         let found = that.state.devices.find(
//           element => device.deviceId == element.deviceId
//         );
//         if (type == "added" && device && !found) {
//           that.setState({ devices: [...that.state.devices, device] });
//         }
//       }
//     );
//     this.unsubscribeList.push(unsub);
//   };
//
//   componentDidMount() {
//     this.grabDevices();
//   }
//
//   componentWillUnmount() {
//     this.unsubscribeList.forEach(function(unsub) {
//       unsub();
//     });
//   }
//
//   render() {
//     if (this.props.project) {
//       return (
//         <DeviceCard>
//           <WidgetSpan>
//             {"Online Devices :" + this.state.onlineDevices.length}
//           </WidgetSpan>
//         </DeviceCard>
//       );
//     } else {
//       return null;
//     }
//   }
// }
//
// export default OnlineDevicesWidget;
import React from "react";
import styled from "styled-components/macro";
import {StyledCard, AnimatingDiv} from "../UIUtils";
import {
  getNumberOfDevicesInProject,
  grabNumberOfOnlineDevicesInProject,
  getNumberOfOnlineDevicesInProject
} from "../FirebaseFunctions";

const DeviceCard = styled(StyledCard)`
  overflow:hidden;
  min-height:80px;
`;

const WidgetSpan = styled.span`
  margin: 32px;
  display: block;
`;

class OnlineDevicesWidget extends React.Component {
  unsubscribeList = [];
  state = {
    onlineDevices: -1
  };
  mounted = false;

  getOnlineDevices = async(remote) =>{
    if(!this.mounted)return;
    const projectId = this.props.project.projectId;
    const onlineNumber = await grabNumberOfOnlineDevicesInProject(projectId, remote);
    return onlineNumber;
  }

  init = async () => {
    if(!this.mounted)return;
    let onlineNumber = await this.getOnlineDevices(false);
    if(!this.mounted)return;
    if(onlineNumber > 0 ){
      this.setState({onlineDevices : onlineNumber});
    }
    if(!this.mounted)return;
    onlineNumber = await this.getOnlineDevices(true);
    this.setState({onlineDevices : onlineNumber});
    // console.log("onlineNumber : ", onlineNumber);
  }

  subscribeToOnlineDevices = async() => {
    if(!this.mounted)return;
    let that = this;
    const projectId = this.props.project.projectId;
    let unsub = await getNumberOfOnlineDevicesInProject(
      projectId,
      function(doc, type) {
        if(!that.mounted)return;
        if(type == 'added'){
          let num = that.state.onlineDevices;
          num++;
          that.setState({onlineDevices : num});
        }
        else if(type == 'removed'){
          let num = that.state.onlineDevices;
          num--;
          if(num < 0 ) num = 0;
          that.setState({onlineDevices : num});
        }
      }
    );
    this.unsubscribeList.push(unsub);
  }

  componentDidMount() {
    this.mounted = true;
    // this.grabDeviceNumber();
    // this.subscribeToOnlineDevices();
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    if (this.props.project) {
      return (
        <DeviceCard>
          {this.state.onlineDevices == -1 && (
            <AnimatingDiv></AnimatingDiv>
          )}
          {this.state.onlineDevices >= 0 && (
            <WidgetSpan>{"Online Devices : " + this.state.onlineDevices}</WidgetSpan>
          )}
        </DeviceCard>
      );
    } else {
      return null;
    }
  }
}

export default OnlineDevicesWidget;
