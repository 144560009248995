import { DeviceStrings } from "../strings";

const INSTALL_STAGES = [
  "onProjectObtained",
  "installProject",
  "handleStartTasks",
  //   "alreadyRegistered",
  "onDeviceRegistration",
  "onDeviceAddedToProject",
  //   "onProjectMedia",
  "processMediaFiles",
  "processUnmatchedMediaFiles",
  "onAllMediaFilesHandled",
  "onUpdatesCompleted",
  "handleEndTasks", // <<<
  "onEndTasksCompleted",
  // "notifyDeviceCompliant",
  "Task RestartServices"
];

const APP_STAGES = [
  "processUninstalledPkgs",
  "downloadAndInstallApk",
  "onPackageInstalled"
];

const CONTENT_STAGES = [
  "onBlobDownloaded", // <<<
  "onBlobsDownloaded",
  "overwriteUnmatchedFiles"
];

const START_STAGES = ["onProjectObtained", "installProject"];
const END_STAGES = ["onEndTasksCompleted", "Task RestartServices"];

const UPDATED_FILES_STAGE = "overwriteUnmatchedFiles";
const UPDATED_FILES_FIELD = "nUnmatchedFiles";

const TOTAL_FILES_STAGE = "processMediaFiles";
const TOTAL_FILES_FIELD = "numberOfMediaFiles";

const INSTALL_APPS_KEY = "pkgs";

const LABELS = {
  onProjectObtained: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_PROJECT_OBTAINED,
  installProject: () => DeviceStrings.DEVICE_CONTENT_PROGRESS_INSTALL_PROJECT,
  handleStartTasks: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_HANDLE_START_TASKS,
  onDeviceRegistration: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_DEVICE_REGISTRATION,
  onDeviceAddedToProject: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_DEVICE_ADDED_TO_PROJECT,
  processMediaFiles: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_PROCESS_MEDIA_FILES,
  onAllMediaFilesHandled: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_ALL_MEDIA_FILES_HANDLED,
  onUpdatesCompleted: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_UPDATES_COMPLETED,
  handleEndTasks: () => DeviceStrings.DEVICE_CONTENT_PROGRESS_HANDLE_END_TASKS,
  processUnmatchedMediaFiles: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_PROCESS_UNMATCHED_MEDIA_FILES,
  onBlobDownloaded: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_BLOB_DOWNLOADED,
  onBlobsDownloaded: info =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_BLOBS_DOWNLOADED.replace(
      "{}",
      info && info.downloadedBlobs ? info.downloadedBlobs.length : ""
    ),
  overwriteUnmatchedFiles: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_OVERWRITE_UNMATCHED_FILES,
  onEndTasksCompleted: () =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_END_TASKS_COMPLETED,
  //   notifyDeviceCompliant:
  //     DeviceStrings.DEVICE_CONTENT_PROGRESS_NOTIFY_DEVICE_COMPLIANT,
  processUninstalledPkgs: info =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_PROCESS_UNINSTALL_PKGS.replace(
      "{}",
      info && info.pkg && info.pkg.appName ? info.pkg.appName : ""
    ),
  downloadAndInstallApk: info =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_PROCESS_UNINSTALL_PKGS.replace(
      "{}",
      info && info.appNameVer ? info.appNameVer : ""
    ),
  onPackageInstalled: info =>
    DeviceStrings.DEVICE_CONTENT_PROGRESS_ON_PACKAGE_INSTALLED.replace(
      "{}",
      info && info.pkgName ? info.pkgName.split(".").reverse()[0] : ""
    )
};

export const getFormattedLabel = (stage, info) => {
  if (stage in LABELS) {
    return LABELS[stage](info);
  } else {
    return stage;
  }
};

const getNewestStage = (progress, stages) =>
  stages
    .reverse()
    .sort(
      (a, b) => new Date(progress[b].moment) - new Date(progress[a].moment)
    )[0];

const isMomentDone = (moment, startMoment) => moment >= startMoment;

const getLastStage = (progress, stages) => {
  const start = getStartMoment(progress);
  const newestStage = getNewestStage(progress, stages);
  const newestMoment = progress[newestStage].moment;
  return newestMoment >= start ? newestStage : null;
};

export const getStartMoment = progress =>
  // take max of all stages in case of stage update lost
  progress[getNewestStage(progress, START_STAGES)].moment;

export const getEndMoment = progress =>
  // take max of all stages in case of stage update lost
  progress[getNewestStage(progress, END_STAGES)].moment;

export const isDeploying = progress =>
  getEndMoment(progress) < getStartMoment(progress);

export const getLastUpdatedFiles = progress => {
  const start = getStartMoment(progress);
  const moment = progress[UPDATED_FILES_STAGE].moment;
  if (isMomentDone(moment, start))
    return progress[UPDATED_FILES_STAGE][UPDATED_FILES_FIELD];
  else return null;
};

export const getLastTotalFiles = progress => {
  const start = getStartMoment(progress);
  const moment = progress[TOTAL_FILES_STAGE].moment;
  if (isMomentDone(moment, start))
    return progress[TOTAL_FILES_STAGE][TOTAL_FILES_FIELD];
  else return null;
};

const getAppName = (key, packages) => {
  let name = key.replace(/~/g, ".");
  if (!packages) return name.split(".").reverse()[0];

  packages.some(pkg => {
    if (pkg.pkgName === name) {
      name = pkg.appName;
      return true;
    }
    return false;
  });
  return name;
};

export const getLastInstalledApps = (progress, packages) => {
  const start = getStartMoment(progress);
  const appList = [];
  Object.keys(progress[INSTALL_APPS_KEY]).forEach(pkg => {
    const moment = progress[INSTALL_APPS_KEY][pkg].moment;
    if (
      isMomentDone(moment, start) &&
      progress[INSTALL_APPS_KEY][pkg].state === "installed"
    ) {
      appList.push(getAppName(pkg, packages));
    }
  });
  return appList;
};

export const getLastInstallStage = progress =>
  getLastStage(progress, INSTALL_STAGES);

export const getLastAppStage = progress => getLastStage(progress, APP_STAGES);

export const getLastContentStage = progress =>
  getLastStage(progress, CONTENT_STAGES);

export const isInstallStage = stage => INSTALL_STAGES.includes(stage);

export const isAppStage = stage => APP_STAGES.includes(stage);

export const isContentStage = stage => CONTENT_STAGES.includes(stage);
