import React from "react";
import styled from "styled-components/macro";
import ProjectBaseUI from "./ProjectBaseUI";
import ProjectPeopleRow from "./ProjectPeopleRow";
import ShareButton from "./ShareButton";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { StyledCard, AnimatingDiv } from "../UIUtils";
import ProjectButtonSection from "./ProjectButtonSection";
import ProjectDropDownButton from "./ProjectDropDownButton";
import {
  getPermissionsForKey,
  subscribeToProjectChanges,
  subscribeToUserProjectRemoval
} from "../FirebaseFunctions";

export const ProjectCard = styled(StyledCard)``;

export const ProjectCardSizer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 80%;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  margin-bottom: 20%;
  border-radius: 10px 10px 0px 0px;
`;

export const ProjectInfoBar = styled.div`
  display: block;
  position: absolute;
  width: 95%;
  background-color: white;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  bottom: 0px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  border-radius: 0px 0px 10px 10px;
`;

export const ProjectTopInfoBar = styled(ProjectInfoBar)`
  top: 0px;
`;

export const ProjectBottomInfoBar = styled(ProjectInfoBar)`
  text-align: left;
  bottom: 0px;
`;


export const peopleSectionStyle = {
  position: "relative",
  display: "inline-block",
  verticalAlign: "middle",
  width: "calc(100% - 80px)"
};

class ProjectCell extends ProjectBaseUI {
  render() {
    const projectBuilt =
      this.state.project &&
      !this.state.project.deleted &&
      !this.state.removed &&
      this.state.project.creatorId;

      if (projectBuilt) {
      const onlyHasExperience =
          this.state.project.permissions &&
          this.state.project.permissions.content.read &&
          !this.state.project.permissions.users.read &&
          !this.state.project.permissions.devices.read &&
          !this.state.project.permissions.analytics.read;

      return (
        <ProjectCard>
          {onlyHasExperience && !this.state.project.hasMedia && (
            <Link
              to={"/project/" + this.state.project.projectId + "/experience"}
            >
              <ProjectCardSizer
                bgImage={this.state.project.imageURL}
              ></ProjectCardSizer>
            </Link>
          )}
          {(!onlyHasExperience || this.state.project.hasMedia) && (
            <Link
              to={
                onlyHasExperience
                  ? "/project/" + this.state.project.projectId + "/experience"
                  : `/project/${this.state.project.projectId}/users`
              }
            >
              <ProjectCardSizer
                bgImage={this.state.project.imageURL}
              ></ProjectCardSizer>
            </Link>
          )}

          <ProjectBottomInfoBar>
            <span className="project">{`${this.state.project.name}`}</span>
            <br />
            <br />
            <div style={peopleSectionStyle}>
              {this.state.project.permissions &&
                this.state.project.permissions.users &&
                this.state.project.permissions.users.read && (
                  <ProjectPeopleRow
                    project={this.state.project}
                  ></ProjectPeopleRow>
                )}
            </div>
            <ProjectButtonSection
              project={this.state.project}
              usersId={this.props.usersId}
            ></ProjectButtonSection>
          </ProjectBottomInfoBar>
        </ProjectCard>
      );
    }
    else if(this.state.project && this.state.project.deleted) {
       return null;
    } else {
    return (
      <ProjectCard>
        <ProjectCardSizer>
          <AnimatingDiv></AnimatingDiv>
        </ProjectCardSizer>
      </ProjectCard>
    );
    }
  }
}

export default ProjectCell;
