import React from "react";
import styled from "styled-components/macro";
import {StyledCard, AnimatingDiv} from "../UIUtils";
import {
  getNumberOfDevicesInProject,
} from "../FirebaseFunctions";

const DeviceCard = styled(StyledCard)`
  overflow:hidden;
  min-height:80px;
`;

const WidgetSpan = styled.span`
  margin: 32px;
  display: block;
`;

class TotalDevicesWidget extends React.Component {
  unsubscribeList = [];
  mounted = false;
  state = {
    totalDevices : -1,
  };

  grabDeviceNumber = async (remote) => {
    const projectId = this.props.project.projectId;
    const num = await getNumberOfDevicesInProject(projectId, remote);
    return num;
  }

  init = async() => {
    let num = await this.grabDeviceNumber(false);
    if(!this.mounted)return;
    if(num > 0 ){
      this.setState({totalDevices : num});
    }
    num = await this.grabDeviceNumber(true);
    if(!this.mounted)return;
    this.setState({totalDevices : num});
  }

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    if (this.props.project) {
      return (
        <DeviceCard>
          {this.state.totalDevices == -1 && (
            <AnimatingDiv></AnimatingDiv>
          )}
          {this.state.totalDevices >= 0 && (
            <WidgetSpan>{"Total Devices : " + this.state.totalDevices}</WidgetSpan>
          )}
        </DeviceCard>
      );
    } else {
      return null;
    }
  }
}

export default TotalDevicesWidget;
