import React from "react";
import styled from "styled-components/macro";
import UserPermissionsDropDown from "./UserPermissionsDropDown";
import { StyledCard, AnimatingDiv } from "../UIUtils";
import PersonCircle from "./PersonCircle";
import {
  subscribeToUser,
  subscribeToUserProjectRemoval,
  getLoggedInUsersId,
  getPermissionsForKey
} from "../FirebaseFunctions";

const UserPermissionSpan = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 73px;
  margin-right: 15px;
`;

const UserNameBlock = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

const UserName = styled.span`
  position: relative;
  display: block;
  vertical-align: middle;
`;

const PermissionBlock = styled.div`
  position: relative;
  float: right;
  display: inline-block;
  vertical-align: middle;
`;

const SpacerBlock = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 0px;
  opacity: 0.5;
  font-size: 12px;
`;

const UserCard = styled(StyledCard)`
  text-align: left;
  min-height: 74px;
  @media (min-width: ${props => (props.maxWidth ? 0 : 10000)}) {
    width: calc(100% - 20px);
  }
`;

class UserRow extends React.Component {
  unsubscribeList = [];
  mounted = false;
  state = {
    user: {},
    viewer: {},
    removed: false
  };
  uploadTime = "";

  getUser = async usersId => {
    const that = this;
    let unsub = await subscribeToUser(usersId, function(user, type) {
      if (!that.mounted) return;
      let useUser = { ...that.state.user, ...user };
      that.setState({ user: useUser });
    });
    this.unsubscribeList.push(unsub);
  };

  subscribeToPermissionsChange = async (usersId, callback) => {
    const that = this;
    /* subscribe to permissions changes and deletion of the project */
    let unsub = await subscribeToUserProjectRemoval(
      this.props.project.projectId,
      usersId,
      function(proj, type) {
        if (!that.mounted) return;
        if (proj.projectId != that.props.project.projectId) return;
        callback(proj, type);
      }
    );
    this.unsubscribeList.push(unsub);
  };

  getPermissionsFromKeyState = async (permissionsKey, stateKey) => {
    let permissions = await getPermissionsForKey(permissionsKey);
    if (!this.mounted) return;
    let useProject = {
      ...this.state[stateKey],
      permissions: { ...this.state[stateKey].permissions, ...permissions }
    };
    this.setState({ [stateKey]: useProject });
  };

  getViewerProjectPermissionsChanges = async () => {
    const that = this;
    const usersId = getLoggedInUsersId();
    this.setState({ viewer: { usersId: usersId } });
    this.subscribeToPermissionsChange(usersId, function(proj, type) {
      if (!that.mounted) return;
      if (proj.permissionsKey) {
        that.getPermissionsFromKeyState(proj.permissionsKey, "viewer");
      }
    });
  };

  getProjectPermissionsChanges = async usersId => {
    const that = this;
    this.subscribeToPermissionsChange(usersId, function(proj, type) {
      if (!that.mounted) return;
      if (type == "removed") {
        that.setState({ removed: true });
      } else {
        if (proj.permissionsKey) {
          that.getPermissionsFromKeyState(proj.permissionsKey, "user");
        }
      }
    });
  };

  getUploadTime = () => {
    this.uploadTime = new Date(this.props.user.timestamp).toDateString();
  };

  componentDidMount() {
    this.mounted = true;
    if (this.props.user.timestamp) {
      this.getUploadTime();
    }
    const user = this.props.user;
    this.setState({ user: user });
    this.getUser(user.usersId);
    this.getProjectPermissionsChanges(user.usersId);
    this.getViewerProjectPermissionsChanges();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    const canViewPermissions =
      this.state.user &&
      this.state.user.permissions &&
      this.state.viewer &&
      this.state.viewer.permissions &&
      (this.state.viewer.permissions.userPermissions.read ||
        this.state.viewer.permissions.userPermissions.write);
    if (this.state.user && !this.state.removed && this.state.user.name) {
      return (
        <UserCard maxWidth={!this.props.asUploader && !this.props.asApproved}>
          <PersonCircle
            user={this.state.user}
            key={this.state.user.usersId}
            width="30px"
            height="30px"
            margin="20px"
            fontSize="12px"
          />
          <UserNameBlock>
            <UserName>
              {this.state.user.usersId == this.state.viewer.usersId
                ? this.state.user.name + " (You)"
                : this.state.user.name}
            </UserName>
            {this.props.asUploader && (
              <SpacerBlock> Uploaded {this.uploadTime} </SpacerBlock>
            )}
            {this.props.asApproved && (
              <SpacerBlock> Approved {this.uploadTime} </SpacerBlock>
            )}
          </UserNameBlock>
          {canViewPermissions &&
            !this.props.asUploader &&
            !this.props.asApproved && (
              <PermissionBlock>
                {this.props.isCreator && (
                  <UserPermissionSpan>Creator</UserPermissionSpan>
                )}
                {!this.props.isCreator &&
                  this.state.viewer.permissions.userPermissions.read && (
                    <UserPermissionSpan>
                      {this.state.user.permissions.name}
                    </UserPermissionSpan>
                  )}
                {!this.props.isCreator &&
                  this.state.viewer.permissions.userPermissions.write && (
                    <UserPermissionsDropDown
                      project={this.props.project}
                      user={this.state.user}
                      viewer={this.state.viewer}
                    ></UserPermissionsDropDown>
                  )}
              </PermissionBlock>
            )}
        </UserCard>
      );
    } else if (!this.state.removed) {
      return (
        <UserCard maxWidth={!this.props.asUploader && !this.props.asApproved}>
          <AnimatingDiv></AnimatingDiv>
        </UserCard>
      );
    } else {
      return <></>;
    }
  }
}

export default UserRow;
