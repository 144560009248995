import React, { Component } from "react";

import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import styled from "styled-components/macro";

/**
 * styled-components
 */

const FormTextField = styled(TextField)`
  width: 300px;
`;

/**
 * props = { name, label, width, error, onChange }
 */
class PasswordField extends Component {
  state = {
    password: "",
    showPassword: false
  };

  onChange = event => {
    // set state
    this.setState({ [event.target.name]: event.target.value });
    // pass state change to parent
    this.props.onChange(event);
  };

  toggleVisibility = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  render() {
    return (
      <FormTextField
        error={this.props.error !== null}
        helperText={this.props.error}
        name={this.props.name}
        label={this.props.label}
        type={this.state.showPassword ? "text" : "password"}
        value={this.state.password}
        onChange={this.onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={this.toggleVisibility}>
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}

export default PasswordField;
