import React from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import styled from 'styled-components/macro';
import {getProjectCodeForId, addProjectForUser, getLoggedInUsersId} from '../FirebaseFunctions'
import {showMessage, showMultiOption, standardButton} from '../UIUtils'
import { Redirect } from "react-router";

const Button = styled(standardButton)`
  background-color: #ff9300;
`;

class JoinButton extends React.Component {

  joinProject = async (project) => {
    const {history} = this.props;
      const rsp = await addProjectForUser(
        project.projectId,
        getLoggedInUsersId(),
        undefined,
        project.shareCode
      );
      showMessage("Project Joined").then(result => {
        history.push(`/project/${project.projectId}/experience`)
      })
  }

  render() {
    const {project} = this.props
      if(project){
        return(
            <Button onClick={(e) => this.joinProject(project)}>Join Project</Button>
        )
      }
      else{
        return null;
      }
  }
}
//


export default JoinButton;
