// import React from 'react';
// import ReactDOM from 'react-dom';
// import './css/index.css';
// import App from './App';
// import Firebase, { FirebaseContext } from './Firebase';
//
// import * as serviceWorker from './serviceWorker';
//
// ReactDOM.render(
//    <FirebaseContext.Provider value={new Firebase()}>
//      <App />, document.getElementById('root')
//    </FirebaseContext.Provider>
// );
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import Firebase, { FirebaseContext } from './Firebase';
import * as serviceWorker from './serviceWorker';

// function noop() {}
//
// console.log = noop;
// console.warn = noop;
// console.error = noop;

ReactDOM.render(<FirebaseContext.Provider value={new Firebase()}><App /></FirebaseContext.Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
