import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import "./css/Dashboard.css";
import ProjectCell from "./components/ProjectCell";
import AddProjectCell from "./components/AddProjectCell";
import listIcon from "./images/list.svg";
import gridIcon from "./images/grid.svg";
import UserHeader from "./components/UserHeader";
import Swal from "sweetalert2";
import { withLogin } from "./loggedInPage.js";
import {
  getProjectIdsForUser,
  subscribeToUseProjectsListChanges
} from "./FirebaseFunctions";
import styled from "styled-components";
import { CenteredOuterDiv, HeaderSpan, showMetaTags } from "./UIUtils";
import ProjectList from "./components/ProjectList.js";

const OuterDiv = styled(CenteredOuterDiv)`
  z-index: -1;
`;

const HideableDiv = styled(CenteredOuterDiv)`
  display: ${props => (props.hide ? "none" : "block")};
`;

const VerifiyEmailAddressSection = styled.div`
  position: fixed;
  background-color: #f73131;
  text-align: center;
  width: 100%;
  bottom: 0px;
  color: white;
  padding: 10px 0px;
  cursor: pointer;
`;

const BottomPadding = styled.div`
  position: relative;
  padding-bottom: 75px;
  display: block;
`;

const ToggleImage = styled.img`
  vertical-align: middle;
  position: absolute;
  top: 0px;
  right: 2.5%;
  margin-top: 5px;
  cursor: pointer;
  width: 25px;
`;

class Dashboard extends React.Component {
  state = {
    projects: [],
    projectIds: [],
    listView: true,
    pushUrl: null
  };
  mounted = false;
  shownList = this.state.listView;
  shownGrid = !this.state.listView;

  unsubscribeList = [];
  usersId = firebase.auth().currentUser.uid;

  grabProjectsFromFB = async remote => {
    const projects = await getProjectIdsForUser(this.usersId, remote);
    if (!this.mounted) return;
    const projectIds = [];
    projects.forEach(function(element) {
      projectIds.push(element.projectId);
    });
    this.setState({ projectIds: projectIds });
    this.setState({ projects: projects });
    return remote;
  };

  handleProjectChange = (type, proj) => {
    const index = this.state.projectIds.indexOf(proj.projectId);
    if (type == "added" && index == -1) {
      this.projectAdded(proj);
    } else if (type == "removed" && index != -1) {
      this.projectRemoved(proj);
    }
  };

  subscribeToProjectChanges = async () => {
    const that = this;
    let unsub = await subscribeToUseProjectsListChanges(this.usersId, function(
      proj,
      type
    ) {
      that.handleProjectChange(type, proj);
    });
    this.unsubscribeList.push(unsub);
  };

  init = async () => {
    const that = this;
    this.setState({ user: this.props.user });
    try {
      const awaiting = await this.grabProjectsFromFB(false);
      if (!that.mounted) return;
    } catch (e) {}
    setTimeout(function() {
      try {
        that.grabProjectsFromFB(true);
      } catch (e) {}
      that.subscribeToProjectChanges();
    }, 1000);
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  projectAdded = proj => {
    let projects = this.state.projects;
    let projectIds = this.state.projectIds;
    projects.unshift(proj);
    projectIds.unshift(proj.projectId);
    this.setState({ projects: projects });
    this.setState({ projectIds: projectIds });
  };

  /* make sure the state is updated for removed project */
  projectRemoved = project => {
    const index = this.state.projectIds.indexOf(project.projectId);
    const projectIds = this.state.projectIds;
    projectIds.splice(index, 1);
    const projects = this.state.projects;
    projects.splice(index, 1);
    this.setState({ projectIds: projectIds });
    this.setState({ projects: projects });
  };

  sendEmailVerification = () => {
    firebase.auth().currentUser.sendEmailVerification();
    Swal.fire("Email Verification sent, please check your inbox");
  };

  signOut = () => {
    firebase.auth().signOut();
  };

  createProject = () => {};

  projectSelected = project => {
    this.setState({ pushUrl: "/project/" + project.projectId });
  };

  toggleList = () => {
    this.setState({ listView: !this.state.listView });
    this.shownList = true;
    this.shownGrid = true;
  };

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    let emptyProject = { projectId: 0 };
    if (this.state.pushUrl) {
      return <Redirect push to={this.state.pushUrl} />;
    }
    return (
      <>
        {showMetaTags("Dashboard", "Your MyPlayer Dashboard")}
        <UserHeader signOut={this.signOut} addProject={this.createProject} />
        <CenteredOuterDiv>
          <HeaderSpan> Your Projects </HeaderSpan>
          <ToggleImage
            onClick={this.toggleList}
            src={this.state.listView ? listIcon : gridIcon}
          />
        </CenteredOuterDiv>
        {(this.state.listView || this.shownList) && (
          <HideableDiv hide={!this.state.listView}>
            <AddProjectCell asRow={true}></AddProjectCell>
            <ProjectList
              project={emptyProject}
              projectSelected={this.projectSelected}
            ></ProjectList>
            <BottomPadding></BottomPadding>
          </HideableDiv>
        )}
        {(!this.state.listView || this.shownGrid) && (
          <HideableDiv hide={this.state.listView}>
            <AddProjectCell></AddProjectCell>
            {this.state.projects.map(project => (
              <ProjectCell
                project={project}
                usersId={this.props.user.uid}
                key={project.projectId}
                projectSelected={this.projectSelected}
              />
            ))}
            <BottomPadding></BottomPadding>
          </HideableDiv>
        )}
        {!this.props.user.emailVerified && (
          <VerifiyEmailAddressSection onClick={this.sendEmailVerification}>
            Your email address has not been verified, some features may be
            unavailable <br /> <b>click here</b> to resend the verification
            email
          </VerifiyEmailAddressSection>
        )}
      </>
    );
  }
}

export default withLogin(Dashboard);
