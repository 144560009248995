import React, { Component } from "react";
import Swal from "sweetalert2";

import { withFirebase } from "../Firebase";
import LogoHeader from "../components/LogoHeader";
import { HomeStrings } from "../strings";

import { SignInLinkSpan } from "./styled";
import SignInForm from "./SignInForm";

class SignInContent extends Component {
  onForgotPassword = async () => {
    // show an alert popup
    const email = await Swal.fire({
      title: HomeStrings.FORGOT_PASSWORD_TITLE,
      input: "email",
      inputPlaceholder: HomeStrings.FORGOT_PASSWORD_PLACEHOLDER
    });
    if (email.value) {
      this.props.firebase.resetPassword(email.value);
      Swal.fire(
        HomeStrings.FORGOT_PASSWORD_DONE_TITLE,
        HomeStrings.FORGOT_PASSWORD_DONE_TEXT.replace("{email}", email.value)
      );
    }
  };

  onSignIn = (email, password) => this.props.firebase.signIn(email, password);

  render() {
    return (
      <div>
        <LogoHeader title={HomeStrings.TITLE_SIGNIN} />
        <SignInForm onSignIn={this.onSignIn} setProcessing={this.props.setProcessing} />
        <SignInLinkSpan onClick={this.props.toggleSignIn}>
          {HomeStrings.LINK_REGISTER}
        </SignInLinkSpan>
        <SignInLinkSpan onClick={this.onForgotPassword}>
          {HomeStrings.FORGOT_PASSWORD}
        </SignInLinkSpan>
      </div>
    );
  }
}

export default withFirebase(SignInContent);
