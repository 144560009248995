import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import "./css/App.css";
import Auth from "./Auth";
import { customHistory } from "./History";
import { provideAuth, AuthPermission } from "./authentication";
import { AuthRoute, ROUTES } from "./route";

import Dashboard from "./dashboard";
import Recovery from "./recover";
import ProjectPage from "./projectPage";
import CreateProjectPage from "./CreateProjectPage";
import UploadProjectPage from "./UploadProjectPage";
import ProjectExperiencePage from "./projectExperiencePage";
import DownloadAppPage from "./downloadAppPage";
import JoinProjectPage from "./JoinProjectPage";
import DevicePage from "./device";
import HomePage from "./home";

const App = () => (
  <Router history={customHistory}>
    {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. */}
    <Switch>
      <Route path={ROUTES.DOWNLOAD} component={DownloadAppPage}></Route>
      <Route path={ROUTES.AUTH} component={Auth}></Route>
      <Route path={ROUTES.CREATE} component={CreateProjectPage}></Route>
      <Route path={ROUTES.PROJECT_UPLOAD} component={UploadProjectPage}></Route>
      <Route path={ROUTES.PROJECT_EDIT} component={CreateProjectPage}></Route>
      <Route path={ROUTES.RECOVER}>
        <Recovery />
      </Route>
      <Route
        path={ROUTES.PROJECT_EXPERIENCE}
        component={ProjectExperiencePage}
      ></Route>
      <Route
        exact
        path={ROUTES.PROJECT_DEVICES}
        render={props => <ProjectPage startingPage={"devices"} {...props} />}
      />
      <Route exact path={ROUTES.PROJECT_DEVICE} component={DevicePage} />
      <Route
        exact
        path={ROUTES.PROJECT_USERS}
        render={props => <ProjectPage startingPage={"users"} {...props} />}
      />
      <Route
        exact
        path={ROUTES.PROJECT_ANALYTICS}
        render={props => <ProjectPage startingPage={"analytics"} {...props} />}
      />
      <Route path={ROUTES.PROJECT_MAIN} component={ProjectPage}></Route>
      <AuthRoute
        path={ROUTES.PROJECT_JOIN}
        component={JoinProjectPage}
        authCheck={AuthPermission.userExist}
        authFailPath={ROUTES.HOME}
      ></AuthRoute>
      <Route path={ROUTES.DASHBOARD}>
        <Dashboard />
      </Route>
      <AuthRoute
        path={ROUTES.HOME}
        component={HomePage}
        authCheck={AuthPermission.userNotExist}
        authFailPath={ROUTES.DASHBOARD}
      />
      <Redirect to={ROUTES.HOME} />
    </Switch>
  </Router>
);

export default provideAuth(App);
