import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import styled from "styled-components/macro";

import GlobalStrings from "../strings";

const TableGrid = styled(Grid)`
  padding: 2% 3% 2% 3%;
`;

// display div when locked
// display TextField when unlocked
const TableRow = props => (
  <>
    {props.locked && (
      <TableGrid container item>
        <Grid item xs={4}>
          <div>{props.label}</div>
        </Grid>
        <Grid item xs={8}>
          <div>{props.value}</div>
        </Grid>
      </TableGrid>
    )}
    {!props.locked && (
      <TableGrid item xs={12}>
        <TextField
          fullWidth
          name={props.name}
          label={props.label}
          value={props.value}
          onChange={props.onChange}
        />
      </TableGrid>
    )}
  </>
);

/**
 * props: { data: [{ key, label, value }], onSave({ keys: values })}
 */
class ProtectedEditForm extends React.Component {
  state = {
    locked: true,
    ready: false
  };

  dataKeys = [];

  componentDidMount = () => {
    this.resetData();
    this.setState({ ready: true });
  };

  // need arrow function to access "this"
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onEditStart = () => {
    this.setState({ locked: false });
  };

  onEditFinish = save => {
    if (save) {
      const data = {};
      // convert data from state
      this.dataKeys.forEach(key => (data[key] = this.state[key]));

      // call props.onSave
      this.props.onSave(data).catch(error => {
        // reset data if onSave failed
        this.resetData();
      });
    } else {
      // reset data if cancel
      this.resetData();
    }

    this.setState({ locked: true });
  };

  isFormInvalid = () => this.dataKeys.some(key => this.state[key].length === 0);

  resetData = () => {
    // convert data to state
    this.props.data.forEach(item => {
      // remember data keys
      this.dataKeys.push(item.key);

      // add new state
      this.setState({ [item.key]: item.value });
    });
  };

  render = () => {
    if (!this.state.ready) return <></>;
    return (
      <TableGrid container justify="center">
        {this.props.data.map((item, index) => (
          <TableRow
            key={`ProtectedEditForm-row-${index}`}
            locked={this.state.locked}
            name={item.key}
            label={item.label}
            value={this.state[item.key]}
            onChange={this.onChange}
          />
        ))}
        <Grid container justify="space-between" item xs={12}>
          <Grid item>
            {!this.state.locked && (
              <Button
                variant="contained"
                color="primary"
                disabled={this.isFormInvalid()}
                onClick={() => this.onEditFinish(true)}
              >
                {GlobalStrings.BUTTON_SAVE}
              </Button>
            )}
          </Grid>
          <Grid item>
            {this.state.locked && (
              <EditOutlined
                onClick={this.onEditStart}
                fontSize="large"
                style={{ cursor: "pointer" }}
              />
            )}
            {!this.state.locked && (
              <Button
                variant="contained"
                color="default"
                onClick={() => this.onEditFinish(false)}
              >
                {GlobalStrings.BUTTON_CANCEL}
              </Button>
            )}
          </Grid>
        </Grid>
      </TableGrid>
    );
  };
}

export default ProtectedEditForm;
