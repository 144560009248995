import React from 'react';
import firebase from 'firebase';
import styled from 'styled-components/macro';
import shareImage from '../images/ShareIcon.svg'
import {getProjectCodeForId, removeProjectForUser, getLoggedInUsersId, updateProject} from '../FirebaseFunctions'
import {showMessage, showConfirmation} from '../UIUtils'
import DropDown from "./DropDown.js";
import moreIcon from "../images/moreIcon.svg"
// import {customHistory} from '../History'
import { Redirect } from "react-router";

const MoreIcon = styled.img`
  width: 20px;
  &:hover {
    opacity: 0.5;
  }
`;

class ProjectDropDownButton extends React.Component {
  state = {
      isShown: false,
      removed: false,
      navAway: null,
  };

  deleteProject = async () => {
    const rsp = await showConfirmation("Delete Project?", "Are you sure you want to delete this Project? This cannot be undone")
    if (rsp.value) {
      let projectDetails = {
        projectId: this.props.project.projectId,
        deleted: true
      }
      const response = updateProject(projectDetails);
      this.setState({navAway : '/dashboard'});
      console.log("delete project for user : ", this.props.project.projectId, this.props.usersId);
    }
  }

  editProject = () => {
    const url = '/project/' + this.props.project.projectId + '/edit';
    // customHistory.push(url);
    this.setState({navAway : url});
  }

  removeProject = async () => {
    const rsp = await showConfirmation("Leave Project?", "Are you sure you want to leave this Project? To re-join you will have to re-enter a share code")
    if (rsp.value) {
      const rsp = removeProjectForUser(this.props.project.projectId, this.props.usersId)
    }
  }

  uploadProject = () => {
    const url = '/project/' + this.props.project.projectId + '/experience';
    this.setState({navAway : url});
  }

  calcDropItems = () => {
    let project = this.props.project;
    let permissions = this.props.project.permissions;
    let dropItems = [];
    if(permissions == undefined)return dropItems;
    const leaveItem = {name : "Leave", style : "Negative", action : this.removeProject}
    if(!permissions){
      dropItems.push(leaveItem)
      return dropItems;
    }
    if(permissions.project.write){
      dropItems.push({name : "Edit", style : "Positive", action : this.editProject})
    }
    if( (permissions.content.read && project.hasMedia) || permissions.content.write){
      dropItems.push({name : "Content", style : "Positive", action : this.uploadProject})
    }
    if(getLoggedInUsersId() != this.props.project.creator){
      dropItems.push(leaveItem)
    }
    if(permissions.project.delete){
      dropItems.push({name : "Delete", style : "Negative", action : this.deleteProject})
    }
    return dropItems;
  }

  componentDidMount() {
    this.calcDropItems();
  }

  toggleShown = () =>{
    let isShown = this.state.isShown;
    this.setState({isShown:!isShown})
  }

  render() {
    const dropItems = this.calcDropItems();
    if(this.state.navAway){
      return <Redirect to={this.state.navAway} push/>;
    }
    if(dropItems.length && !this.state.removed){
      return(
        <>
          <MoreIcon src={moreIcon} onClick={this.toggleShown}/>
          {this.state.isShown && (
              <DropDown top="20px" right="-5px" dropItems={dropItems} toggleShown={this.toggleShown}></DropDown>
          )}
        </>
      )
    }
    else{
      return null
    }
  }
}

export default ProjectDropDownButton;
