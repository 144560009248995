import React from "react";
import styled from "styled-components/macro";

import {
  getProject,
  getPermissionsForKey,
  subscribeToProjectChanges,
  subscribeToUserProjectRemoval,
} from "../FirebaseFunctions";

class ProjectBaseUI extends React.Component {
  unsubscribeList = [];
  mounted = false;
  state = {
    project: null,
    projectPin: null,
    removed: false
  };

  getProject = async () => {
    let rsp = await getProject(this.props.project.projectId, false);
    if(!this.mounted)return;
    let useProject = { ...this.state.project, ...rsp };
    this.setState({ project: useProject });

    rsp = await getProject(this.props.project.projectId, true);
    if(!this.mounted)return;
    useProject = { ...this.state.project, ...rsp };
    this.setState({ project: useProject });

    return rsp;
  }

  getProjectChanges = async () => {
    const that = this;
    /* subscribe to project info changes */
    let unsub = await subscribeToProjectChanges(
      this.props.project.projectId,
      function(rsp) {
        if(!that.mounted)return;
        let useProject = { ...that.state.project, ...rsp };
        that.setState({ project: useProject });
      }
    );
    this.unsubscribeList.push(unsub);
  };

  defaultPermissionsDict = {};

  getPermissionsFromDefaults = async permissionsKey => {
    if(this.defaultPermissionsDict[permissionsKey]){
      return this.defaultPermissionsDict[permissionsKey];
    }
    const that = this;
    let permissions = await getPermissionsForKey(permissionsKey);
    if(!this.mounted)return;
    if(!that.state.project)return;
    let useProject = {
      ...that.state.project,
      permissions: { ...that.state.project.permissions, ...permissions }
    };
    this.defaultPermissionsDict[permissionsKey] = permissions;
    that.setState({ project: useProject });
  };

  getProjectPermissionsChanges = async () => {
    const that = this;
    /* subscribe to permissions changes and deletion of the project */
    let unsub = await subscribeToUserProjectRemoval(
      this.props.project.projectId,
      that.props.usersId,
      function(proj, type) {
        if(!that.mounted)return;
        if (proj.projectId != that.props.project.projectId) return;
        if (type == "removed") {
          that.setState({ removed: true });
        } else {
          let useProject = { ...that.state.project, ...proj };
          that.setState({ project: useProject });
          if (useProject.permissionsKey) {
            that.getPermissionsFromDefaults(useProject.permissionsKey);
          }
        }
      }
    );
    this.unsubscribeList.push(unsub);
  };

  grabProject = async () => {
    const that = this;
    let proj = await that.getProject();
    if(proj && proj.deleted)return;
    that.getProjectPermissionsChanges();
    setTimeout(() => {
      that.getProjectChanges();
    }, 500);
  };

  componentDidMount() {
    this.mounted = true;
    this.grabProject();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      console.log("unsubscibe : ", unsub)
      unsub();
    });
  }
}

export default ProjectBaseUI;
