import ComplianceStrings from "./compliance";
import DeviceStrings from "./device";
import HomeStrings from "./home";

const STRINGS = {
  BUTTON_CANCEL: "Cancel",
  BUTTON_SAVE: "Save"
};

export { ComplianceStrings, DeviceStrings, HomeStrings };
export default STRINGS;
