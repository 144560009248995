import React from "react";
import { Redirect } from "react-router";
import { withLogin } from "./loggedInPage.js";
import ProjectHeader from "./components/ProjectHeader";
import ProjectAppsHolder from "./components/ProjectAppsHolder";
import ToggleContentSizes from "./components/ToggleContentSizes";
import firebase from "firebase";
import styled from "styled-components/macro";
import {
  cancelUpload,
  uploadFile,
  createDeviceProject,
  uploadFolder,
  getProject,
  getLoggedInUsersId,
  updateProject,
  returnUploadContentUrlForProject,
  makeProjectFolderPublic,
  projectMediaHasChanged,
  deleteFolderContents,
  getNumberOfFilesInProject
} from "./FirebaseFunctions";
import {
  CenteredOuterDiv,
  HeaderSpan,
  StyledCard,
  DescSpan,
  showMessage,
  showConfirmation,
  showInputMessage,
  showPercentageIndicator,
  showMetaTags,
  showCheckBoxConfirm
} from "./UIUtils";

const OuterDiv = styled(CenteredOuterDiv)`
  margin-top: 75px;
`;

const HiddenInput = styled.input`
  opacity: 0;
  display: none;
  position: absolute;
`;

const BoldSpan = styled(DescSpan)`
  font-weight: bold;
  font-size: 16px;
`;

const UploadButton = styled.div`
  font-size: 18px;
  color: white;
  background-color: black;
  padding: 10px 30px;
  border-radius: 10px;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
`;

const hiddenStyle = {
  display: "none"
};

class UploadProjectPage extends React.Component {
  state = {
    redirectURL: null,
    pushURL: null,
    project: null,
    overwrite: false,
    headerSpan: "Upload Content",
    uploadButton: "Upload Content",
    fileCount: -1
  };

  uploadManager = {
    currentUploadTask: null
  };

  contentToUpload = null;

  handleDelete = async () => {
    let path = returnUploadContentUrlForProject(this.state.project);
    const rsp = await deleteFolderContents(path);
    return rsp;
  };

  handleUpload = async () => {
    const that = this;
    let fileLength = this.contentToUpload.length;
    let currentItemNum = 0;
    let currentItem = null;
    let percentId = "percentageIndicator";
    const projectId = this.state.project.projectId;
    showPercentageIndicator("Uploading Media", percentId).then(function() {
      cancelUpload(that.uploadManager);
    });
    var percentSpan = document.getElementById(percentId);
    // const proj = await createDeviceProject(this.state.project);
    // if (proj == 200) {
    let uploadPath = returnUploadContentUrlForProject(this.state.project);
    const rsp = await uploadFolder(
      this.uploadManager,
      uploadPath,
      this.contentToUpload,
      function(progress) {
        percentSpan.textContent =
          currentItemNum +
          "/" +
          fileLength +
          " " +
          currentItem +
          ": " +
          progress.toFixed(0) +
          "%";
      },
      function(item) {
        currentItemNum++;
        currentItem = item.name;
      }
    );

    if (rsp == 200) {
      const updated = await updateProject({
        projectId: projectId,
        hasMedia: true,
        uploader: {
          usersId: getLoggedInUsersId(),
          timestamp: Date.now()
        },
        approval: []
      });
      let path = "/project/" + projectId + "/experience";
      const projectMediaHasChangedRsp = await projectMediaHasChanged(projectId);
      console.log("projectMediaHasChangedRsp : ", projectMediaHasChangedRsp);
      const makeProjectFolderPublicRsp = await makeProjectFolderPublic(
        projectId
      );
      console.log("makeProjectFolderPublicRsp : ", makeProjectFolderPublicRsp);
      const rsp = await showMessage("Project Uploaded");
      const files = await getNumberOfFilesInProject(uploadPath);
      console.log("files : ", files);
      this.setState({ fileCount: files });
      // this.setState({ redirectURL: path });
    }
    // }
  };

  checkForUpload = async () => {
    if (this.state.project.hasMedia) {
      let checked = false;
      const rsp = await showCheckBoxConfirm(
        "Upload New Content?",
        "Making Changes to your project will make all your devices become non compliant",
        "Delete Existing Files",
        isChecked => {
          checked = isChecked;
        }
      );
      if (rsp.value) {
        if (checked) {
          await this.handleDelete();
        }
        this.handleUpload();
      }
    } else {
      this.handleUpload();
    }
  };

  handleInputChange = event => {
    console.log("event : ", event);
    this.contentToUpload = event.target.files;
    this.checkForUpload();
  };

  handleUploadClick = () => {
    this.inputElement.click();
  };

  goToExperience = () => {
    this.setState({
      pushURL: `/project/${this.props.match.params.projectId}/experience`
    });
  };

  handleMakeProjectPublic = async () => {
    let projectId = this.props.match.params.projectId;
    console.log("handleMakeProjectPublic : ", projectId);
    const makeProjectFolderPublicRsp = await makeProjectFolderPublic(projectId);
    console.log("makeProjectFolderPublicRsp : ", makeProjectFolderPublicRsp);
  };

  grabProject = async () => {
    let projectId = this.props.match.params.projectId;
    const project = await getProject(projectId);
    if (project.hasMedia) {
      this.setState({
        overwrite: true,
        headerSpan: "Overwrite Exisiting Content",
        uploadButton: "Upload New Content"
      });
    } else {
      const proj = await createDeviceProject(project);
    }
    this.setState({ project: project });
    let path = returnUploadContentUrlForProject(this.state.project);
    const files = await getNumberOfFilesInProject(path);
    this.setState({ fileCount: files });
  };

  componentDidMount() {
    this.grabProject();
  }

  render() {
    if (!this.state.project) {
      return null;
    }
    if (this.state.pushURL) {
      return <Redirect to={this.state.pushURL} />;
    }
    if (this.state.redirectURL) {
      return <Redirect to={this.state.redirectURL} />;
    }
    return (
      <>
        {showMetaTags(
          "Upload Content",
          "Edit/Upload Content to Your MyPlayer Project"
        )}
        <OuterDiv>
          <div onClick={this.goToExperience}>
            <ProjectHeader
              project={this.state.project}
              usersId={this.props.user.uid}
              hideButtons={true}
            ></ProjectHeader>
          </div>
          <HeaderSpan> {this.state.headerSpan}</HeaderSpan>
          <DescSpan>
            {" "}
            Upload the content for your devices to play. Add the outermost
            folder of your project. Inside this folder there must be a file
            called index.html.
          </DescSpan>
          {this.state.fileCount == 1 && (
            <DescSpan>
              {this.state.fileCount + " file currently in project content"}
            </DescSpan>
          )}
          {this.state.fileCount > 1 && (
            <DescSpan>
              {this.state.fileCount + " files currently in project content"}
            </DescSpan>
          )}
          {this.state.overwrite && (
            <BoldSpan>
              ** Uploading new content will cause all of your devices to become
              non-compliant **
            </BoldSpan>
          )}
          <input
            ref={input => (this.inputElement = input)}
            mozdirectory=""
            directory=""
            webkitdirectory=""
            type="file"
            style={hiddenStyle}
            onChange={this.handleInputChange.bind(this)}
          />
          <UploadButton onClick={this.handleUploadClick}>
            {this.state.uploadButton}
          </UploadButton>
        </OuterDiv>
        <OuterDiv>
          <HeaderSpan> Project Apps </HeaderSpan>
          <DescSpan> Choose the apps you're content needs from below</DescSpan>
          {this.state.overwrite && (
            <BoldSpan>
              ** Changing the apps for your project will cause all of your
              devices to become non-compliant **
            </BoldSpan>
          )}
          <ProjectAppsHolder project={this.state.project}></ProjectAppsHolder>
          <HeaderSpan> Project Experience Size </HeaderSpan>
          <DescSpan>
            {" "}
            Choose the resolution for the Project's Experience content
          </DescSpan>
          <ToggleContentSizes project={this.state.project} />
        </OuterDiv>
      </>
    );
  }
}

export default withLogin(UploadProjectPage);
