import React from "react";
import { Box, Grid } from "@material-ui/core";

import DeployPanel from "./DeviceContentDeployPanel";
import CompliancePanel from "./DeviceContentCompliancePanel";

/**
 * props: deviceId, projectId
 */
const Content = props => {
  const { deviceId, projectId } = props;
  return (
    <Box mt={3} mb={3}>
      <Grid container justify="flex-start" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <CompliancePanel deviceId={deviceId} projectId={projectId} />
        </Grid>
        <Grid item xs={12}>
          <DeployPanel deviceId={deviceId} projectId={projectId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Content;
