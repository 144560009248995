import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import "./css/Dashboard.css";
import styled from "styled-components";
import {withLogin} from "./loggedInPage.js"
import { withRouter } from "react-router";
import ProjectPreviewCell from "./components/ProjectPreviewCell";
import { getProjectIdFromCode, getPojectPreview, checkProjectIdExistsForUser, getLoggedInUsersId } from "./FirebaseFunctions"
import { useHistory } from 'react-router-dom';
import LoadingSpinner from "./components/LoadingSpinner"
import {
  showMessage,
  EmptyTablePlaceholder,
  CenteredOuterDiv,
  DescSpan,
  HeaderSpan,
  standardButton,
  showMetaTags
} from "./UIUtils";

import JoinButton from "./components/JoinButton"

export const CenteredProjectPreview = styled(CenteredOuterDiv)`
  top:50px;
  text-align: center;
`;

export const ButtonPlaceholder = styled(CenteredOuterDiv)`
  text-align: center;
`

const DeclineButton = styled(standardButton)`
  background-color: black;
  color:white;
`

class JoinProjectPage extends React.Component {
  state = {
    project: null,
    loading : true
  };

  componentDidMount() {
    var self = this;
    this.getProjectByCode(this.props.match.params.code)
    this.props.setKeepNextUrl(true);
  }

  //get the project based on the share code otherwise return project not found
  getProjectByCode = async (code) => {
    if(code){
      const project = await getProjectIdFromCode(code);
      this.setState({loading : false})
      if(project && project.projectId){
        let proj = await getPojectPreview(project.projectId, code)
        const existingProjects = await checkProjectIdExistsForUser(
          project.projectId,
          getLoggedInUsersId()
        );
        if(existingProjects){
          this.props.history.push(`/project/${project.projectId}`)
        }else{
          this.setState({projectPreview:proj});
          this.setState({project : project})
        }
      }else{
        showMessage("Project Not Found").then(result => {
          this.props.history.push('/dashboard')
        })

        return ;
      }
    }else{
      showMessage("Share code not found")
      return;
    }
  }
  render() {
    let {project, projectPreview, loading} = this.state
    const {history} = this .props
    if(project && !loading){
      return (
          <CenteredProjectPreview>
          {showMetaTags("Join Project", 'Join a project on MyPlayer')}
            <DescSpan>You have been invited to join a project on MyPlayer</DescSpan>
            <ProjectPreviewCell project = {projectPreview}/>
            <ButtonPlaceholder>
            <JoinButton project={project} history = {history}/>
            </ButtonPlaceholder>
            <ButtonPlaceholder>
            <DeclineButton onClick={() => history.push('/')}>Cancel</DeclineButton>
            </ButtonPlaceholder>
          </CenteredProjectPreview>
      )
    }else {
      return <LoadingSpinner/>
    }
  }
}

export default withLogin(JoinProjectPage, true);
