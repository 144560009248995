import React from "react";
import { Button, Chip, Grid } from "@material-ui/core";
import {
  AccessTimeSharp,
  CheckCircleOutlineSharp,
  ErrorOutlineSharp,
  HighlightOffSharp
} from "@material-ui/icons";

import {
  checkComplianceForDevice,
  subscribeToDeviceCompliance,
  subscribeToDeviceComplianceCheck,
  subscribeToDeviceStatus
} from "../FirebaseFunctions";
import { showConfirmation, showToast, showErrorToast } from "../UIUtils";
import { DeviceStrings } from "../strings";
import { COMPLIANCE_STATE, getComplianceStatus } from "../utils/complianceUtil";
import { isDeploying } from "../utils/projectProgressUtil";

import { BorderedBox } from "./styled";

/**
 * props: status (string from FirebaseFunctions.subscribeToDeviceCompliance())
 */
const ComplianceChip = props => {
  const { deploying, status } = props;
  if (status === null) return <Chip label={COMPLIANCE_STATE.STATE_CHECKING} />;

  if (deploying)
    return (
      <Chip
        label={COMPLIANCE_STATE.STATE_DEPLOYING}
        color="default"
        icon={<AccessTimeSharp />}
      />
    );

  if (status === COMPLIANCE_STATE.STATE_COMPLIANT) {
    return (
      <Chip label={status} color="primary" icon={<CheckCircleOutlineSharp />} />
    );
  } else if (status === COMPLIANCE_STATE.STATE_CHECKING) {
    return <Chip label={status} color="default" icon={<AccessTimeSharp />} />;
  } else if (status === COMPLIANCE_STATE.STATE_DEPLOYING) {
    return <Chip label={status} color="default" icon={<AccessTimeSharp />} />;
  } else if (status === COMPLIANCE_STATE.STATE_ERROR_DEPLOYING) {
    return (
      <Chip label={status} color="secondary" icon={<ErrorOutlineSharp />} />
    );
  } else {
    return (
      <Chip label={status} color="secondary" icon={<HighlightOffSharp />} />
    );
  }
};

class DeviceContentCompliancePanel extends React.Component {
  state = {
    deploying: null,
    complianceChecking: null,
    complianceState: null,
    complianceLastCheck: null
  };

  unsubList = [];

  checkCompliance = async () => {
    const { deviceId, projectId } = this.props;
    const confirm = await showConfirmation(
      DeviceStrings.DEVICE_CONTENT_CHECK_COMPLIANCE_TITLE,
      DeviceStrings.DEVICE_CONTENT_CHECK_COMPLIANCE_TEXT
    );
    if (confirm.value) {
      const result = await checkComplianceForDevice(projectId, deviceId);
      console.debug("checkComplianceForDevice", result);
      if (result.success) {
        showToast(DeviceStrings.DEVICE_REQUEST_RECEIVED, 2000);
      } else {
        const msg =
          result.errors.length > 0
            ? result.errors[0]
            : DeviceStrings.DEVICE_ERROR_REQUEST_FAILED;
        showErrorToast(msg);
      }
    }
  };

  shouldDisableButton = status => {
    const { deploying } = this.state;
    return (
      deploying ||
      status === COMPLIANCE_STATE.STATE_CHECKING ||
      status === COMPLIANCE_STATE.STATE_DEPLOYING
    );
  };

  onComplianceUpdate = apiInfo => {
    const complianceState = apiInfo.state;
    const complianceLastCheck = apiInfo.moment;
    console.debug(
      `onComplianceUpdate: state=${complianceState}, lastCheck=${complianceLastCheck}`
    );
    this.setState({ complianceState, complianceLastCheck });
  };

  onComplianceCheckUpdate = apiInfo => {
    const complianceChecking = apiInfo.state;
    console.debug(`onComplianceCheckUpdate: checking=${complianceChecking}`);
    this.setState({ complianceChecking });
  };

  onStatusUpdate = status => {
    const progress = status.project_progress;

    const deploying = isDeploying(progress);
    this.setState({ deploying });
  };

  componentDidMount = async () => {
    const { deviceId, projectId } = this.props;

    this.unsubList.push(
      await subscribeToDeviceStatus(deviceId, this.onStatusUpdate)
    );

    this.unsubList.push(
      await subscribeToDeviceCompliance(
        deviceId,
        projectId,
        this.onComplianceUpdate
      )
    );

    this.unsubList.push(
      await subscribeToDeviceComplianceCheck(
        deviceId,
        projectId,
        this.onComplianceCheckUpdate
      )
    );
  };

  componentWillUnmount = () => {
    this.unsubList.forEach(unsub => unsub());
  };

  render = () => {
    const { deviceId, projectId } = this.props;
    if (!deviceId || !projectId) return <></>;

    const {
      complianceChecking,
      complianceState,
      complianceLastCheck,
      deploying
    } = this.state;
    const status = getComplianceStatus(complianceChecking, complianceState);
    return (
      <BorderedBox p={2}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={8} container spacing={1}>
            <Grid item xs={12}>
              {DeviceStrings.DEVICE_CONTENT_COMPLIANCE_STATUS}
              {": "}
              <ComplianceChip deploying={deploying} status={status} />
            </Grid>
            <Grid item xs={12}>
              {DeviceStrings.DEVICE_CONTENT_COMPLIANCE_LAST_CHECKED}
              {!!complianceLastCheck &&
                `: ${new Date(complianceLastCheck).toUTCString()}`}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.checkCompliance}
              disabled={this.shouldDisableButton()}
            >
              {DeviceStrings.DEVICE_BUTTON_CHECK_COMPLIANCE}
            </Button>
          </Grid>
        </Grid>
      </BorderedBox>
    );
  };
}

export default DeviceContentCompliancePanel;
