import { Box } from "@material-ui/core";
import styled from "styled-components";

import { primaryColor } from "../theme";

export const BorderedBox = styled(Box)`
  border: 1px solid ${primaryColor};
  border-radius: 20px;
`;

