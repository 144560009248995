import React from "react";
import styled from "styled-components/macro";
import ShareButton from "./ShareButton";
import ProjectDropDownButton from "./ProjectDropDownButton";

const ShareButtonSection = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
`;

const ShareButtonsSection = styled(ShareButtonSection)`
  float: right;
  cursor:pointer;
`;

class ProjectButtonSection extends React.Component {
  render() {
    if (
      this.props.project &&
      this.props.usersId
    ) {
      return(
          <ShareButtonsSection>
            <ShareButtonSection>
              <ShareButton project={this.props.project}></ShareButton>
            </ShareButtonSection>
            <ShareButtonSection>
              <ProjectDropDownButton
                project={this.props.project}
                usersId={this.props.usersId}
              ></ProjectDropDownButton>
            </ShareButtonSection>
          </ShareButtonsSection>
      )
    }
    else{
      return null
    }
  }
}

export default ProjectButtonSection;
