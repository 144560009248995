const ROUTES = {
  HOME: "/",
  AUTH: "/auth",
  CREATE: "/create",
  DASHBOARD: "/dashboard",
  DOWNLOAD: "/download",
  PROJECT_MAIN: "/project/:projectId",
  PROJECT_UPLOAD: "/project/:projectId/upload",
  PROJECT_EDIT: "/project/:projectId/edit",
  PROJECT_EXPERIENCE: "/project/:projectId/experience",
  PROJECT_DEVICE: "/project/:projectId/device/:deviceId",
  PROJECT_DEVICES: "/project/:projectId/devices",
  PROJECT_USERS: "/project/:projectId/users",
  PROJECT_ANALYTICS: "/project/:projectId/analytics",
  PROJECT_JOIN: "/join/project/:code",
  RECOVER: "/recover"
};

export default ROUTES;
