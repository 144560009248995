import React from "react";
import styled from "styled-components/macro";
import { StyledCard, AnimatingDiv } from "../UIUtils";
import {
  subscribeToNumberOfCompliantDevicesInProject,
  getNumberOfCompliantDevicesInProject
} from "../FirebaseFunctions";

const DeviceCard = styled(StyledCard)`
  overflow:hidden;
  min-height:80px;
`;

const WidgetSpan = styled.span`
  margin: 32px;
  display: block;
`;

class CompliantDevicesWidget extends React.Component {
  unsubscribeList = [];
  state = {
    compliantDevices: -1
  };
  mounted = false;

  getCompliantDevices = async(remote) => {
    if(!this.mounted)return;
    const projectId = this.props.project.projectId;
    const onlineNumber = await getNumberOfCompliantDevicesInProject(projectId, remote);
    return onlineNumber;
  }

  init = async () => {
    if(!this.mounted)return;
    let onlineNumber = await this.getCompliantDevices(false);
    if(!this.mounted)return;
    if(onlineNumber > 0 ){
      this.setState({compliantDevices : onlineNumber});
    }
    if(!this.mounted)return;
    onlineNumber = await this.getCompliantDevices(true);
    if(!this.mounted)return;
    this.setState({compliantDevices : onlineNumber});
  }

  subscribeToCompliance = async () => {
    const projectId = this.props.project.projectId;
    let that = this;
    if(!that.mounted)return;
    let unsub = await subscribeToNumberOfCompliantDevicesInProject(
      projectId,
      function(doc, type) {
        if(!that.mounted)return;
        if(type == 'added'){
          let num = that.state.compliantDevices;
          num++;
          that.setState({compliantDevices : num});
        }
        else if(type == 'removed'){
          let num = that.state.compliantDevices;
          num--;
          if(num < 0 ) num = 0;
          that.setState({compliantDevices : num});
        }
        console.log("grabCompliantDevices : ", doc, type);
        // that.mergeInfoToDevice(device);
      }
    );
    this.unsubscribeList.push(unsub);
  };

  componentDidMount() {
    this.mounted = true;
    // this.grabDeviceNumber();
    this.init();
    // this.subscribeToCompliance();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    if (
      this.props.project
    ) {
      return (
        <DeviceCard>
        {this.state.compliantDevices == -1 && (
          <AnimatingDiv></AnimatingDiv>
        )}
        {this.state.compliantDevices >= 0 && (
          <WidgetSpan>{"Compliant Devices : " + this.state.compliantDevices}</WidgetSpan>
        )}
        </DeviceCard>
      );
    } else {
      return null;
    }
  }
}

export default CompliantDevicesWidget;
