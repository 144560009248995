import React from "react";
import styled from 'styled-components/macro';
import LogoHeader from "./components/LogoHeader";
import {
  showMetaTags,
  CenteredOuterDiv,
} from "./UIUtils"

const Outer = styled(CenteredOuterDiv)`
  text-align:center
`
const StyledSpan = styled.span`
  display:inline-block;
  margin-top: 30px;
`

const DownloadButton = styled.div`
  display: inline-block;
  padding: 10px 30px;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  min-width: 200px;
  background-color:#fda128;
  margin-top:50px
`;

const StyledLink = styled.a`
`

class DownloadPage extends React.Component {
  state = {
    isDownloading : false,
  };

  componentDidMount() {
  }

  download = () => {
    this.setState({isDownloading : true})
    return true;
  }

  render() {

    return (
      <>
      <LogoHeader title={"Download MyProjects apk"} />
      {showMetaTags("Download MyProjects", 'Download the MyProjects app')}
        <Outer>
        {this.state.isDownloading && (
          <StyledSpan>
          MyProjects Apk is downloading,           <StyledLink href="https://storage.googleapis.com/myplayer-apps/apks/io.myplayer.android.myprojects/MyProjects-0.1.6-beta2.apk">
click here to download again </StyledLink>
          </StyledSpan>
        )}
        {!this.state.isDownloading && (
          <StyledLink download onClick={this.download} href="https://storage.googleapis.com/myplayer-apps/apks/io.myplayer.android.myprojects/MyProjects-0.1.6-beta2.apk">
          <DownloadButton>
           Download
          </DownloadButton>
          </StyledLink>
        )}
        </Outer>
      </>
    );
  }
}

export default DownloadPage;
