import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import "./css/Dashboard.css";
import { Link } from "react-router-dom";
import {
  subscribeToProjectChanges,
  returnContentUrlForProject,
  subscribeToUser,
  subscribeToUserProjectRemoval,
  getLoggedInUsersId,
  getPermissionsForKey,
  getDefaultDeviceProject,
  getProject,
  approveProject
} from "./FirebaseFunctions";
import styled from "styled-components";
import woodBackground from "./images/woodBackground.png";
import minusIcon from "./images/minusIcon.svg";
import plusIcon from "./images/plusIcon.svg";
import { withRouter } from "react-router";
import { withLogin } from "./loggedInPage.js";
import {
  showMetaTags,
  showMessage,
  StyledSizing,
  CenteredOuterDiv
} from "./UIUtils";
import UserRow from "./components/UserRow.js";

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto;
  background-color: black;
  background-size: cover;
  text-align: center;
  background-image: url(${props => props.bgImage});
`;

const TabletOuter = styled.div`
  transform: translateX(-50%) translateY(-50%) scale(${props => props.scale});
  top: 50%;
  left: 50%;
  position: absolute;
  display: inline-block;
  border-radius: 45px;
  background-color: black;
  border: 5px solid #ececec;
  overflow: hidden;
  padding: 50px;
  transition: all 0.3s ease;
`;

const ZoomIcon = styled.img`
  width: 25px;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
`;

const ZoomSection = styled.div`
  margin-top: 15px;
  font-weight: bold;
  z-index: 100;
  padding: 0px 10px;
  position: relative;
  display: inline-block;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
`;

const EmptyExperienceSpan = styled.div`
  color: white;
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  font-weight: bold;
  line-height: 42px;
  transform: translateX(-50%) translateY(-50%);
`;

const ExperienceFrame = styled.iframe`
  position: relative;
  outline: 1px solid #c7c7c7;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const EditContentButton = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  color: white;
  padding: 16px 30px;
  z-index: 100;
  cursor: pointer;
`;

const BackButton = styled.div`
  padding: 20px 10px;
  color: black;
  border-radius: 5px;
  display: block;
  cursor: pointer;
  margin-left: 50px;
  position: absolute;
  float: left;
`;

const ApproveContentButton = styled(EditContentButton)`
  right: 150px;
`;

// const UploaderArea = styled(CenteredOuterDiv)`
//   position:fixed;
//   bottom:0px;
//   text-align:center;
//   overflow-x:scroll;
// `

const UploaderArea = styled(CenteredOuterDiv)`
  position: fixed;
  bottom: 0px;
  overflow-x: auto;
`;

class ProjectExperiencePage extends React.Component {
  state = {
    user: {},
    project: {},
    contentURL: {},
    scale: 0.6,
    hasLoaded: false,
    approved: false
  };
  unsubscribeList = [];

  back = () => {
    const onlyHasExperience =
      this.state.project.permissions &&
      this.state.project.permissions.content.read &&
      !this.state.project.permissions.users.read &&
      !this.state.project.permissions.devices.read &&
      !this.state.project.permissions.analytics.read;
    if (onlyHasExperience) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/project/" + this.state.project.projectId);
    }
  };

  scaleUp = () => {
    let newScale = this.state.scale + 0.1;
    if (newScale > 1.5) {
      newScale = 1.5;
    }
    this.setState({ scale: newScale });
  };

  scaleDown = () => {
    let newScale = this.state.scale - 0.1;
    if (newScale < 0.3) {
      newScale = 0.3;
    }
    this.setState({ scale: newScale });
  };

  checkAlreadyApproved = (approved, callback) => {
    const usersId = getLoggedInUsersId();
    if (!approved || approved.length == 0) {
      return callback(false);
    }
    approved.forEach(function(approval) {
      if (approval.usersId == usersId) {
        return callback(true);
      }
      if (approved[approved.length - 1] === approval) {
        return callback(false);
      }
    });
  };

  unApproveContent = async () => {
    let that = this;
    let approved = this.state.project.approved;
    if (!approved) return;
    let usersId = getLoggedInUsersId();
    const filteredItems = approved.filter(function(item) {
      return item.usersId !== usersId;
    });
    const rsp = approveProject({
      projectId: that.state.project.projectId,
      approved: filteredItems
    });
    showMessage("Project Unapproved");
  };

  approveContent = async () => {
    let that = this;
    let approved = this.state.project.approved;
    if (!approved) approved = [];
    this.checkAlreadyApproved(approved, function(alreadyApproved) {
      if (alreadyApproved) {
        showMessage("Project Already Approved");
        return;
      }
      approved.push({
        usersId: getLoggedInUsersId(),
        timestamp: Date.now()
      });
      const rsp = approveProject({
        projectId: that.state.project.projectId,
        approved: approved
      });
      showMessage("Project Approved");
    });
  };

  grabProjectInfo = async projectId => {
    const that = this;
    let unsub = await subscribeToProjectChanges(projectId, function(rsp) {
      let useProject = { ...that.state.project, ...rsp };
      let contentURL = returnContentUrlForProject(useProject);
      that.setState({ project: useProject, contentURL: contentURL });
      that.setState({ hasLoaded: true });
      if (that.state.project.approved) {
        that.checkAlreadyApproved(that.state.project.approved, approved => {
          console.log("approved : ", approved);
          that.setState({ approved: approved });
          console.log("this state : ", that.state);
        });
      }
    });
    this.unsubscribeList.push(unsub);
  };

  getPermissionsFromKeyState = async (permissionsKey, stateKey) => {
    let permissions = await getPermissionsForKey(permissionsKey);
    let useProject = {
      ...this.state[stateKey],
      permissions: { ...this.state[stateKey].permissions, ...permissions }
    };
    this.setState({ [stateKey]: useProject });
  };

  subscribeToPermissionsChange = async (usersId, callback) => {
    const that = this;
    /* subscribe to permissions changes and deletion of the project */
    let unsub = await subscribeToUserProjectRemoval(
      that.props.match.params.projectId,
      usersId,
      function(proj, type) {
        if (proj.projectId != that.props.match.params.projectId) return;
        callback(proj, type);
      }
    );
    this.unsubscribeList.push(unsub);
  };

  getViewerProjectPermissionsChanges = async () => {
    const that = this;
    const usersId = getLoggedInUsersId();
    this.subscribeToPermissionsChange(usersId, function(proj, type) {
      if (proj.permissionsKey) {
        that.getPermissionsFromKeyState(proj.permissionsKey, "user");
      }
    });
  };

  componentDidMount() {
    var self = this;
    let project = { projectId: this.props.match.params.projectId };
    this.setState({ project: project });
    self.setState({ user: this.props.user });
    this.grabProjectInfo(project.projectId);
    this.getProjectSize();
    this.getViewerProjectPermissionsChanges();
  }

  async getProjectSize() {
    // const project = await getProject(projectId);
    const sizesObj = await getDefaultDeviceProject("common_project_sizes");
    Object.entries(sizesObj).map(sizeObj => {
      if (sizeObj[0] === this.state.project.projectSizeKey) {
        this.setState({ projectSize: sizeObj[1] });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    const uploadPath = "/project/" + this.state.project.projectId + "/upload";
    const needsContent =
      this.state.user &&
      this.state.user.permissions &&
      !this.state.project.hasMedia &&
      this.state.user.permissions.content.write;
    if (
      this.state.project &&
      this.state.project.hasMedia &&
      this.state.user &&
      this.state.user.uid &&
      this.state.contentURL
    ) {
      console.log("this state approved : ", this.state.project);
      return (
        <>
          {showMetaTags("Project Content", "View Your Uploaded Content")}
          <Background bgImage={woodBackground}>
            <BackButton onClick={this.back}>&#8592; BACK</BackButton>
            <ZoomSection>
              <ZoomIcon onClick={this.scaleDown} src={minusIcon} />
              <span>Zoom</span>
              <ZoomIcon onClick={this.scaleUp} src={plusIcon} />
            </ZoomSection>
            <TabletOuter scale={this.state.scale}>
              <ExperienceFrame
                src={this.state.contentURL}
                width={
                  this.state.projectSize ? this.state.projectSize.width : "1280"
                }
                height={
                  this.state.projectSize ? this.state.projectSize.height : "720"
                }
              ></ExperienceFrame>
            </TabletOuter>
          </Background>
          {this.state.user &&
            this.state.user.permissions &&
            this.state.user.permissions.content.write && (
              <Link to={uploadPath}>
                <EditContentButton> Edit Content </EditContentButton>
              </Link>
            )}
          {(!this.state.project.uploader ||
            this.state.project.uploader.usersId != this.state.user.uid) &&
            !this.state.approved &&
            this.state.user &&
            this.state.user.permissions &&
            this.state.project.hasMedia &&
            this.state.user.permissions.project.write && (
              <ApproveContentButton onClick={this.approveContent}>
                {"Approve Content"}
              </ApproveContentButton>
            )}
          {(!this.state.project.uploader ||
            this.state.project.uploader.usersId != this.state.user.uid) &&
            this.state.approved &&
            this.state.user &&
            this.state.user.permissions &&
            this.state.user.permissions.project.write && (
              <ApproveContentButton onClick={this.unApproveContent}>
                {"Unapprove Content"}
              </ApproveContentButton>
            )}
          {this.state.user &&
            this.state.user.permissions &&
            this.state.user.permissions.users.read &&
            (this.state.project.uploader || this.state.project.approved) && (
              <UploaderArea>
                {this.state.project.uploader && (
                  <UserRow
                    user={this.state.project.uploader}
                    project={this.state.project}
                    isCreator={false}
                    key={this.state.project.uploader}
                    asUploader={true}
                  ></UserRow>
                )}
                {this.state.project.approved &&
                  this.state.project.approved.length > 0 && (
                    <>
                      {this.state.project.approved.map(user => (
                        <UserRow
                          user={user}
                          project={this.state.project}
                          isCreator={false}
                          key={user.usersId}
                          asUploader={false}
                          asApproved={true}
                        ></UserRow>
                      ))}
                    </>
                  )}
              </UploaderArea>
            )}
        </>
      );
    } else if (
      this.state.project &&
      !this.state.project.hasMedia &&
      this.state.user &&
      this.state.user.uid &&
      this.state.contentURL &&
      this.state.hasLoaded
    ) {
      return (
        <>
          <Background bgImage={woodBackground}>
            <TabletOuter scale={this.state.scale}>
              <ExperienceFrame
                width={
                  this.state.projectSize ? this.state.projectSize.width : "1280"
                }
                height={
                  this.state.projectSize ? this.state.projectSize.height : "720"
                }
              ></ExperienceFrame>
              <EmptyExperienceSpan>
                {" "}
                Content Unavailable,
                <br />
                Please check back later{" "}
              </EmptyExperienceSpan>
            </TabletOuter>
          </Background>
          {this.state.user &&
            this.state.user.permissions &&
            this.state.user.permissions.content.write && (
              <Link to={uploadPath}>
                <EditContentButton> Edit Content </EditContentButton>
              </Link>
            )}
          {(!this.state.project.uploader ||
            this.state.project.uploader.usersId != this.state.user.uid) &&
            !this.state.approved &&
            this.state.user &&
            this.state.user.permissions &&
            this.state.project.hasMedia &&
            this.state.user.permissions.project.write && (
              <ApproveContentButton onClick={this.approveContent}>
                {"Approve Content"}
              </ApproveContentButton>
            )}
          {(!this.state.project.uploader ||
            this.state.project.uploader.usersId != this.state.user.uid) &&
            this.state.approved &&
            this.state.user &&
            this.state.user.permissions &&
            this.state.user.permissions.project.write && (
              <ApproveContentButton onClick={this.unApproveContent}>
                {"Unapprove Content"}
              </ApproveContentButton>
            )}
          {this.state.user &&
            this.state.user.permissions &&
            this.state.user.permissions.users.read &&
            (this.state.project.uploader || this.state.project.approved) && (
              <UploaderArea>
                {this.state.project.uploader && (
                  <UserRow
                    user={this.state.project.uploader}
                    project={this.state.project}
                    isCreator={false}
                    key={this.state.project.uploader}
                    asUploader={true}
                  ></UserRow>
                )}
                {this.state.project.approved &&
                  this.state.project.approved.length && (
                    <>
                      {this.state.project.approved.map(user => (
                        <UserRow
                          user={user}
                          project={this.state.project}
                          isCreator={false}
                          key={this.state.project.uploader}
                          asUploader={false}
                          asApproved={true}
                        ></UserRow>
                      ))}
                    </>
                  )}
              </UploaderArea>
            )}
        </>
      );
    } else if (needsContent) {
      return <Redirect to={uploadPath} />;
    } else if (this.state.user == null) {
      return <Redirect to="/" />;
    } else {
      return <></>;
    }
  }
}

export default withLogin(ProjectExperiencePage);
