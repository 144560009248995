import React from "react";
import styled from "styled-components/macro";
import { Redirect } from "react-router";
import ProjectBaseUI from "./ProjectBaseUI";
import UserList from "./UserList";
import DeviceList from "./DeviceList";
import ProjectAnalytics from "./ProjectAnalytics";
import ProjectCodeList from "./ProjectCodeList";
import { getLoggedInUsersId } from "../FirebaseFunctions";
import LoadingSpinner from "../components/LoadingSpinner";

const PageSelector = styled.div`
  width: 100%;
  text-align: left;
  display: block;
  padding: 15px 0px;
  overflow:auto;
  border-bottom: 1px solid grey;
`;

const PageSection = styled.div`
  width: 100%;
  text-align: left;
  display: block;
  position:relative;
`;

const PageTitle = styled.span`
  margin: 10px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  border-bottom: ${props => (props.selected ? "#f7822f solid 2px" : "none")};
  color: ${props => (props.selected ? "#f7822f" : "grey")};
`;

const Page = styled.div`
  margin-top: 25px;
  overflow: hidden;
  width: 100%;
  padding-bottom: 200px;
  display: ${props => (props.isShown ? "block" : "none")};
`;

class ProjectPageSections extends ProjectBaseUI {
  state = {
    ...this.state,
    pageChange: false,
    redirectURL: null,
    loadedPages: []
  };

  currentPage = null;

  pageClicked = page => {
    this.currentPage = page;
    let loadedPages = this.state.loadedPages;
    const { history, project } = this.props;
    if (!loadedPages.includes(page.key)) {
      this.setState({ loadedPages: [...loadedPages, page.key] });
    }
    history.push(`/project/${project.projectId}/${page.key}`);
    this.setState({ pageChange: !this.state.pageChange });
  };

  calcPages = () => {
    let project = this.state.project;
    if (!project) return [];
    let permissions = this.state.project.permissions;
    let pages = [];

    if (permissions == undefined) return pages;

    if (permissions.users.read) {
      pages.push({ title: "Users", key: "users", ...permissions });
    }
    if (permissions.devices.read) {
      pages.push({ title: "Devices", key: "devices", ...permissions });
    }
    if (permissions.analytics.read) {
      pages.push({ title: "Analytics", key: "analytics", ...permissions });
    }
    if (permissions.code.write) {
      pages.push({ title: "Codes", key: "codes", ...permissions });
    }
    return pages;
  };

  backToDashboard = (currentPage, defaultPage) => {
    const { history } = this.props;
    return <Redirect push to={{ pathname: "/" }} />;
  };

  getCurrentPage = (pages, key) => {
    if (pages.length == 0) return;
    console.log("pages, key) : ", pages, key);
    let matchedPage = undefined;
    pages.map(page => {
      if (page.key === key) {
        matchedPage = page;
      }
    });
    if (matchedPage == undefined) {
      matchedPage = pages[0];
    }
    let loadedPages = this.state.loadedPages;
    if (!loadedPages.includes(matchedPage.key)) {
      this.setState({ loadedPages: [...loadedPages, matchedPage.key] });
    }
    return matchedPage;
  };

  render() {
    if (this.state.redirectURL) {
      return <Redirect to={this.state.redirectURL} />;
    }
    if (
      this.state.project &&
      !this.state.project.deleted &&
      !this.state.removed &&
      this.state.project.creatorId
    ) {
      const pages = this.calcPages();
      const { history } = this.props;
      const locationArr = history.location.pathname.split("/");
      const location = locationArr[locationArr.length - 1];
      const currentPage = this.getCurrentPage(pages, location);
      if (currentPage !== undefined) {
        this.currentPage = currentPage;
      }

      if (!pages.length) {
        return null;
      }
      if (currentPage === undefined) {
        return <Redirect push to={{ pathname: "/" }} />;
      }
      return (
        <>
          <PageSelector>
            {pages.map(page => (
              <PageTitle
                onClick={() => this.pageClicked(page)}
                key={page.key}
                selected={this.currentPage && this.currentPage.key == page.key}
              >
                {page.title}
              </PageTitle>
            ))}
          </PageSelector>
          <PageSection>
            {this.state.loadedPages.indexOf("devices") > -1 && (
              <Page
                isShown={this.currentPage && this.currentPage.key == "devices"}
              >
                <DeviceList project={this.state.project}></DeviceList>
              </Page>
            )}
            {this.state.loadedPages.indexOf("users") > -1 && (
              <Page
                isShown={this.currentPage && this.currentPage.key == "users"}
              >
                <UserList project={this.state.project}></UserList>
              </Page>
            )}
            {this.state.loadedPages.indexOf("analytics") > -1 && (
              <Page
                isShown={
                  this.currentPage && this.currentPage.key == "analytics"
                }
              >
                <ProjectAnalytics
                  project={this.state.project}
                  usersId={getLoggedInUsersId()}
                ></ProjectAnalytics>
              </Page>
            )}
            {this.state.loadedPages.indexOf("codes") > -1 && (
              <Page
                isShown={this.currentPage && this.currentPage.key == "codes"}
              >
                <ProjectCodeList
                  project={this.state.project}
                  usersId={getLoggedInUsersId()}
                ></ProjectCodeList>
              </Page>
            )}
          </PageSection>
        </>
      );
    } else {
      return null;
    }
  }
}

export default ProjectPageSections;
