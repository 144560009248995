import app from "firebase/app";
const DEMO_MODE = process.env.REACT_APP_DEMO_MODE || null;

const CONFIG = DEMO_MODE
  ? {
      // test config
      apiKey: "AIzaSyAzOd8vZBkpuJioGqyvXFc8zMPq2ut1jfM",
      authDomain: "myplayertest.firebaseapp.com",
      databaseURL: "https://myplayertest.firebaseio.com",
      projectId: "myplayertest",
      storageBucket: "myplayertest.appspot.com",
      messagingSenderId: "209604178303",
      appId: "1:209604178303:web:2b9f57a6a5ce0f8d25aa4a"
    }
  : {
      // production config
      apiKey: "AIzaSyAXRZrgD935w7S3CCIPlzsj5ZcZIL6LG68",
      authDomain: "myplayerbase.firebaseapp.com",
      databaseURL: "https://myplayerbase.firebaseio.com",
      projectId: "myplayerbase",
      storageBucket: "myplayerbase.appspot.com",
      messagingSenderId: "448033846169",
      appId: "1:448033846169:web:61062ef4862d2ce748d908"
    };

const PATHS = {
  USER_PROFILE: "users"
};

class Firebase {
  constructor() {
    app.initializeApp(CONFIG);
    this.auth = app.auth();
    this.db = app.firestore();

    // Enable Persistence
    this.db.enablePersistence({ synchronizeTabs: true }).catch(function(err) {
      console.error("enablePersistence : ", err);
    });
  }

  getAuth = () => this.auth;

  createUserProfile = (email, name, usersId) =>
    this.db
      .collection(PATHS.USER_PROFILE)
      .doc(usersId)
      .set({ email, name, usersId, accountId: email });

  registerUser = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  resetPassword = email => this.auth.sendPasswordResetEmail(email);

  signIn = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();
}

export default Firebase;
