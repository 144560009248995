import Swal from "sweetalert2";
import styled, { keyframes } from "styled-components/macro";
import { Helmet } from "react-helmet";
import React from "react";

import { primaryColor } from "./theme";

export const TopBanner = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  background-color: black;
  color: white;
  text-align: center;
`;

export const Spinner = () => (
  <div className="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const placeHolderShimmer = keyframes`
0%{
    background-position: -468px 0
}
100%{
    background-position: 468px 0
}
`;

export const AnimatingDiv = styled.div`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(
    to right,
    rgb(250, 250, 250) 8%,
    rgb(240, 240, 240) 18%,
    rgb(250, 250, 250) 33%
  );
  background-size: 800px 104px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  overflow: hidden;
`;

export const PersonCircle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border: 2px solid white;
  background-color: white;
  border-radius: 50%;
  background-image: url(${props => props.bgImage});
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  background-size: cover;
`;

export const standardButton = styled.div`
  display: inline-block;
  padding: 10px 30px;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  min-width: 200px;
`;

export const EmptyTablePlaceholder = styled.div`
  width: 90%;
  left: 5%;
  position: relative;
  text-align: center;
  margin-top: 50px;
`;

export const DescSpan = styled.div`
  width: 100%;
  margin: 20px 10px;
  padding: 0px 0px 10px 0px;
  font-size: 18px;
`;

export const HeaderSpan = styled.div`
  font-weight: bold;
  width: 100%;
  margin: 20px 10px;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid grey;
  font-size: 24px;
`;

export const CenteredOuterDiv = styled.div`
  position: relative;
  text-align: left;
  width: 90%;
  left: 5%;

  @media (min-width: 1440px) {
    width: 1296px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const StyledSizing = styled.div`
  margin: 10px;
  width: calc(33% - 20px);

  @media (min-width: 1260px) {
    margin: 10px;
    width: calc(25% - 20px);
  }

  @media (max-width: 1000px) {
    margin: 10px;
    width: calc(50% - 20px);
  }

  @media (max-width: 640px) {
    margin: 10px;
    width: calc(100% - 20px);
  }
`;

export const StyledCard = styled(StyledSizing)`
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  display: inline-block;
  vertical-align: top;
  position: relative;
  color: black;
  font-weight: bold;
  border-radius: 10px;

  margin: 10px;
  width: calc(33% - 20px);

  @media (min-width: 1260px) {
    margin: 10px;
    width: calc(25% - 20px);
  }

  @media (max-width: 1000px) {
    margin: 10px;
    width: calc(50% - 20px);
  }

  @media (max-width: 640px) {
    margin: 10px;
    width: calc(100% - 20px);
  }
`;

export const showMessage = async (title, desc) => {
  const result = await Swal.fire({
    title: title,
    text: desc
  });
  return result;
};

export const showInputMessage = async (title, placeholder) => {
  const result = await Swal.fire({
    title: title,
    input: "text",
    inputPlaceholder: placeholder
  });
  return result;
};

export const showConfirmation = async function(title, desc) {
  const result = await Swal.fire({
    title: title,
    text: desc,
    allowOutsideClick: false,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: primaryColor,
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
    reverseButtons: true
  });
  return result;
};

export const showMultiOption = async (title, desc, button1, button2) => {
  const result = await Swal.fire({
    title: title,
    text: desc,
    showCancelButton: true,
    confirmButtonColor: "black",
    cancelButtonColor: "black",
    confirmButtonText: button1,
    cancelButtonText: button2
  });
  return result;
};

export const showCheckBoxConfirm = async (
  title,
  desc,
  checkboxTitle,
  checked
) => {
  const result = Swal.fire({
    title: title,
    text: desc,
    html:
      `
              <div id="swal2-content" style="display: block;">
              ` +
      desc +
      `
              </div>
              <div class="form-check" style="margin:10px;">
                  <input class="form-check-input" type="checkbox" value="" id="checkbox1" style="display: inline-block;vertical-align: middle;position: relative;width: initial;">
                  <span style="display: inline-block;vertical-align: middle;position: relative;">
                      ` +
      checkboxTitle +
      `
                  </span>
              </div>
      `,
    focusConfirm: false,
    allowOutsideClick: false,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: primaryColor,
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
    reverseButtons: true,
    preConfirm: () => {
      checked(document.getElementById("checkbox1").checked);
    }
  });
  return result;
};

export const showPercentageIndicator = async (title, percentId) => {
  const result = await Swal.fire({
    title: "<strong>" + title + "</strong>",
    html: '<span id="' + percentId + '">0%</span>',
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false
  });
  return result;
};

export const showToast = async (msg, timer = 3000, background = "#F78130") => {
  const position = "bottom";
  const showConfirmButton = false;
  const showCloseButton = true;
  const width = 400;
  const html = `<div style="color: #FFFFFF; font-size: 14px"><b>${msg}</b></div>`;
  return await Swal.fire({
    html,
    timer,
    background,
    width,
    position,
    showConfirmButton,
    showCloseButton
  });
};

export const showSuccessToast = async msg => showToast(msg, 3000, "#00BB00");

export const showErrorToast = async msg => showToast(msg, 3000, "#BB0000");

export const showMetaTags = (title, description) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content="https://www.myplayer.io" />
      <meta property="og:site_name" content="MyPlayer" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};
