import React from "react";
import AuthUserContext from "./Context";
import { withFirebase } from "../Firebase";

/**
 * provides { user, initialized } to consumers
 */
const provideAuth = Component => {
  class AuthProviderClass extends React.Component {
    state = {
      user: null,
      initialized: false
    };
    componentDidMount() {
      // currentUser needs time to initialize so we cannot make use of it immediately
      this.listener = this.props.firebase.getAuth().onAuthStateChanged(user => {
        console.debug("onAuthStateChanged", user);
        const initialized = true;
        user
          ? this.setState({ initialized, user })
          : this.setState({ initialized, user: null });
      });
    }
    componentWillUnmount() {
      this.listener();
    }
    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(AuthProviderClass);
};

export default provideAuth;
