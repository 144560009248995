import React from "react";
import { Box, Button, Chip, Grid } from "@material-ui/core";
import { CheckCircleOutlineSharp, HighlightOffSharp } from "@material-ui/icons";

import { DeviceStrings } from "../strings";
import {
  isDeviceOnlineSince,
  requestHeartbeat,
  subscribeToDeviceStatus
} from "../FirebaseFunctions";
import { showToast, showErrorToast } from "../UIUtils";

import { BorderedBox } from "./styled";

const OnlineChip = props => {
  if (props.lastOnline === null)
    return <Chip label={DeviceStrings.DEVICE_ONLINE_STATUS_CHECKING} />;
  const isOnline = isDeviceOnlineSince(props.lastOnline);
  if (isOnline)
    return (
      <Chip
        label={DeviceStrings.DEVICE_ONLINE_STATUS_ONLINE}
        clickable={false}
        color="primary"
        icon={<CheckCircleOutlineSharp />}
      />
    );
  else
    return (
      <Chip
        label={DeviceStrings.DEVICE_ONLINE_STATUS_OFFLINE}
        clickable={false}
        color="default"
        icon={<HighlightOffSharp />}
      />
    );
};

class Online extends React.Component {
  state = {
    lastOnline: null
  };

  unsubList = [];

  requestOnline = async () => {
    const { deviceId, projectId } = this.props;
    const result = await requestHeartbeat(deviceId, projectId);
    console.debug("requestHeartbeat", result);
    if (result.success) {
      showToast(DeviceStrings.DEVICE_REQUEST_RECEIVED, 2000);
    } else {
      const msg =
        result.errors.length > 0
          ? result.errors[0]
          : DeviceStrings.DEVICE_ERROR_REQUEST_FAILED;
      showErrorToast(msg);
    }
  };

  // check if all required params exist, otherwise disable button
  isInvalid = () => {
    const { deviceId, projectId } = this.props;
    return !deviceId || !projectId;
  };

  onStatusUpdate = apiInfo => {
    const lastOnline = apiInfo.updatedAt;
    console.debug(`onStatusUpdate: lastOnline=${lastOnline}`);
    this.setState({ lastOnline });
  };

  componentDidMount = async () => {
    const { deviceId } = this.props;

    this.unsubList.push(
      await subscribeToDeviceStatus(deviceId, this.onStatusUpdate)
    );
  };

  componentWillUnmount = () => {
    this.unsubList.forEach(unsub => {
      unsub();
    });
  };

  render() {
    const { lastOnline } = this.state;

    return (
      <Box mt={3} mb={3}>
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <BorderedBox p={2}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={8} container spacing={1}>
                  <Grid item xs={12}>
                    {DeviceStrings.DEVICE_ONLINE_LABEL}
                    {": "}
                    <OnlineChip lastOnline={lastOnline} />
                  </Grid>
                  <Grid item xs={12}>
                    {DeviceStrings.DEVICE_ONLINE_LAST_ONLINE}
                    {!!lastOnline && `: ${new Date(lastOnline).toUTCString()}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.isInvalid()}
                    onClick={this.requestOnline}
                  >
                    {DeviceStrings.DEVICE_ONLINE_REQUEST}
                  </Button>
                </Grid>
              </Grid>
            </BorderedBox>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Online;
