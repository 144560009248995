import React from "react";
import firebase from "firebase";
import styled from "styled-components/macro";
import onClickOutside from "react-onclickoutside";

const DropDownOuter = styled.div`
  min-width: 100px;
  height: auto;
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
  font-weight:bold;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 3px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    margin-top: -5px;
  }
`;

const DropDownItem = styled.div`
  width: 100%;
  cursor:pointer;
  margin: 10px 0px;
  color: black;
  &:hover {
    opacity: 0.5;
  }
`;

class DropDown extends React.Component {
  handleClickOutside = evt => {
    console.log("Clicked outside");
    this.props.toggleShown();
  };

  componentDidMount() {
    console.log("this.props.dropItems : ", this.props.dropItems);
  }

  render() {
    if (this.props.dropItems) {
      return (
        <DropDownOuter top={this.props.top} right={this.props.right}>
          {this.props.dropItems.map(dropDownItem => (
            <DropDownItem onClick={dropDownItem.action} key={dropDownItem.name}>
              <span
                style={{
                  color:
                    dropDownItem.style == "Negative"
                      ? "red"
                      : dropDownItem.style == "Neutral"
                      ? "Grey"
                      : "black"
                }}
              >{`${dropDownItem.name}`}</span>
            </DropDownItem>
          ))}
        </DropDownOuter>
      );
    } else {
      return null;
    }
  }
}

export default onClickOutside(DropDown);
