import React from "react";
import styled from "styled-components/macro";
import ProjectBaseUI from "./ProjectBaseUI";
import CodeCell from "./CodeCell"
import { EmptyTablePlaceholder, showInputMessage } from "../UIUtils";
import { subscribeToCodesInProject } from "../FirebaseFunctions";

class ProjectCodeList extends ProjectBaseUI {

  grabCodes = async () => {
    var that = this;
    console.log("Grab codes");
    let unsub = await subscribeToCodesInProject(
      this.props.project.projectId,
      function(codeDict, type) {
        console.log("codeDict : ", codeDict);
        let project = that.state.project;
        if(!project.codes){
          project.codes = [];
        }
        let foundIndex = that.state.project.codes.findIndex(
          element => codeDict.code == element.code
        );
        if(foundIndex == -1 && type == 'added'){
          project.codes.push(codeDict);
          that.setState({ project: project});
        }
        else if(foundIndex > -1 && type == 'removed'){
          project.codes.splice(foundIndex, 1);
          that.setState({ project: project });
        }
      }
    );
    this.unsubscribeList.push(unsub);
  }

  componentWillUpdate() {
    if (
      this.state.project &&
      this.state.project.permissions &&
      this.state.project.permissions.code.write &&
      !this.state.project.codes
    ) {
      this.grabCodes();
    }
  }

  render() {
    if (
      this.state.project &&
      this.state.project.permissions &&
      this.state.project.permissions.code.write &&
      this.state.project.codes &&
      this.state.project.codes.length
    ) {
      return (
        <>
        {this.state.project.codes.map(codeDict => (
          <CodeCell
            codeDict={codeDict}
            key={codeDict.code}
            project={this.state.project}
          ></CodeCell>
        ))}
        </>
      )
    } else if (
      this.state.project &&
      this.state.project.codes &&
      !this.state.project.codes.length
    ) {
      return (
        <EmptyTablePlaceholder>
          This project doesn't have any codes yet
        </EmptyTablePlaceholder>
      );
    }
    return null;
  }
}

export default ProjectCodeList;
