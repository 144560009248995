import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { DebounceInput } from "react-debounce-input";
import searchIcon from "../images/searchIcon.svg";
import {
  getDevicesInProject,
  subscribeToDevicesInProject,
  getAllDevices,
  getFile,
  getDevice,
  subscribeToDevice,
  transferDevice
} from "../FirebaseFunctions";
import {
  EmptyTablePlaceholder,
  StyledCard,
  showMessage,
  HeaderSpan,
  DescSpan,
  CenteredOuterDiv,
  showConfirmation
} from "../UIUtils";
import DeviceCell from "./DeviceCell";
import TotalDevicesWidget from "./TotalDevicesWidget";
import OnlineDevicesWidget from "./OnlineDevicesWidget";
import CompliantDevicesWidget from "./CompliantDevicesWidget";
import ProjectList from "./ProjectList";

import { ROUTES } from "../route";

const StatsBlock = styled(CenteredOuterDiv)`
  width: 100%;
  left: 0;
`;

const SearchImage = styled.img`
  position: absolute;
  left: 22px;
  width: 20px;
  margin-top: 10px;
  opacity: 0.55;
`;

const SearchBlock = styled(DebounceInput)`
  margin-left: 10px;
  margin-bottom: 20px;
  background: #f1f1f1;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  margin-top: 20px;
  padding-left: 50px;
`;

const ResultBlock = styled.div`
  display: ${props => (props.isShown ? "block" : "none")};
`;

const MoreDevicesCard = styled.div`
  cursor: pointer;
  background-color: #f7822f;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  display: block;
  vertical-align: top;
  position: relative;
  color: black;
  font-weight: bold;
  border-radius: 10px;
  padding: 10px 20px;
  text-align: center;
  color: white;
  margin: 10px;
`;

const ProjectListHolder = styled.div`
  position: fixed;
  width: 150%;
  height: 150%;
  top: -25%;
  left: -25%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ProjectListHolderInner = styled.div`
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  display: inline-block;
  vertical-align: top;
  position: relative;
  color: black;
  font-weight: bold;
  border-radius: 10px;
  -webkit-transform: translateY(-50%) translateX(-50%);
  max-height: 50%;
  min-height: 20%;
  overflow-y: auto;
  overflow-x: hidden;
  top: 50%;
  left: 50%;
`;

const ClickOff = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

class DeviceList extends React.Component {
  unsubscribeList = [];
  mounted = false;
  state = {
    devices: [],
    creatingSeachIndex: true,
    shownDevices: [],
    searchDevices: [],
    searchString: "",
    hasLoaded: false,
    transferringDevice: false
  };
  searchIndexCreated = false;
  searchableDevices = [];

  showMoreDevices = () => {
    let currentShown = this.state.shownDevices;
    let startIndex = currentShown.length;
    let maxResults = 20;
    let toAdd = this.state.devices.slice(startIndex, startIndex + maxResults);
    let newShown = currentShown.concat(toAdd);
    this.setState({ shownDevices: newShown });
    this.setState({ hasLoaded: true });
  };

  updateDevice = device => {
    if (!this.mounted) return;
    const currentDevices = this.searchableDevices;
    const foundDevice = currentDevices.find(
      dev => dev.deviceId == device.deviceId
    );
    const foundIndex = currentDevices.indexOf(foundDevice);
    let mergedDevice = { ...currentDevices[foundIndex], ...device };
    currentDevices.splice(foundIndex, 1, mergedDevice);
    this.searchableDevices = currentDevices;
    return mergedDevice;
  };

  /* shifting this out of device cell -- need to create some type of model */
  getDeviceInfo = async (device, isLastDevice) => {
    // let that = this;
    const rsp = await getDevice(device.deviceId, true);
    if (!this.mounted) return;
    const dev = this.updateDevice(rsp);

    let searchString = this.state.searchString;
    if (searchString.length) {
      let deviceId = rsp.deviceId;
      let storeId = rsp.storeId;
      let matched =
        deviceId.match(searchString) ||
        (storeId != undefined &&
          storeId.toLowerCase().match(searchString.toLowerCase()));
      if (matched) {
        this.setState({ creatingSeachIndex: false });
        this.setState({ searchDevices: [...this.state.searchDevices, dev] });
      }
    }

    if (isLastDevice) {
      this.setState({ creatingSeachIndex: false });
    }

    // let unsub = await subscribeToDevice(
    //   device.deviceId,
    //   function(device) {
    //     if(!that.mounted)return;
    //     console.log("update searchabledevice : ", device);
    //     that.updateDevice(device);
    //   }
    // );
    // this.unsubscribeList.push(unsub);
  };

  createSearchIndex = async () => {
    if (this.searchIndexCreated) return;
    this.searchIndexCreated = true;
    const that = this;
    let devices = this.state.devices;
    let i = 0;
    for (let device of devices) {
      i++;
      if (!device.deviceId || !device.storeId) {
        that.getDeviceInfo(device, i == devices.length);
      }
    }
  };

  selectedDevice = null;
  transferSelected = async project => {
    const rsp = await showConfirmation(
      "Transfer Device",
      "Are you sure you want to transfer the device with imei " +
        this.selectedDevice.deviceId +
        " to project " +
        project.name
    );
    if (rsp.value) {
      const message = await showMessage(
        "Your Device is being Transferred",
        "This may take a few minutes"
      );
      if (message.value) {
        this.setState({ transferringDevice: false });
      }
      const job = await transferDevice(
        project.projectId,
        this.selectedDevice.deviceId
      );
      console.log(this.selectedDevice, " transfer to project : ", project);
    }
  };

  transferDevice = device => {
    this.setState({ transferringDevice: true });
    this.selectedDevice = device;
  };

  endTransfer = () => {
    console.log("endTransfer");
    this.setState({ transferringDevice: false });
    this.selectedDevice = null;
  };

  grabDevices = async () => {
    const that = this;
    const devices = await getDevicesInProject(this.props.project.projectId);
    if (!this.mounted) return;
    this.setState({ devices: devices });
    this.showMoreDevices();
    this.searchableDevices = devices;
    let unsub = await subscribeToDevicesInProject(
      this.props.project.projectId,
      function(device, type) {
        if (!that.mounted) return;
        let devices = that.state.devices;
        let found = devices.find(
          element => device.deviceId == element.deviceId
        );
        if (type == "added" && device && !found) {
          that.setState({ devices: [device, ...devices] });
        }
        if (type == "removed" && device && found) {
          let foundIndex = devices.indexOf(device);
          devices.splice(foundIndex, 1);
          that.setState({ devices: devices });

          let searchFoundIndex = that.searchableDevices.indexOf(device);
          that.searchableDevices.splice(foundIndex, 1);
        }
      }
    );
    this.unsubscribeList.push(unsub);
  };

  componentDidMount() {
    this.mounted = true;
    this.grabDevices();
  }

  handleChange(event) {
    let search = event.target.value;
    this.setState({ searchString: search });
    this.handleSearchWithString(search);
  }

  handleSearchWithString = searchString => {
    // if(!this.searchIndexCreated){
    //   console.log("No Search index");
    //   return;
    // }
    if (searchString.length == 0) {
      this.setState({ searchDevices: [] });
      return;
    }
    console.log("Search String : ", searchString, this.searchableDevices);
    let matched = this.searchableDevices.filter(
      ({ deviceId, storeId }) =>
        deviceId.match(searchString) ||
        (storeId != undefined &&
          storeId.toLowerCase().match(searchString.toLowerCase()))
    );
    console.log("matched : ", matched);
    this.setState({ searchDevices: matched });
    if (matched.length) {
      this.setState({ creatingSeachIndex: false });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  gotoDevicePage(deviceId) {
    const projectId = this.props.project.projectId;
    const path = ROUTES.PROJECT_DEVICE.replace(":projectId", projectId).replace(
      ":deviceId",
      deviceId
    );
    this.props.history.push(path);
  }

  render() {
    if (this.props.project && this.state.devices && this.state.devices.length) {
      return (
        <>
          <StatsBlock>
            <TotalDevicesWidget project={this.props.project} />
            <OnlineDevicesWidget project={this.props.project} />
            <CompliantDevicesWidget project={this.props.project} />
          </StatsBlock>
          <div onClick={this.createSearchIndex}>
            <SearchImage src={searchIcon} />
            <SearchBlock
              placeholder="Search by imei, storeId or address"
              type="text"
              onChange={this.handleChange.bind(this)}
              minLength={2}
              debounceTimeout={300}
            ></SearchBlock>
          </div>
          {this.state.searchString.length > 0 &&
            !this.state.creatingSeachIndex && (
              <ResultBlock isShown={this.state.searchString.length > 0}>
                <HeaderSpan> Search Results</HeaderSpan>
                {this.state.searchDevices.length == 0 && (
                  <DescSpan>
                    No results Found for '{this.state.searchString}'{" "}
                  </DescSpan>
                )}
                {this.state.searchDevices.map(device => (
                  <DeviceCell
                    device={device}
                    key={device.deviceId}
                    project={this.props.project}
                    transferDevice={this.transferDevice}
                    onClick={() => this.gotoDevicePage(device.deviceId)}
                  ></DeviceCell>
                ))}
              </ResultBlock>
            )}
          {this.state.searchString.length > 0 && this.state.creatingSeachIndex && (
            <ResultBlock isShown={this.state.searchString.length > 0}>
              <HeaderSpan> Search Results</HeaderSpan>
              <DescSpan>
                {" "}
                Searching for '{this.state.searchString}'...{" "}
              </DescSpan>
            </ResultBlock>
          )}
          <ResultBlock isShown={this.state.searchString.length == 0}>
            {this.state.shownDevices.map(device => (
              <DeviceCell
                device={device}
                key={device.deviceId}
                project={this.props.project}
                updateDevice={this.updateDevice}
                transferDevice={this.transferDevice}
                onClick={() => this.gotoDevicePage(device.deviceId)}
              ></DeviceCell>
            ))}
          </ResultBlock>
          {this.state.searchString.length == 0 &&
            this.state.devices.length > this.state.shownDevices.length && (
              <MoreDevicesCard onClick={this.showMoreDevices}>
                {" "}
                Load More Devices{" "}
              </MoreDevicesCard>
            )}
          {this.state.transferringDevice && (
            <ProjectListHolder>
              <ClickOff onClick={this.endTransfer}></ClickOff>
              <ProjectListHolderInner>
                <CenteredOuterDiv>
                  <HeaderSpan> Choose a Project </HeaderSpan>
                  <ProjectList
                    project={this.props.project}
                    projectSelected={this.transferSelected}
                  ></ProjectList>
                </CenteredOuterDiv>
              </ProjectListHolderInner>
            </ProjectListHolder>
          )}
        </>
      );
    } else if (this.state.hasLoaded) {
      return (
        <>
          <EmptyTablePlaceholder>
            This Project has no devices yet. Download the MyProjects application
            to a device get started.
          </EmptyTablePlaceholder>
        </>
      );
    } else {
      return <> </>;
    }
  }
}

export default withRouter(DeviceList);
