import React from "react";
import firebase from "firebase";
import styled from "styled-components/macro";
import moreIcon from "../images/moreIcon.svg";
import {
  signOut,
  updateUser,
  cancelUpload,
  uploadFile
} from "../FirebaseFunctions";
import {
  showMessage,
  showConfirmation,
  showInputMessage,
  showPercentageIndicator
} from "../UIUtils";
import DropDown from "./DropDown";

const MoreIcon = styled.img`
  display: inline-block;
  margin: 0px 15px 0px 0px;
  width: 20px;
  margin-top: 7.5px;
  vertical-align: middle;
  &:hover {
    opacity: 0.5;
  }
`;

const HiddenInput = styled.input`
  opacity: 0;
  display:none;
  position: absolute;
`;

class UserHeaderDropDown extends React.Component {
  state = {
    isShown: false,
    dropDownItems: []
  };

  uploadImage: null;

  toggleShown = () => {
    let isShown = this.state.isShown;
    this.setState({ isShown: !isShown });
  };

  changeName = async () => {
    this.toggleShown();
    const rsp = await showInputMessage("Enter your Name", this.props.user.name);
    if (rsp.value) {
      updateUser({ name: rsp.value, usersId: this.props.user.usersId });
    }
  };

  changeImage = () => {
    this.inputElement.click();
    this.toggleShown();
  };

  signOut = () => {
    signOut();
    this.toggleShown();
  };

  uploadManager = {
    currentUploadTask: null
  };

  handleImageChange = async event => {
    const that = this;
    this.uploadImage = event.target.files[0];
    if(!this.uploadImage)return;
    const usersId = this.props.user.usersId;
    var percentId = "percentageIndicator";
    showPercentageIndicator("Uploading Picture", percentId).then(
      function() {
        cancelUpload(that.uploadManager);
      }
    );
    var percentSpan = document.getElementById(percentId);
    uploadFile(
      "/userImages/" + usersId,
      this.uploadImage,
      that.uploadManager,
      function(progress) {
        percentSpan.textContent = progress.toFixed(0) + "%";
      }
    ).then(function(rsp) {
      updateUser({ img: rsp, usersId: that.props.user.usersId });
      showMessage("Picture Updated");
    });
  };

  calcDropItems = () => {
    const that = this;
    let items = [];
    const item = {
      name: "Update Name",
      style: "Positive",
      action: this.changeName
    };
    items.push(item);

    const item1 = {
      name: "Update Picture",
      style: "Positive",
      action: this.changeImage
    };
    items.push(item1);

    const item2 = {
      name: "Sign Out",
      style: "Negative",
      action: this.signOut
    };
    items.push(item2);

    that.setState({ dropDownItems: items });
  };

  componentDidMount() {
    this.calcDropItems();
  }

  render() {
    if (this.state.dropDownItems.length) {
      return (
        <>
          <MoreIcon src={moreIcon} onClick={this.toggleShown} />
          {this.state.isShown && (
            <DropDown
              top="50px"
              right="10px"
              dropItems={this.state.dropDownItems}
              toggleShown={this.toggleShown}
            ></DropDown>
          )}
          <HiddenInput
            ref={input => (this.inputElement = input)}
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={this.handleImageChange.bind(this)}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

export default UserHeaderDropDown;
