import React from "react";
import {ProjectCard, ProjectCardSizer, ProjectInfoBar, ProjectBottomInfoBar} from "./ProjectCell"

class ProjectPreviewCell extends React.Component {
  render() {
    const {project} = this.props
    if (project !== undefined) {
      return (
        <ProjectCard>
            <ProjectCardSizer bgImage={project.imageURL}></ProjectCardSizer>
            <ProjectBottomInfoBar>
              <span className="project">{`${project.name}`}</span>
            </ProjectBottomInfoBar>
        </ProjectCard>
      );
    } else {
      return null;
    }
  }
}

export default ProjectPreviewCell;
