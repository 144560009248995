import React from 'react';
import logo from '../images/icon_my_player_v3.png';

class LogoHeader extends React.Component {

  LogoOuterStyle = {
    textAlign: 'center',
    width: '100%',
    marginTop: '100px',
    // marginBottom: '-200px',
  };

  LogoImageStyle = {
    display: 'inline-block',
    width: '100px'
  }

  componentDidMount() {
  }

  render() {
      return(
          <div className="LogoOuter" style={this.LogoOuterStyle}>
            <img src={logo} style={this.LogoImageStyle}/>
            <span className="register_title">{this.props.title}</span>
          </div>
      )
  }
}

export default LogoHeader;
