import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";

import PasswordField from "../components/PasswordField";
import themeMyPlayer from "../theme";
import { HomeStrings } from "../strings";

import {
  CenteredFormContainer,
  FormRow,
  FormTextField,
  RoundedButton
} from "./styled";

class RegisterForm extends React.PureComponent {
  state = {
    email: "",
    fullname: "",
    password: "",
    showPassword: false,
    error: null
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();

    // call parent callback
    this.props.setProcessing(true);
    const { email, password, fullname } = this.state;
    this.props.onRegister(email, password, fullname).catch(error => {
      // register failed
      this.props.setProcessing(false);
      console.error(error);
      let showError = HomeStrings.ERROR_REGISTER_TRANSATION[error.code];
      if(!showError)showError = error.message;
      this.setState({ error: showError});
    });
  };

  isInvalid = () => {
    const { email, fullname, password } = this.state;
    return email === "" || fullname === "" || password === "";
  };

  render() {
    return (
      <MuiThemeProvider theme={themeMyPlayer}>
        <CenteredFormContainer onSubmit={this.onSubmit}>
          <FormRow>
            <FormTextField
              name="email"
              label={HomeStrings.FORM_EMAIL}
              type="email"
              value={this.state.email}
              onChange={this.onChange}
            />
          </FormRow>
          <FormRow>
            <FormTextField
              name="fullname"
              label={HomeStrings.FORM_NAME}
              value={this.state.fullname}
              onChange={this.onChange}
            />
          </FormRow>
          <FormRow>
            <PasswordField
              name="password"
              label={HomeStrings.FORM_PASSWORD_REGISTER}
              width="300px"
              onChange={this.onChange}
              error={this.state.error}
            />
          </FormRow>
          <FormRow>
            <RoundedButton
              variant="contained"
              color="primary"
              disabled={this.isInvalid()}
              type="submit"
            >
              {HomeStrings.FORM_REGISTER}
            </RoundedButton>
          </FormRow>
        </CenteredFormContainer>
      </MuiThemeProvider>
    );
  }
}

export default RegisterForm;
