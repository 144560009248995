const STRINGS = {
  BUTTON_BACK: "← BACK",
  BUTTON_SAVE: "Save",

  DEVICE_STORE_ID: "Store ID",
  DEVICE_POSTCODE: "Postcode",
  DEVICE_RETAILER_NAME: "Retailer",
  DEVICE_STORE_ID: "Store ID",
  DEVICE_ONLINE: "Online",
  DEVICE_OFFLINE: "Offline",

  DEVICE_BUTTON_CLOUD_CONTROL: "Cloud Control",
  DEVICE_BUTTON_CHECK_COMPLIANCE: "Check Compliance",
  DEVICE_BUTTON_DEPLOY_CONTENT: "Deploy Content",

  DEVICE_TAB_CONTENT: "Device Content",
  DEVICE_TAB_ONLINE_STATUS: "Online Status",
  DEVICE_TAB_MESSAGE_LOG: "Message Log",

  DEVICE_CONTENT_COMPLIANCE_STATUS: "Status",
  DEVICE_CONTENT_COMPLIANCE_LAST_CHECKED: "Last checked",
  DEVICE_CONTENT_COMPLIANCE_LAST_DEPLOYED: "Last deployed",
  DEVICE_CONTENT_CHECK_COMPLIANCE_TITLE: "Check Device Compliance?",
  DEVICE_CONTENT_CHECK_COMPLIANCE_TEXT:
    "Are you sure you want to check compliance of this device?",
  DEVICE_CONTENT_DEPLOY_CONTENT_TITLE: "Deploy Content?",
  DEVICE_CONTENT_DEPLOY_CONTENT_TEXT:
    "Are you sure you want to deploy content to this device?",
  DEVICE_CONTENT_DEPLOY_INSTALLED_APPS: "Apps installed",
  DEVICE_CONTENT_DEPLOY_UPDATED_FILES: "Files updated",

  DEVICE_CONTENT_PROGRESS_ON_PROJECT_OBTAINED: "Project obtained",
  DEVICE_CONTENT_PROGRESS_HANDLE_START_TASKS: "Handling start tasks...",
  DEVICE_CONTENT_PROGRESS_INSTALL_PROJECT: "Installing project...",
  DEVICE_CONTENT_PROGRESS_ON_DEVICE_REGISTRATION: "Registering device...",
  DEVICE_CONTENT_PROGRESS_ON_DEVICE_ADDED_TO_PROJECT:
    "Device added to project",
  DEVICE_CONTENT_PROGRESS_PROCESS_MEDIA_FILES: "Processing media files...",
  DEVICE_CONTENT_PROGRESS_ON_UPDATES_COMPLETED: "Media files updates completed",
  DEVICE_CONTENT_PROGRESS_HANDLE_END_TASKS: "Handling end tasks...",
  DEVICE_CONTENT_PROGRESS_ON_ALL_MEDIA_FILES_HANDLED:
    "All media files processed",
  DEVICE_CONTENT_PROGRESS_PROCESS_UNMATCHED_MEDIA_FILES:
    "Checking updated project content...",
  DEVICE_CONTENT_PROGRESS_ON_BLOB_DOWNLOADED: "Downloading media files...",
  DEVICE_CONTENT_PROGRESS_ON_BLOBS_DOWNLOADED: "Downloaded {} files",
  DEVICE_CONTENT_PROGRESS_OVERWRITE_UNMATCHED_FILES:
    "Updating project content...",
  DEVICE_CONTENT_PROGRESS_ON_END_TASKS_COMPLETED: "Deployment completed",
  // DEVICE_CONTENT_PROGRESS_NOTIFY_DEVICE_COMPLIANT: "Updating compliance...",
  DEVICE_CONTENT_PROGRESS_PROCESS_UNINSTALL_PKGS:
    "Installing application: {}...",
  DEVICE_CONTENT_PROGRESS_ON_PACKAGE_INSTALLED: "Installed application: {}",
  DEVICE_CONTENT_PROGRESS_CONTACTING_DEVICE: "Contacting device...",

  DEVICE_ONLINE_LABEL: "Status",
  DEVICE_ONLINE_LAST_ONLINE: "Last online",
  DEVICE_ONLINE_STATUS_ONLINE: "Online",
  DEVICE_ONLINE_STATUS_OFFLINE: "Offline",
  DEVICE_ONLINE_STATUS_CHECKING: "Checking...",
  DEVICE_ONLINE_REQUEST: "Request Online",

  DEVICE_REQUEST_RECEIVED: "Request received by server",

  DEVICE_ERROR_REQUEST_FAILED: "Request Failed!",

  DEVICE_UPDATE_OK_TEXT: "Device info successfully updated.",
  DEVICE_UPDATE_FAIL_TEXT: "Device info failed to update",

  META_TITLE: "MyPlayer Device",
  META_DESCRIPTION: "MyPlayer Device Info",

  DUMMY_TEXT:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\
  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\
  enim ad minim veniam, quis nostrud exercitation ullamco laboris\
  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor\
  in reprehenderit in voluptate velit esse cillum dolore eu fugiat\
  nulla pariatur. Excepteur sint occaecat cupidatat non proident,\
  sunt in culpa qui officia deserunt mollit anim id est laborum."
};

export default STRINGS;
