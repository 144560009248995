import React from "react";

import Footer from "../components/Footer";
import { showMetaTags, TopBanner } from "../UIUtils";
import { HomeStrings } from "../strings";

import RegisterContent from "./Register";
import SignInContent from "./SignIn";
import { Spinner } from "../UIUtils"

class HomePage extends React.Component {
  state = {
    isSignIn: true,
    isProcessing: false,
  };

  constructor(props) {
    super(props);

    // show the top banner if redirect from another path
    this.from =
      props.location.state && props.location.state.from
        ? props.location.state.from
        : null;
  }

  setProcessing = (processing) => {
    this.setState({isProcessing: processing})
  }

  toggleSignIn = () => {
    const { isSignIn } = this.state;
    this.setState({ isSignIn: !isSignIn });
  };

  render() {
    const { isSignIn } = this.state;
    const { isProcessing } = this.state;

    return (
      <>
        {showMetaTags(HomeStrings.META_TITLE, HomeStrings.META_DESCRIPTION)}
        {this.from && <TopBanner>{HomeStrings.TOP_BANNER_MESSAGE}</TopBanner>}
        {isSignIn ? (
          <SignInContent toggleSignIn={this.toggleSignIn} setProcessing={this.setProcessing}/>
        ) : (
          <RegisterContent toggleSignIn={this.toggleSignIn} setProcessing={this.setProcessing}/>
        )}
        {isProcessing && (
          <Spinner></Spinner>
        )}
        <Footer />
      </>
    );
  }
}

export default HomePage;
