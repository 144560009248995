import React from "react";
import { Redirect } from "react-router";
import firebase from "firebase";
import styled from "styled-components/macro";
import logo from "./images/long_icon.png"
import './css/App.css';
import { Link } from "react-router-dom";

const NavBar = styled.div`
  position:fixed;
  top:0px;
  height:50px;
  width:100%;
  z-index:100;
  background-color:black;
`;

const NavIcon = styled.img`
  position: relative;
  height: 30px;
  margin-top: 10px;
  left: 5%;
`;

const OffsetContent = styled.div`
  margin-top: 50px;
  min-height: calc(100% - 50px);
  top: 0px;
  left: 0px;
`;

/* make sure there is a logged in user */
export const withLogin = (WrappedComponent, keepNextURL = false) => {
  return class extends React.Component {
    state = {
      user: {},
    };

    keepNextURL = null

    setKeepNextUrl = (keep) =>{
      keepNextURL = keep
    }

    componentDidMount() {
      const self = this;
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          self.setState({ user: user });
        } else {
          self.setState({ user: null });
        }
      });
    }

    componentWillUnmount() {
      // DataSource.removeChangeListener(this.handleChange);
    }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      if (this.state.user && this.state.user.uid) {
        return (
          <>
          <NavBar>
            <Link to={"/"}>
              <NavIcon src={logo}/>
            </Link>
          </NavBar>
          <OffsetContent>
          <WrappedComponent user={this.state.user} setKeepNextUrl={this.setKeepNextUrl} {...this.props} />
          </OffsetContent>
          </>
        )
      } else if (this.state.user == null) {
        if(keepNextURL && this.props.location !== undefined && this.props.location.pathname !== undefined){
          console.log("keepNextURL redirect : ", keepNextURL);
          return <Redirect push to={{ pathname: '/', state: { nextPage: this.props.location.pathname}}} />
        }
        return <Redirect push to={{ pathname: '/'}} />
      } else {
        return <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      }
    }
  };
}
