import React from "react";
import { getDefaultProjectSizes, updateProject } from "../FirebaseFunctions";
import { CenteredOuterDiv, StyledCard, showMessage } from "../UIUtils";
import styled from "styled-components/macro";

const SelectedSizesCell = styled.div`
  border: 5px solid orange;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
`;

const SizesCellSpan = styled.span`
  margin: 32px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
`;

const SaveChangeButton = styled.div`
  font-size: 18px;
  color: white;
  background-color: black;
  padding: 10px 30px;
  border-radius: 10px;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
`;

const SizeCard = styled(StyledCard)`
  padding: 25px;
`

const CheckBox = styled.div`
  width: 15px;
  height: 15px;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid #a0a0a0;
  top: 0px;
  right: 0px;
  position: absolute;
`;

const CheckedBox = styled(CheckBox)`
  background: orange;
`;

export default class ToggleContentSizes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: {},
      currentSize: ""
    };
  }

  /* when the component is shown then retrieve the default project sizes*/
  componentDidMount() {
    this.getDefaultDeviceProjectSize();
  }

  getDefaultSize = (object) => {
    return Object.keys(object).find(key => object[key].default === true);
  }

  /* get the default project */
  async getDefaultDeviceProjectSize() {
    const sizesObj = await getDefaultProjectSizes();
    this.setState({ sizes: sizesObj });
    console.log("sizesObj : ", sizesObj);
    if (this.props.project.projectSizeKey) {
      this.setState({ currentSize: this.props.project.projectSizeKey });
    }
    else{
      this.setState({ currentSize: this.getDefaultSize(sizesObj) });
    }
  }

  /* save the current size selected by the user if its selected*/

  saveChanges = async () => {
    if (this.state.currentSize) {
      await updateProject({
        projectId: this.props.project.projectId,
        projectSizeKey: this.state.currentSize
      }).then(resp => {
        showMessage("Project Experience Size Updated");
      });
    }
  };

  setSelectedSize = itemName => {
    this.setState({
      currentSize: itemName
    });
  };

  render() {
    /* get the current size from previos props, and set that size
      when a user selects another size then set the new selected size stateKey
      to be saved to the cloud functions
    */
    let { sizes } = this.state;
    return (
      <>
        {Object.keys(sizes).map(size => (
          <SizeCard
            key={size}
            onClick={e => this.setSelectedSize(size)}
          >
          {this.state.currentSize == size && (
            <CheckedBox/>
          )}
          {this.state.currentSize != size && (
            <CheckBox/>
          )}
          <span>{this.state.sizes[size].width} X {this.state.sizes[size].height}</span>
          </SizeCard>
        ))}
        <SaveChangeButton onClick={this.saveChanges}>
          Save Project Sizes{" "}
        </SaveChangeButton>
      </>
    );
  }
}
