import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";

import PasswordField from "../components/PasswordField";
import themeMyPlayer from "../theme";
import { HomeStrings } from "../strings";

import {
  CenteredFormContainer,
  FormRow,
  FormTextField,
  RoundedButton
} from "./styled";

class SignInForm extends React.PureComponent {
  state = {
    email: "",
    password: "",
    error: null
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();

    // call parent callback
    this.props.setProcessing(true);
    const { email, password } = this.state;
    this.props.onSignIn(email, password).catch(error => {
      // sign in failed
      console.error("onSignIn", error);
      let showError = HomeStrings.ERROR_SIGN_IN[error.code];
      if(!showError)showError = error.message;
      this.props.setProcessing(false);
      this.setState({ error: showError });
    });
  };

  isInvalid = () => {
    const { email, password } = this.state;
    return email === "" || password === "";
  };

  render() {
    return (
      <MuiThemeProvider theme={themeMyPlayer}>
        <CenteredFormContainer onSubmit={this.onSubmit}>
          <FormRow>
            <FormTextField
              name="email"
              label={HomeStrings.FORM_EMAIL}
              type="email"
              value={this.state.email}
              onChange={this.onChange}
            />
          </FormRow>
          <FormRow>
            <PasswordField
              name="password"
              label={HomeStrings.FORM_PASSWORD_SIGNIN}
              width="300px"
              onChange={this.onChange}
              error={this.state.error}
            />
          </FormRow>
          <FormRow>
            <RoundedButton
              variant="contained"
              color="primary"
              disabled={this.isInvalid()}
              type="submit"
            >
              {HomeStrings.FORM_SIGNIN}
            </RoundedButton>
          </FormRow>
        </CenteredFormContainer>
      </MuiThemeProvider>
    );
  }
}

export default SignInForm;
