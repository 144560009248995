import React from "react";
import styled from "styled-components/macro";
import ProjectBaseUI from "./ProjectBaseUI";
import ProjectButtonSection from "./ProjectButtonSection";
import { Redirect } from "react-router";
import { StyledCard, AnimatingDiv } from "../UIUtils";
import {
  returnProjectPinPath,
  grabFileFromStorage,
  getProject,
  getPermissionsForKey,
  subscribeToProjectChanges,
  subscribeToUserProjectRemoval
} from "../FirebaseFunctions";

const ProjectCard = styled(StyledCard)`
  display: inline-block;
  vertical-align: middle;
`;

const OuterDiv = styled.div`
  /* margin-top:75px; */
`;

const ProjectCardSizer = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  padding-bottom: 80%;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;

const ProjectInfoSection = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 25px);

  @media (min-width: 1260px) {
    width: calc(75% - 25px);
  }

  @media (max-width: 1000px) {
    width: calc(100% - 25px);
  }

  @media (max-width: 640px) {
    width: calc(100% - 25px);
  }
`;

const ProjectTitle = styled.span`
  display: block;
  font-weight: bold;
  font-size: 26px;
  margin: 10px;
`;

const ProjectDescription = styled.span`
  display: block;
  margin: 10px;
`;

const LoadingTitle = styled.div`
  display: block;
  font-weight: bold;
  font-size: 26px;
  margin: 10px;
  position: relative;
  width: 80%;
  height: 30px;
`;

const LoadingDescription = styled.div`
  display: block;
  position: relative;
  margin: 10px;
  width: 60%;
  height: 30px;
`;

const ProjectPin = styled.span`
  display: block;
  font-weight: bold;
  margin: 10px;
`;

class ProjectHeader extends React.Component {
  unsubscribeList = [];
  mounted = false;
  state = {
    project: null,
    projectPin: null,
    removed: false
  };

  getProject = async () => {
    const rsp = await getProject(this.props.project.projectId);
    if (!this.mounted) return;
    let useProject = { ...this.state.project, ...rsp };
    this.setState({ project: useProject });
    return rsp;
  };

  getProjectChanges = async () => {
    const that = this;
    /* subscribe to project info changes */
    let unsub = await subscribeToProjectChanges(
      this.props.project.projectId,
      function(rsp) {
        if (!that.mounted) return;
        let useProject = { ...that.state.project, ...rsp };
        that.setState({ project: useProject });
      }
    );
    this.unsubscribeList.push(unsub);
  };

  getPermissionsFromDefaults = async permissionsKey => {
    const that = this;
    let permissions = await getPermissionsForKey(permissionsKey);
    if (!this.mounted) return;
    let useProject = {
      ...that.state.project,
      permissions: { ...that.state.project.permissions, ...permissions }
    };
    that.setState({ project: useProject });
  };

  getProjectPermissionsChanges = async () => {
    const that = this;
    /* subscribe to permissions changes and deletion of the project */
    let unsub = await subscribeToUserProjectRemoval(
      this.props.project.projectId,
      that.props.usersId,
      function(proj, type) {
        if (!that.mounted) return;
        if (proj.projectId != that.props.project.projectId) return;
        if (type == "removed") {
          that.setState({ removed: true });
        } else {
          let useProject = { ...that.state.project, ...proj };
          that.setState({ project: useProject });
          if (useProject.permissionsKey) {
            that.getPermissionsFromDefaults(useProject.permissionsKey);
          }
        }
      }
    );
    this.unsubscribeList.push(unsub);
  };

  grabProject = async () => {
    const that = this;
    that.getProjectPermissionsChanges();
    const proj = await that.getProject();
    if (!this.mounted) return;
    that.getProjectChanges();
    this.grabProjectPin();
  };

  grabProjectPin = async () => {
    const that = this;
    try {
      let path = returnProjectPinPath(this.state.project);
      let file = await grabFileFromStorage(path);
      if (!that.mounted) return;
      let r = file.replace("window.pinNumber = '", "");
      let p = r.replace("'", "");
      console.log("project pin : ", p);
      this.setState({ projectPin: p });
    } catch (e) {
      console.warn("No Pin");
    }
  };

  initProject = async () => {
    this.grabProject();
  };

  componentDidMount() {
    this.mounted = true;
    this.initProject();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeList.forEach(function(unsub) {
      unsub();
    });
  }

  render() {
    if (
      this.state.project &&
      !this.state.project.deleted &&
      !this.state.removed &&
      this.state.project.creatorId
    ) {
      console.log("this.state.project : ", this.state.project);
      return (
        <OuterDiv>
          <ProjectCard>
            <ProjectCardSizer
              bgImage={this.state.project.imageURL}>
            </ProjectCardSizer>
          </ProjectCard>
          <ProjectInfoSection>
            <ProjectTitle>{this.state.project.name}</ProjectTitle>
            <ProjectDescription>{this.state.project.desc}</ProjectDescription>
            {this.state.projectPin && this.state.project.permissions && this.state.project.permissions.content.write && (
              <ProjectPin>{"Pin: " + this.state.projectPin}</ProjectPin>
            )}
            {!this.props.hideButtons && (
              <ProjectButtonSection project={this.state.project}
              usersId={this.props.usersId}></ProjectButtonSection>
            )}
          </ProjectInfoSection>
        </OuterDiv>
      );
    }
    else if(this.state.removed){
      return <Redirect to="/" />;
    }
    else if(this.state.project && this.state.project.deleted) {
       return null;
    } else {
    return (
      <OuterDiv>
        <ProjectCard>
          <ProjectCardSizer>
            <AnimatingDiv></AnimatingDiv>
          </ProjectCardSizer>
        </ProjectCard>
        <ProjectInfoSection>
          <LoadingTitle>
            <AnimatingDiv></AnimatingDiv>
          </LoadingTitle>
          <LoadingDescription>
            <AnimatingDiv></AnimatingDiv>
          </LoadingDescription>
        </ProjectInfoSection>
      </OuterDiv>
    )
    }
  }
}

export default ProjectHeader;
