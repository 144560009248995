import firebase from "firebase";

const DEMO_MODE = process.env.REACT_APP_DEMO_MODE || null;

/* paths and stuff */
const usersAndProjectsPath = "users_and_projects";
const devicesAndProjectsPath = "projects_and_devices";
const usersIdKey = "usersId";
const deviceIdKey = "deviceId";
const projectIdKey = "projectId";
const userPath = "users";
const devicePath = "devices";
const projectDeviceInfoPath = "projects";
const projectAndAnalyticsPath = "projects_and_analytics";
const deviceStatusPath = "device_status";
const adminUsersPath = "admin_users";
const deviceDeploymentPath = "device_deployment";
const deviceInfoPath = "device_info";
const projectCodesPath = "projectcodes";
const deviceRecoveryPath = "device_recovery";
const projectInfoPath = "projects_and_info";
const deviceHeartbeatsPath = "device_heartbeats";
const defaultPermissionsPath = "default_project_permissions";
const defaultCreatorPermissionsDoc = "creator_default";
const defaultUserPermissionsDoc = "user_default";
const defaultManagerPermissionsDoc = "manager_default";
const defaultViewerPermissionsDoc = "viewer_default";
const defaultDeviceProject = "project_defaults";
const defaultDeviceProjectDoc = "defaultDeviceProject";
const defaultDeviceProjectSize = "common_project_sizes";
const deviceComplianceCheckPath = "device_compliance_checks";
const getPermissionsCategoriesCollection = "default_project_permissions";
const contentbaseUrl =
  "https://storage.googleapis.com/myplayerbase.appspot.com/media/";

// const defaultUserPermissionsDoc = 'creator_default';
// const defaultCreatorPermissionsDoc = 'user_default';

export const makeRandomProjectId = () => {
  return makeid(20);
};

const makeid = length => {
  var result = "";
  var characters = "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const returnProjectPinPath = project => {
  return returnUploadContentUrlForProject(project) + "pinNumber.js";
};

export const returnUploadContentUrlForProject = project => {
  var mediaFolder = "project";
  if (project.mediaFolder) {
    mediaFolder = project.mediaFolder;
  }
  const URL = "media/" + project.projectId + "/download/" + mediaFolder + "/";
  console.log("returnUploadContentUrlForProject : ", URL);
  return URL;
};

export const returnContentUrlForProject = project => {
  return (
    "https://storage.googleapis.com/myplayerbase.appspot.com/" +
    returnUploadContentUrlForProject(project) +
    "index.html?t=" +
    Date.now()
  );
};

export const signOut = () => {
  firebase.auth().signOut();
};

export const getLoggedInUsersKey = async () => {
  const token = await firebase.auth().currentUser.getIdToken(true);
  console.log("logged in token : ", token);
  return token;
};

export const getLoggedInUsersId = () => {
  try {
    const uuid = firebase.auth().currentUser.uid;
    getLoggedInUsersKey();
    return uuid;
  } catch (e) {
    return null;
  }
};

export const checkForExisitingUser = async user => {
  const use = await getUser(user.uid);
  if (use) {
    console.log("User already exists");
    if (use.details) {
      var userData = {
        accountId: use.client,
        name: use.details.name,
        usersId: user.uid,
        details: firebase.firestore.FieldValue.delete()
      };
      const response = await firebase
        .firestore()
        .collection(userPath)
        .doc(user.uid)
        .update(userData);
      return response;
    }
  } else {
    // var user = app.currentUser();
    var userData = {
      email: user.email.replace(/\./g, "~"),
      accountId: user.email.replace(/\./g, "~"),
      usersId: user.uid,
      name: user.displayName
    };
    const response = await firebase
      .firestore()
      .collection(userPath)
      .doc(user.uid)
      .set(userData);
    return response;
  }
};

/* get possible permissions category */
export const getPermissionsCategories = async () => {
  const snapshot = await firebase
    .firestore()
    .collection(getPermissionsCategoriesCollection)
    .get();
  return snapshot.docs.map(doc => doc.data());
};

export const getPermissionsForKey = async key => {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection(defaultPermissionsPath)
      .doc(key)
      .get();
    let info = querySnapshot.data();
    return info;
  } catch (error) {
    console.warn("getPermissionsForKey: ", error);
    return null;
  }
};

/* get default project permissions for a joining user */
const getDefaultUserPermissions = async () => {
  const querySnapshot = await firebase
    .firestore()
    .collection(defaultPermissionsPath)
    .doc(defaultUserPermissionsDoc)
    .get();
  return querySnapshot.data();
};

/* get default project permissions for a creating user */
const getDefaultCreatorPermissions = async () => {
  const querySnapshot = await firebase
    .firestore()
    .collection(defaultPermissionsPath)
    .doc(defaultCreatorPermissionsDoc)
    .get();
  return querySnapshot.data();
};

/* get details for a user from an id */
export const getUser = async userId => {
  const querySnapshot = await firebase
    .firestore()
    .collection(userPath)
    .doc(userId)
    .get();
  return querySnapshot.data();
};

export const subscribeToUser = async (userId, callback) => {
  return subscribeToEntry(userPath, userId, callback);
};

export const removeProjectCode = async codeDict => {
  var uid = codeDict.code;
  const rsp = await firebase
    .firestore()
    .collection(projectCodesPath)
    .doc(uid)
    .delete();
  return rsp;
};

/* get a project id from a project code */
export const getProjectIdFromCode = async code => {
  const querySnapshot = await firebase
    .firestore()
    .collection(projectCodesPath)
    .doc(code)
    .get();
  return querySnapshot.data();
};

/* get (user) project info from a project id */
export const getProject = async (projectId, remote) => {
  let getOptions = returnCacheOptions(remote);
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection(projectInfoPath)
      .doc(projectId)
      .get(getOptions);
    return querySnapshot.data();
  } catch (e) {
    console.warn("Error getting getProject remote : ", remote, projectId);
    return {};
  }
};

/* get (device) project info from a project id */
export const getDeviceProject = async projectId => {
  const querySnapshot = await firebase
    .firestore()
    .collection(projectDeviceInfoPath)
    .doc(projectId)
    .get();
  return querySnapshot.data();
};

/* get project analytics */
export const getProjectAnalytics = async projectId => {
  const querySnapshot = await firebase
    .firestore()
    .collection(projectAndAnalyticsPath)
    .doc(projectId)
    .get();
  return querySnapshot.data();
};

/* set project analytics */
export const setProjectAnalytics = async data => {
  const response = await firebase
    .firestore()
    .collection(projectAndAnalyticsPath)
    .doc(data.projectId)
    .set(data);
  return response;
};

/* helper method for complex query */
const getMatchesInCollection = async (key, value, path, remote) => {
  if (remote == undefined) remote = true;
  let getOptions = returnCacheOptions(remote);
  const querySnapshot = await firebase
    .firestore()
    .collection(path)
    .where(key, "==", value)
    // .orderBy("creationDate", "asc")
    .get(getOptions);
  var returnArray = [];
  console.log(
    getOptions,
    " getMatchesInCollection : ",
    key,
    value,
    path,
    remote
  );
  if (!querySnapshot.docs.length) {
    return returnArray;
  }
  querySnapshot.forEach(function(doc) {
    if (doc.data().deleted) {
      /* dont return the project */
    } else {
      returnArray.push(doc.data());
    }
  });
  return returnArray;
};

export const checkForAdmin = async callback => {
  try {
    const rsp = await firebase
      .firestore()
      .collection(adminUsersPath)
      .doc(getLoggedInUsersId())
      .get();
    return rsp.data();
  } catch (e) {
    console.warn("no permissions");
  }
};

export const subscribeToRecovery = async callback => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(deviceRecoveryPath)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (e) {
    console.warn("subscribeToRecovery : ", e);
  }
};

/* get users in project */
export const getUsersIdsInProject = async (projectId, remote) => {
  return getMatchesInCollection(
    projectIdKey,
    projectId,
    usersAndProjectsPath,
    remote
  );
};

export const subscribeToCodesInProject = async (projectId, callback) => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(projectCodesPath)
      .where(projectIdKey, "==", projectId)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          // let doc = change.doc;
          // let docId = doc._document.key.path.segments[doc._document.key.path.segments.length - 1];
          // firebase
          //   .firestore()
          //   .collection(projectCodesPath)
          //   .doc(docId)
          //   .update({ code: docId });
          //
          // console.log("doc : ", docId);
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (e) {
    console.warn("subscribeToCodesInProject : ", e);
  }
};

export const subscribeToUsersIdsInProject = async (projectId, callback) => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(usersAndProjectsPath)
      .where(projectIdKey, "==", projectId)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (e) {
    console.warn("subscribeToUsersIdsInProject : ", e);
  }
};

export const checkProjectIdExistsForUser = async (projectId, usersId) => {
  const uid = projectId + "_" + usersId;
  try {
    const rsp = await firebase
      .firestore()
      .collection(usersAndProjectsPath)
      .doc(uid)
      .get();
    return rsp.data();
  } catch (error) {
    console.warn("checkProjectIdExistsForUser error: ", error);
    return null;
  }
};

/* gets projects for user */
const returnCacheOptions = remote => {
  let getOptions = {};
  if (remote) {
    getOptions = {
      source: "server"
    };
  } else {
    getOptions = {
      source: "cache"
    };
  }
  return getOptions;
};

export const getProjectIdsForUser = async (usersId, remote) => {
  try {
    let getOptions = returnCacheOptions(remote);
    const path = usersAndProjectsPath;
    const key = usersIdKey;
    const value = usersId;
    const querySnapshot = await firebase
      .firestore()
      .collection(path)
      .where(key, "==", value)
      // .where('deleted', "==", false)
      .orderBy("creationDate", "desc")
      .get(getOptions);
    var returnArray = [];
    if (!querySnapshot.docs.length) {
      return returnArray;
    }

    var source = querySnapshot.metadata.fromCache ? "local cache" : "server";
    console.log(getOptions, " getProjectIdsForUser Data came from " + source);

    querySnapshot.forEach(function(doc) {
      returnArray.push(doc.data());
    });
    console.log("getProjectIdsForUser : ", returnArray);
    return returnArray;
  } catch (error) {
    console.warn("getProjectIdsForUser: ", error);
    return null;
  }
};

export const removeProjectForUser = async (projectId, usersId) => {
  var uid = projectId + "_" + usersId;
  const rsp = await firebase
    .firestore()
    .collection(usersAndProjectsPath)
    .doc(uid)
    .delete();
  return rsp;
};

export const subscribeToProjectChanges = async (projectId, callback) => {
  console.log("subscribeToProjectChanges : ", projectInfoPath, projectId);
  try {
    const unsub = await firebase
      .firestore()
      .collection(projectInfoPath)
      .doc(projectId)
      .onSnapshot(function(doc) {
        console.log("subscribeToProjectChanges doc : ", doc);
        callback(doc.data());
      });
    return unsub;
  } catch (error) {
    console.warn("subscribeToProjectChanges: ", error);
    return null;
  }
};

export const subscribeToUseProjectsListChanges = async (usersId, callback) => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(usersAndProjectsPath)
      .where("usersId", "==", usersId)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (error) {
    console.warn("subscribeToUseProjectsListChanges : ", error);
  }
};

// export const subscribeToUserProjectChanges = async (projectId, usersId, callback) => {
//   const unsub = await firebase.firestore().collection(usersAndProjectsPath).doc(projectId + '_' + usersId).onSnapshot(function (doc) {
//     callback(doc.data());
//   });
//   return unsub;
// }

export const subscribeToUserProjectRemoval = async (
  projectId,
  usersId,
  callback
) => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(usersAndProjectsPath)
      .where(usersIdKey, "==", usersId)
      .where("projectId", "==", projectId)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (error) {
    console.warn("subscribeToUserProjectRemoval: ", error);
    return null;
  }
};

/* get project code for projectId */
export const getProjectCodeForId = async (projectId, forceRefresh) => {
  const uuid = makeid(8);
  let projectCodeDict = JSON.parse(localStorage.getItem(projectCodesPath));
  if (!projectCodeDict) projectCodeDict = {};
  if (forceRefresh != true) {
    if (projectCodeDict[projectId]) {
      /* remove for testing permissions */
      return { status: 200, code: projectCodeDict[projectId] };
    }
  }
  projectCodeDict[projectId] = uuid;
  localStorage.setItem(projectCodesPath, JSON.stringify(projectCodeDict));
  let projectCodeData = {
    creationDate: Date.now(),
    code: uuid,
    projectId: projectId,
    immutable: false
  };
  console.log("projectCodeData : ", projectCodeData);
  const response = await firebase
    .firestore()
    .collection(projectCodesPath)
    .doc(uuid)
    .set(projectCodeData);
  return { status: 200, code: uuid };
};

/* add project for user */
export const addProjectForUser = async (
  projectId,
  usersId,
  permissions,
  code
) => {
  const uuid = projectId + "_" + usersId;
  var data = {};
  if (permissions == undefined) {
    permissions = defaultUserPermissionsDoc;
  }
  data.permissionsKey = permissions;
  data.projectId = projectId;
  data.usersId = usersId;
  data.creationDate = Date.now();
  if (code) {
    data.joinCode = code;
  }
  try {
    const response = await firebase
      .firestore()
      .collection(usersAndProjectsPath)
      .doc(uuid)
      .set(data);
    return response;
  } catch (error) {
    console.warn("addProjectForUser error: ", error);
    return null;
  }
};

export const setPermissionsForUser = async (
  projectId,
  usersId,
  permissionsKey
) => {
  const uuid = projectId + "_" + usersId;
  const response = await firebase
    .firestore()
    .collection(usersAndProjectsPath)
    .doc(uuid)
    .update({ permissionsKey: permissionsKey });
  return response;
};

export const getDevicesInProject = async projectId => {
  return getMatchesInCollection(
    projectIdKey,
    projectId,
    devicesAndProjectsPath
  );
};

/* gets projects for user */
export const subscribeToDevicesInProject = async (projectId, callback) => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(devicesAndProjectsPath)
      .where(projectIdKey, "==", projectId)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (error) {
    console.warn("subscribeToDevicesInProject : ", error);
  }
};

export const getNumberOfDevicesInProject = async (projectId, remote) => {
  try {
    let getOptions = returnCacheOptions(remote);
    const querySnapshot = await firebase
      .firestore()
      .collection(devicesAndProjectsPath)
      .where(projectIdKey, "==", projectId)
      .get(getOptions);
    var source = querySnapshot.metadata.fromCache ? "local cache" : "server";
    console.log(
      getOptions,
      " getNumberOfDevicesInProject Data came from " + source
    );
    return querySnapshot.size;
  } catch (e) {
    console.warn("error getting getNumberOfDevicesInProject remote : ", remote);
    return -1;
  }
};

export const getNumberOfCompliantDevicesInProject = async (
  projectId,
  remote
) => {
  try {
    let getOptions = returnCacheOptions(remote);
    const querySnapshot = await firebase
      .firestore()
      .collection(deviceDeploymentPath)
      .where(projectIdKey, "==", projectId)
      .where("state", "==", "compliant")
      .get(getOptions);
    var source = querySnapshot.metadata.fromCache ? "local cache" : "server";
    console.log(
      getOptions,
      "getNumberOfCompliantDevicesInProject Data came from " + source
    );
    return querySnapshot.size;
  } catch (error) {
    console.warn(
      "getNumberOfCompliantDevicesInProject : remote :",
      remote,
      error
    );
  }
};

export const grabNumberOfOnlineDevicesInProject = async (projectId, remote) => {
  try {
    let getOptions = returnCacheOptions(remote);

    const onlineFrom = Date.now() - HoursForOnline() * 60 * 60 * 1000;
    const stringFrom = new Date(onlineFrom).toISOString();

    const querySnapshot = await firebase
      .firestore()
      .collection(deviceStatusPath)
      .where(projectIdKey, "==", projectId)
      .where("updatedAt", ">", stringFrom)
      .get(getOptions);

    console.log("number online = : ", querySnapshot.size);
    var source = querySnapshot.metadata.fromCache ? "local cache" : "server";
    console.log(
      getOptions,
      "grabNumberOfOnlineDevicesInProject Data came from " + source
    );
    return querySnapshot.size;
  } catch (error) {
    console.warn("grabNumberOfOnlineDevicesInProject : ", error);
  }
};

export const getNumberOfOnlineDevicesInProject = async (
  projectId,
  callback
) => {
  try {
    const onlineFrom = Date.now() - HoursForOnline() * 60 * 60 * 1000;
    const stringFrom = new Date(onlineFrom).toISOString();
    const unsub = await firebase
      .firestore()
      .collection(deviceStatusPath)
      .where(
        "project_progress.onDeviceAddedToProject.projectId",
        "==",
        projectId
      )
      .where("updatedAt", ">", stringFrom)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (error) {
    console.warn("getNumberOfOnlineDevicesInProject : ", error);
  }
};

export const subscribeToNumberOfCompliantDevicesInProject = async (
  projectId,
  callback
) => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(deviceDeploymentPath)
      .where(projectIdKey, "==", projectId)
      .where("state", "==", "compliant")
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (error) {
    console.warn("subscribeToNumberOfCompliantDevicesInProject : ", error);
  }
};

/* function for testing large numbers */
export const getAllDevices = async projectId => {
  const querySnapshot = await firebase
    .firestore()
    .collection("devices")
    .get();
  console.log("querySnapshot : ", querySnapshot);
  let devices = [];
  querySnapshot.docs.forEach(function(doc) {
    devices.push(doc.data());
  });
  return devices;
};

export const subscribeToAllDevices = async callback => {
  try {
    const unsub = await firebase
      .firestore()
      .collection("devices")
      .orderBy("timestamp", "desc")
      .limit(100)
      .onSnapshot(function(querySnapshot) {
        var deviceIds = [];
        querySnapshot.forEach(function(doc) {
          deviceIds.push(doc.data());
          console.log("deviceIds : ", deviceIds);
          if (deviceIds.length == querySnapshot.docs.length) {
            callback(deviceIds);
          }
        });
      });
    return unsub;
  } catch (e) {
    console.warn("subscribeToAllDevices : ", e);
  }
};

export const subscribeToEntry = async (collection, doc, callback) => {
  try {
    const unsub = await firebase
      .firestore()
      .collection(collection)
      .doc(doc)
      .onSnapshot(function(snapshot) {
        callback(snapshot.data());
      });
    return unsub;
  } catch (e) {
    console.warn("subscribeToEntry : ", e);
  }
};

export const subscribeToDevice = async (deviceId, callback) => {
  return subscribeToEntry(devicePath, deviceId, callback);
};

export const getDevice = async (deviceId, remote) => {
  try {
    let getOptions = returnCacheOptions(remote);
    const querySnapshot = await firebase
      .firestore()
      .collection(devicePath)
      .doc(deviceId)
      .get(getOptions);
    var source = querySnapshot.metadata.fromCache ? "local cache" : "server";
    console.log(getOptions, " getDevice Data came from " + source);
    return querySnapshot.data();
  } catch (e) {
    console.warn("getDevice : ", e, " remote : ", remote);
    return {};
  }
};

export const subscribeToDeviceStatus = async (deviceId, callback) => {
  return subscribeToEntry(deviceStatusPath, deviceId, callback);
};

export const getDeviceStatus = async deviceId => {
  const querySnapshot = await firebase
    .firestore()
    .collection(deviceStatusPath)
    .doc(deviceId)
    .get();
  return querySnapshot.data();
};

export const subscribeToDeviceInfo = async (deviceId, callback) => {
  return subscribeToEntry(deviceInfoPath, deviceId, callback);
};

export const getDeviceInfo = async deviceId => {
  const querySnapshot = await firebase
    .firestore()
    .collection(deviceInfoPath)
    .doc(deviceId)
    .get();
  return querySnapshot.data();
};

export const getDeviceHeartbeats = async deviceId => {
  const querySnapshot = await firebase
    .firestore()
    .collection(deviceHeartbeatsPath)
    .where(deviceIdKey, "==", deviceId)
    .limit(1)
    .orderBy("timestamp", "desc")
    .get();
  var returnArray = [];
  if (!querySnapshot.docs.length) {
    return returnArray;
  }
  querySnapshot.forEach(function(doc) {
    returnArray.push(doc.data());
  });
  return returnArray;
};

export const subscribeToDeviceCompliance = async (
  deviceId,
  projectId,
  callback
) => {
  let doc = deviceId + ":" + projectId;
  return subscribeToEntry(deviceDeploymentPath, doc, callback);
};

export const subscribeToDeviceComplianceCheck = async (
  deviceId,
  projectId,
  callback
) => {
  let doc = deviceId + ":" + projectId;
  return subscribeToEntry(deviceComplianceCheckPath, doc, callback);
};

export const subscribeToDeviceHeartbeats = async (deviceId, callback) => {
  try {
    const unsub = firebase
      .firestore()
      .collection(deviceHeartbeatsPath)
      .where(deviceIdKey, "==", deviceId)
      .limit(1)
      .orderBy("timestamp", "desc")
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          callback(change.doc.data(), change.type);
        });
      });
    return unsub;
  } catch (e) {
    console.warn("subscribeToDeviceHeartbeats : ", e);
  }
};

export const HoursForOnline = () => {
  return 24;
};

export const isDeviceOnlineSince = timeISOString => {
  const hours = (Date.now() - new Date(timeISOString)) / (60 * 60 * 1000);
  return hours <= HoursForOnline();
};

export const updateUser = async userDetails => {
  const usersId = userDetails.usersId;
  const response = await firebase
    .firestore()
    .collection(userPath)
    .doc(usersId)
    .update(userDetails);
  return response;
};

/*
    create a project_and_info collection,
    NOT to be confused with a device project
*/
export const createProject = async projectDetails => {
  const projectId = projectDetails.projectId;
  const responseTwo = await addProjectForUser(
    projectId,
    projectDetails.creator,
    defaultCreatorPermissionsDoc
  );
  const response = await firebase
    .firestore()
    .collection(projectInfoPath)
    .doc(projectId)
    .set(projectDetails);
  return response;
};

export const approveProject = async projectDetails => {
  console.log("approveProject : ", projectDetails);
  return updateProject(projectDetails);
};

export const updateProject = async projectDetails => {
  const projectId = projectDetails.projectId;
  const loc = projectInfoPath + projectId;
  const response = await firebase
    .firestore()
    .collection(projectInfoPath)
    .doc(projectId)
    .update(projectDetails);
  return response;
};

export const grabFileFromStorage = async location => {
  let storageRef = firebase.storage().ref();
  let result = null;
  const stored = storageRef.child(location);
  console.log("stored : ", stored);
  if (!stored) return;
  const response = await stored.getDownloadURL();
  console.log("response : ", response);
  if (!response) return;
  return new Promise(function(resolve, reject) {
    var request = new XMLHttpRequest();
    request.open("GET", response, true);
    request.send(null);
    request.onreadystatechange = function() {
      if (request.readyState === 4 && request.status === 200) {
        resolve(request.responseText);
        console.log("request.responseText : ", request.responseText);
      }
    };
  });
};

export const getFile = async () => {
  let storageRef = firebase.storage().ref();
  const response = await storageRef
    .child("media/zbNacZQmDlcu3gKvOm0i/download/project/index.html")
    .getDownloadURL();
  console.log("Get File : ", response);

  const response2 = await storageRef
    .child("media/zbNacZQmDlcu3gKvOm0i/download/project/webb.sh")
    .getDownloadURL();
  console.log("Get File : ", response2);
};

/* upload a singular file to firebase storage */
export const uploadFile = (location, file, app, progressCallback) => {
  return new Promise(resolve => {
    var storageRef = firebase.storage().ref();
    app.currentUploadTask = storageRef.child(location).put(file);
    app.currentUploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback(progress);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function(error) {
        console.log("Error : ", error);
      },
      function() {
        app.currentUploadTask.snapshot.ref
          .getDownloadURL()
          .then(function(downloadURL) {
            resolve(downloadURL);
          });
      }
    );
  });
};

export const cancelUpload = function(app) {
  if (app.currentUploadTask) {
    app.currentUploadTask.cancel();
    app.currentUploadTask = null;
  }
};

/* delete contents of a folder in firebase storage */
export const deleteFolderContents = async baseLocation => {
  var storageRef = firebase.storage().ref();
  const listRef = storageRef.child(baseLocation);
  await deleteFilesInFolderRef(listRef);
};

const deleteFilesInFolderRef = async ref => {
  const res = await ref.listAll();
  const promiseListFolders = res.prefixes.map(async folderRef => {
    console.log("delete folder : ", folderRef);
    return await deleteFilesInFolderRef(folderRef);
  });
  await Promise.all(promiseListFolders);
  const promiseListFiles = res.items.map(async itemRef => {
    console.log("delete file : ", itemRef);
    return await itemRef.delete();
  });
  await Promise.all(promiseListFiles);
};

/* delete contents of a folder in firebase storage */
let projectFileCount = 0;
export const getNumberOfFilesInProject = async baseLocation => {
  projectFileCount = 0;
  var storageRef = firebase.storage().ref();
  const listRef = storageRef.child(baseLocation);
  await countFoldersInFileRef(listRef);
  return projectFileCount;
};
const countFoldersInFileRef = async ref => {
  const res = await ref.listAll();
  const promiseListFolders = res.prefixes.map(async folderRef => {
    return await countFoldersInFileRef(folderRef);
  });
  await Promise.all(promiseListFolders);
  const promiseListFiles = res.items.map(async itemRef => {
    console.log("Count file : ", itemRef);
    projectFileCount++;
  });
  await Promise.all(promiseListFiles);
};

/*
    upload a folder to firebase storage,
    can select an outer folder and it will
    upload whole directory relatively
*/
export const uploadFolder = (
  app,
  baseLocation,
  files,
  progressCallback,
  currentItem
) => {
  return new Promise(resolve => {
    let currentIndex = files.length - 1;
    let storageRef = firebase.storage().ref();

    function doUpload(file) {
      currentItem(file);
      var loc;
      if (file.webkitRelativePath && file.webkitRelativePath.length) {
        var pathSplit = file.webkitRelativePath.split("/");
        pathSplit.shift();
        loc = pathSplit.join("/");
      } else {
        loc = file.name;
      }
      uploadFile(baseLocation + loc, file, app, progressCallback).then(
        function() {
          currentIndex--;
          if (currentIndex > -1) {
            doUpload(files[currentIndex]);
          } else {
            resolve(200);
          }
        }
      );
    }
    doUpload(files[currentIndex]);
  });
};

/*
    get a list of default apps that all projects have,
    atow includes: mediaplayer, assistant, sideloader/
*/
export const getProjectDefaultApps = () => {
  return getMatchesInCollection("default", true, "apps_and_info");
};

export const getProjectOtherApps = () => {
  return getMatchesInCollection("default", false, "apps_and_info");
};

/*
    device projects are located at projects/,
    device projects are not seen by users, only devices,
    they contain installation information for project
    including media, apps, and tasks
*/
/*const defaultDeviceProject = {
  accountId: "",
  doc: {
    config: {
      desc: ""
    },
    hardware: {
      0: ""
    },
    software: {
      0: ""
    }
  },
  name: "",
  pkgs: [],
  register: {
    country: "GB",
    postcode: "SE162DB",
    retailerName: "MyPlayer",
    storeId: "Raymouth"
  },
  tasks: [
    {
      async: false,
      cmds: ["am start -n io.myplayer.android.cc/.RunInBackground"],
      name: "RestartServices",
      priority: "end",
      requireSuccess: true,
      sudo: true
    }
  ]
};
*/
export const updateDeviceProject = async projectDetails => {
  const projectId = projectDetails.projectId;
  const response = await firebase
    .firestore()
    .collection(projectDeviceInfoPath)
    .doc(projectId)
    .update(projectDetails);
  return response;
};

export const createDeviceProject = async projectDetails => {
  const projectId = projectDetails.projectId;
  const user = await getUser(getLoggedInUsersId());
  const exisitingProject = await getDeviceProject(projectId);
  if (exisitingProject) {
    console.warn("exists already : ", exisitingProject);
    return exisitingProject;
  }
  let data = await getDefaultDeviceProject(defaultDeviceProjectDoc);
  data.accountId = user.accountId;
  data.projectId = projectId;
  data.name = projectDetails.name.replace(/[^A-Z0-9]+/gi, "_");

  data.doc.config.desc = projectDetails.desc.replace(/[^A-Z0-9]+/gi, "_");

  const apps = await getProjectDefaultApps();
  data.pkgs = apps;

  const response = await firebase
    .firestore()
    .collection(projectDeviceInfoPath)
    .doc(projectId)
    .set(data);
  return 200;
};

/* cloud function apis */
const standardAPIHeaders = {
  Authorization: "Bearer 1fe-PcX6FRvJXthX6bpTDn5Np/rVZyw",
  Accept: "application/json",
  "Content-Type": "application/json"
};
let apiBase = "https://europe-west1-myplayerbase.cloudfunctions.net/webapi/";

let demoablePaths = [];
if (DEMO_MODE) {
  demoablePaths.push("project/preview");
  demoablePaths.push("project/device/update");
  apiBase = "https://europe-west1-myplayertest.cloudfunctions.net/webapi/";
}

const MakePostCloudFunc = async (path, body) => {
  if (DEMO_MODE && demoablePaths.indexOf(path) == -1) {
    return { succes: true };
  }
  const token = await firebase.auth().currentUser.getIdToken(true);
  let url = apiBase + path;
  body.idToken = token;
  console.log("url : ", url);
  console.log("body : ", body);
  const response = await fetch(url, {
    method: "POST",
    headers: standardAPIHeaders,
    body: JSON.stringify(body)
  });
  console.log("response : ", response);
  const result = await response.json();
  return result;
};

export const requestHeartbeat = async (deviceId, projectId) => {
  let path = "project/device/heartbeat/send";
  let data = {
    deviceId: deviceId,
    projectId: projectId
  };
  return MakePostCloudFunc(path, data);
};

export const makeProjectFolderPublic = async projectId => {
  let path = "project/media/makePublic";
  let data = {
    projectId: projectId
  };
  return MakePostCloudFunc(path, data);
};

export const projectMediaHasChanged = async projectId => {
  let path = "project/media/changed";
  let data = {
    projectId: projectId
  };
  return MakePostCloudFunc(path, data);
};

export const projectDocHasChanged = async projectId => {
  let path = "project/changed";
  let data = {
    projectId: projectId
  };
  return MakePostCloudFunc(path, data);
};

export const checkComplianceForDevice = async (projectId, deviceId) => {
  let path = "project/device/deployComplianceCheck";
  let data = {
    projectId: projectId,
    deviceId: deviceId
  };
  return MakePostCloudFunc(path, data);
};

export const deployDeviceInProject = async (projectId, deviceId) => {
  let path = "project/device/deploy";
  let data = {
    projectId: projectId,
    deviceId: deviceId
  };
  return MakePostCloudFunc(path, data);
};

export const transferDevice = async (projectId, deviceId) => {
  let path = "project/device/redeploy";
  let data = {
    projectId: projectId,
    deviceIds: [deviceId]
  };
  return MakePostCloudFunc(path, data);
};

export const updateDeviceInfo = async (projectId, deviceId, info = null) => {
  const path = "project/device/update";
  const data = {
    projectId,
    deviceId
  };
  if (info) {
    Object.keys(info).forEach(key => (data[key] = info[key]));
  }
  return MakePostCloudFunc(path, data);
};

export const getLastMediaTimeForProjectId = async (projectId, callback) => {
  let url = apiBase + "project/media";
  if (DEMO_MODE) {
    return 0;
  }
  const response = await fetch(url, {
    method: "POST",
    headers: standardAPIHeaders,
    body: JSON.stringify({
      projectId: projectId
    })
  });
  const result = await response.json();
  return result.lastMediaMoment;
};

/* get a preview of the project, this only include fewer project details */
export const getPojectPreview = async (projectId, code) => {
  let path = "project/preview";
  let data = {
    projectId: projectId,
    shareCode: code
  };
  return MakePostCloudFunc(path, data);
};

/* get the project_defaults object based on the document name */
export const getDefaultDeviceProject = async doc => {
  const querySnapshot = await firebase
    .firestore()
    .collection(defaultDeviceProject)
    .doc(doc)
    .get();
  return querySnapshot.data();
};

/* get the project_defaults object based on the document name */
export const getDefaultProjectSizes = async () => {
  return await getDefaultDeviceProject(defaultDeviceProjectSize);
};
